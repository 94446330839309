import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Install from './Install';
import Pickup from './Pickup';
import Shipping from './Shipping';

const styles = theme => ({
  content: {},
  formLabel: {
    padding: theme.spacing(),
    paddingLeft: 0,
  },
});

class SIPInfo extends React.Component {
  componentDidMount() {
    this.props.getInstallCities();
  }

  render() {
    const { sip, checkout, stats, cart } = this.props;
    const {
      personal,
      cities,
      selectedCityId,
      shops,
      dates,
      slots,
      selectedShop,
      installDate,
      installSlot: _installSlot,
      shipping,
    } = checkout;
    const installSlot = _installSlot.toString();

    let content;

    if (sip === 'install') {
      content = (
        <Install
          cities={cities}
          cart={cart}
          selectedCityId={selectedCityId}
          selectedShop={selectedShop}
          shops={shops}
          stats={stats}
          dates={dates}
          slots={slots}
          installDate={installDate}
          installSlot={installSlot}
          onClearPickShop={this.props.onClearPickShop}
          onChangeInstallCity={this.props.onChangeInstallCity}
          onPickShop={this.props.onPickShop}
          reportLike={this.props.reportLike}
          onPickInstallDate={this.props.onPickInstallDate}
          onPickInstallSlot={this.props.onPickInstallSlot}
          onContinue={this.props.onContinue}
        />
      );
    } else if (sip === 'pickup') {
      content = <Pickup onContinue={this.props.onContinue} />;
    } else if (sip === 'shipping') {
      content = (
        <Shipping
          onFormFieldChange={this.props.onFormFieldChangeForShipping}
          onContinue={this.props.onContinue}
          data={shipping}
          personal={personal}
        />
      );
    }

    return content;
  }
}

SIPInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  sip: PropTypes.string.isRequired,
  checkout: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  getInstallCities: PropTypes.func.isRequired,
  onChangeInstallCity: PropTypes.func.isRequired,
  onPickShop: PropTypes.func.isRequired,
  onClearPickShop: PropTypes.func.isRequired,
  stats: PropTypes.object,
  onPickInstallDate: PropTypes.func.isRequired,
  onPickInstallSlot: PropTypes.func.isRequired,
  reportLike: PropTypes.func.isRequired,
  onFormFieldChangeForShipping: PropTypes.func.isRequired,
};

export default withStyles(styles)(SIPInfo);
