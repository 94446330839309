import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    padding: 16,
    marginTop: 33,
    marginLeft: "auto",
    marginRight: "auto",
  },
  headline: {
    fontSize: "14pt",
    fontWeight: 525,
  },
  para: {
    margin: "15px 8px",
  },
  "@media (min-width: 1024px)": {
    root: {
      marginTop: 80,
      width: 1000,
    },
    para: {
      width: "70%",
    },
  },
});

const hebrewLabels = {
  title: "הצהרת הגנת מידע ועמידה בתקן GDPR האירופאי",
  paras: [
    "מפעיל אתר זה מתייחס בחשיבות רבה לפרטיות ולשמירה על פרטיכם האישיים - מבקרי ולקוחות האתר אשר יקראו לצורך מסמך זה משתמשי האתר.  עיבוד ואיסוף המידע על ידי מפעיל האתר מתקיימים בתנאים מחמירים ביותר ובעמידה עם התקן האירופי GDPR להגנת מידע אשר מיושם באירופה משנת 2018 ומכריח עמידה בתנאי אחסון נתונים מחמירים כגון הצפנה ברמה גבוהה - אנו מצפינים את הנתונים אשר אנו שומרים על ביקורכם באתר או על קנייתכם באתר באמצעות מפתח הצפנה בגודל העולה על 128 תווים (אורכו המלא לא נמסר על מנת לשמר רמת אבטחה גבוהה ככל האפשר)",
    "בנוסף, הגלישה באתר מתאפשרת באמצעות פרוטוקול מאובטח TLS אשר מבטיח שאף צד שלישי לא מאזין לנתונים העוברים ממכשיר הטלפון או המחשב באמצעותו הינכם צופים באתר ומוסרים מידע רגיש כגון כרטיסי אשראי. תקן ה TLS הינו המתקדם בעולם ומאפשר לכם לספק פרטי אשראי באופן מאובטח.  בנוסף ברשותנו תעודת אבטחה SSL Certificate אשר מאושרים על ידי גורמי ביטחון מידע מהמובילים בעולם מחברת Sectigo ותוכלו לצפות בנתוני התעודה באמצעות הקלקה על סימן המנעול בשורת הכתובת של האתר וכמובן לוודא שהתעודה בתוקף. (נכון לגבי ביקור בכל אתר מסחר)",
    "אנו מפעילי אתר ספורטישופ אחראים למידע אותו את/ה מעביר לרשותנו במהלך רכישה מקוונת",
    "איתמר ארזואן שירותים",
    "רחוב התאנה 9 , יד רמב״ם, מיקוד: 9979300",
    "תא דואר 51359",
    "כתובת מייל:  service@sportyshop.co.il",
    {
      title: "הגדרות איסוף המידע כמו שנאסף באתר שלנו",
      paras: [
        'מידע אישי אותו אנו אוספים נחשב למידע שבאמצעותו אפשר לייצג אדם באופן טבעי כגון: מספר טלפון, כתובת IP, שם, כתובת אימייל, כתובת מגורים, כרטיס אשראי, ועוד. המונח "לאסוף" או "לעבד מידע" מכסה כל שימוש העולה על הדעת בקשר ישיר עם המידע האישי שלכם על פי סעיף 4 לחוק ה GDPR האירופי - מתחילת האינטראקציה שלך עם האתר ועד לסיומה ומחיקת המידע.',
      ],
    },
    {
      title: "עיבוד מידע באמצעות קריאות אל השרת האתר",
      paras: [
        "אנו מאחסנים את האתר שלנו חברת Amazon Web Services אשר הינה מהחברות הטובות והגדולות בעולם בתחום ה IT. המידע מאוחסן בשרתים אשר ברשותנו המלאה (איתמר ארזואן) וללא גישה נוספת של אדם נוסף אל שרתים אלו. חברת Amazon אחראית על הצפנת המידע ועומדת באופן מלא בתקן ה GDPR על מנת לאפשר שאף אדם אשר עובד בחברה יכול לגשת אל תוכן השרתים.",
        "מסד הנתונים גם הוא מאוחסן בחברת Amazon ונמצא מחוץ לתחומי האינטרנט וללא גישה ישירה לאף אדם שהוא. הנתונים האישיים אותם אתם מוסרים במהלך הזמנה מקוונת באתר מוצפנים במפתח הצפנה בגודל העולה על 128 תווים על מנת לאפשר אבטחה מקסימלית.",
      ],
    },
    {
      title: "עיבוד מידע באמצעות חברת גוגל (גוגל אנליטיקס)",
      paras: [
        "אנו אוספים מידע נוסף אודות הביקור שלכם באמצעות קוד Javascript של חברת גוגל אשר ושמו Google Analytics. חברת גוגל עומדת בתקן המחמיר של ה GDPR וכל מידע אודות הביקור שלכם נאסף ונשמר בין היתר בעוגיות או משודר אל שרתי חברת גוגל ישירות מהאתר שלנו בארה״ב ומאוחסן שם.  המידע נשמר בשרתים של גוגל ומשמש לניתוח התנועה לאתר לבקשתו של מפעיל האתר ומוגבל לצרכים אלו בלבד.",
        "המידע שנאסף על ידי גוגל נשמר בתנאים מחמירים על פי הצהרת הפרטיות של גוגל והתחייבות על עמידה בתקן. מידע נוסף ניתן למצוא בלינקים הבאים:",
        <a
          target="_blank"
          href="https://marketingplatform.google.com/about/analytics/terms/us/"
        >
          https://marketingplatform.google.com/about/analytics/terms/us/
        </a>,
        <a target="_blank" href="https://policies.google.com/privacy?hl=en-US">
          https://policies.google.com/privacy?hl=en-US
        </a>,
      ],
    },
    {
      title: "עיבוד מידע באמצעות חברת פייסבוק (פייסבוק פיקסל)",
      paras: [
        "אנו אוספים באתר מידע אודות התנועה שלכם באתר באמצעות מערכת איסוף המידע של חברת פייסבוק אשר מאפשרת לנו לנתח את מגוון הקמפיינים שלנו בפלטפורמה פייסבוק בצורה טובה יותר ולהבין איזה קהלים עלינו להציג עבורם מודעות. המידע מועבר מהאתר ישירות לשרתי חברת פייסבוק אשר נמצאים בתחומי ארה״ב. הפיקסל של חברת פייסבוק משתמש בעוגיות. המידע נאסף בהתאם להצהרת הפרטיות אליה מחוייבת פייסבוק בלינק הבא: ",
        <a target="_blank" href="https://www.facebook.com/policy.php">
          https://www.facebook.com/policy.php
        </a>,
      ],
    },
    {
      title: "עיבוד מידע באמצעות עוגיות",
      paras: [
        "האתר שומר מידעים מסוימים אודות הביקור שלכם בעוגיות כגון: הפריטים אותם הכנסתם לעגלה, מספר מזהה של הביקור שלכם, לאיזה מוצרים ביצעתם מחווה כגון לייק ועוד.. המידע נשמר על מנת לאפשר לאתר לתפקד ואינו חורג משימוש זה. מידע רגיש אינו נשמר בעוגיות אלו ומוגבל לתפקוד האתר בלבד.",
      ],
    },
  ],
};

class GDPRPage extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Paper className={classes.root} elevation={4}>
          <Typography component="h1" variant="h1" className={classes.headline}>
            {hebrewLabels.title}
          </Typography>

          {hebrewLabels.paras.map((pa) => {
            if (typeof pa === "object" && "paras" in pa) {
              return (
                <React.Fragment>
                  <Typography
                    component="h2"
                    variant="h2"
                    className={classes.headline}
                  >
                    {pa.title}
                  </Typography>

                  {pa.paras.map((p) => (
                    <Typography className={classes.para} component="p">
                      {p}
                    </Typography>
                  ))}
                </React.Fragment>
              );
            } else {
              return (
                <Typography className={classes.para} component="p">
                  {pa}
                </Typography>
              );
            }
          })}
        </Paper>
      </div>
    );
  }
}

GDPRPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles)(GDPRPage);
