import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    padding: 16,
    marginTop: 33,
    marginLeft: "auto",
    marginRight: "auto",
  },
  headline: {
    fontSize: "14pt",
    fontWeight: 525,
  },
  para: {
    margin: "15px 8px",
  },
  "@media (min-width: 1024px)": {
    root: {
      marginTop: 80,
      width: 1000,
    },
  },
});

const hebrewLabels = {
  title: "מדיניות פרטיות",
  text: "הפרטיות שלך חשובה לנו!",
  text1:
    "על מנת לאבטח את פרטיך האישיים אנו מספקים בזאת את ההצהרה אשר מסבירה בקצרה על המידע אותו אנו אוספים.",
  text2:
    "אנו אוספים אך ורק מידע אישי אשר משמש לזיהוי האישי של המשתמש בעת ביצוע ההרשמה למערכת וזאת על מנת לבצע כניסה     מאובטחת למערכת או על מנת לבצע קנייה בחנות.",
  text3:
    "אנו מתחייבים לא להעביר את המידע שברשותנו לצד שלישי כל שהוא אלא אם כן הלקוח נשאל והסכים לשיתוף המידע האישי שלו    עם צד שלישי כלשהוא שנחליט בעתיד ליצור איתו קשר.",
  text4:
    "לעולם לא ניצור איתך קשר במספר הסלולארי שתזין במערכת אלא על מנת לעדכן אותך אך ורק בנתונים החשובים לגבי שימושך במערכת.    לא תקבל מסרים הקשורים לתוכן פרסומי כל שהוא מאיתנו או מצד שלישי כלשהוא במקרה ונעסוק עם כזה בעתיד אם לא תחפוץ בכך.",
  text5:
    "אנו מתחייבים כי המערכת הינה מאובטחת ופרטי אשראי לא נשמרים או נמצאים במעקב כלשהוא. נתוניך מועברים ישירות לישראכרט איתה    אנו סולקים ואינם נשמרים אצלנו בשום צורה שהיא.",
};

class PrivacyPolicyPage extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Paper className={classes.root} elevation={4}>
          <Typography component="h1" variant="h1" className={classes.headline}>
            {hebrewLabels.title}
          </Typography>

          <Typography className={classes.para} component="p">
            {hebrewLabels.text}
          </Typography>
          <Typography className={classes.para} component="p">
            {hebrewLabels.text1}
          </Typography>
          <Typography className={classes.para} component="p">
            {hebrewLabels.text2}
          </Typography>
          <Typography className={classes.para} component="p">
            {hebrewLabels.text3}
          </Typography>
          <Typography className={classes.para} component="p">
            {hebrewLabels.text4}
          </Typography>
          <Typography className={classes.para} component="p">
            {hebrewLabels.text5}
          </Typography>
        </Paper>
      </div>
    );
  }
}

PrivacyPolicyPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles)(PrivacyPolicyPage);
