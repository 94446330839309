import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';

const styles = () => ({
    content: {
        padding: 16,
    },
    check: {
        color: 'green',
        marginRight: 5,
        position: 'relative',
        top: 4
    },
    buttonCont: {
        margin: 8,
        marginTop: 16,
    },
    h3: {
        fontSize: '1.5rem'
    }
});

const hebrewLabels = {
    title: 'ההזמנה נקלטה בהצלחה!',
    text: 'הזמנתך נקלטה במערכת שלנו, מספר הזמנה: {xxx}',
    text2: 'הצוות שלנו יתחיל לטפל בהזמנתך בהקדם האפשרי',
    backHomeCTA: 'חזרה לדף הבית'
};

class ThankYou extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.content}>
                <Grid container spacing={0}>
                    <Grid item lg={12}>
                        <Typography variant="h1" component="h1" className={classes.h3}>
                            <CheckIcon className={classes.check} />
                            {hebrewLabels.title}
                        </Typography>

                        <Typography component="p">
                            {hebrewLabels.text.replace('{xxx}', this.props.orderId)}
                        </Typography>

                        <Typography component="p">
                            {hebrewLabels.text2}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={0}>
                    <Grid item lg={12} className={classes.buttonCont}>
                        <Button
                            variant="contained"
                            component={Link}
                            to="/"
                            color="primary"
                            className={classes.button}>
                            {hebrewLabels.backHomeCTA}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

ThankYou.propTypes = {
    classes: PropTypes.object.isRequired,
    orderId: PropTypes.string.isRequired
};

export default withStyles(styles)(ThankYou);
