import React from "react";
import { Switch, Route } from "react-router";
import App from "../containers/App";

import Home from "../containers/Home";
import TireResultsPage from "../containers/TireResultsPage";
import CategoryMotoResultsPage from "../containers/CategoryMotoTiresResultsPage";
import TiresResultsByBrandPage from "../containers/MotoTiresResultsByBrandPage";

import ProductPage from "../containers/ProductPage";
import CartPage from "../containers/CartPage";
import CheckoutPage from "../containers/CheckoutPage";
import BusinessModelPage from "../containers/BusinessModelPage";
import PrivacyPolicyPage from "../containers/PrivacyPolicyPage";
import ReturnsAndRefundPolicy from "../containers/ReturnsAndRefundPolicyPage";
import TermsOfUsePage from "../containers/TermsOfUsePage";
import AboutUsPage from "../containers/AboutUsPage";
import ShopSignupPage from "../containers/ShopSignupPage";
import ImprintPage from "../containers/ImprintPage";
import GDPRPage from "../containers/GdprPage";
import AccessibilityPolicyPage from "../containers/AccessibilityPolicyPage";
import TestimonialsPage from "../containers/TestimonialsPage";

import CustomerServicePage from "../containers/CustomerServicePage";
import OurShopsPage from "../containers/OurShopsPage";
import ShopPage from "../containers/ShopPage";
import AdminPage from "../containers/AdminPage";

import { getHomeData } from "../redux/actions/home";
import { getProductPageData } from "../redux/actions/product-page";
import { getListPageData } from "../redux/actions/tire-results-page";
import { getListPageDataForCategory } from "../redux/actions/category-results-page";
import { getListByBrandPageData } from "../redux/actions/results-by-brand-page";

// for more details see https://reacttraining.com/react-router/web/guides/server-rendering
// specify routes with the asnyc function required to fetch the data to render the route
// IMPORTANT: the loadData function must return a Promise
export const routes = [
  {
    path: "/",
    exact: true,
    component: Home,
    loadData: () => getHomeData(),
  },
  {
    path: "/tires",
    exact: true,
    component: Home,
    loadData: () => getHomeData(),
  },
  {
    path: "/tires-hot-deals",
    exact: true,
    component: Home,
    loadData: () => getHomeData(),
  },
  {
    path: "/tires-super-sale",
    exact: true,
    component: Home,
    loadData: () => getHomeData(),
  },
  {
    path: "/motorcycle-tires",
    exact: true,
    component: Home,
    loadData: () => getHomeData(),
  },
  {
    path: "/our-direct-business-model",
    exact: true,
    component: BusinessModelPage,
    loadData: () => () => Promise.resolve(),
  },
  {
    path: "/list/moto/by/brand/:brand",
    exact: true,
    component: TiresResultsByBrandPage,
    loadData: (a) => getListByBrandPageData(a),
  },
  {
    path: "/list/moto/:width/:profile/:rim",
    exact: true,
    component: TireResultsPage,
    loadData: (a) => getListPageData(a),
  },
  {
    path: "/list/moto/category/:category",
    exact: true,
    component: CategoryMotoResultsPage,
    loadData: (a) => getListPageDataForCategory(a),
  },
  {
    path: "/tire/moto/:ean/:name",
    exact: true,
    component: ProductPage,
    loadData: (a) => getProductPageData(a),
  },
  {
    path: "/our-shops/:name",
    exact: true,
    component: ShopPage,
    loadData: () => () => Promise.resolve(),
  },
  {
    path: "/our-shops",
    exact: true,
    component: OurShopsPage,
    loadData: () => () => Promise.resolve(),
  },
  {
    path: "/coh",
    exact: true,
    component: AdminPage,
    loadData: () => () => Promise.resolve(),
  },
  {
    path: "/cart",
    exact: true,
    component: CartPage,
    loadData: () => () => Promise.resolve(),
  },
  {
    path: "/checkout",
    exact: true,
    component: CheckoutPage,
    loadData: () => () => Promise.resolve(),
  },
  {
    path: "/privacy-policy",
    exact: true,
    component: PrivacyPolicyPage,
    loadData: () => () => Promise.resolve(),
  },
  {
    path: "/returns-and-refund-policy",
    exact: true,
    component: ReturnsAndRefundPolicy,
    loadData: () => () => Promise.resolve(),
  },
  {
    path: "/about-us",
    exact: true,
    component: AboutUsPage,
    loadData: () => () => Promise.resolve(),
  },
  {
    path: "/imprint",
    exact: true,
    component: ImprintPage,
    loadData: () => () => Promise.resolve(),
  },
  {
    path: "/accessibility-policy",
    exact: true,
    component: AccessibilityPolicyPage,
    loadData: () => () => Promise.resolve(),
  },
  {
    path: "/data-protection-policy-and-gdpr",
    exact: true,
    component: GDPRPage,
    loadData: () => () => Promise.resolve(),
  },
  {
    path: "/shop/signup",
    exact: true,
    component: ShopSignupPage,
    loadData: () => () => Promise.resolve(),
  },
  {
    path: "/terms-of-use",
    exact: true,
    component: TermsOfUsePage,
    loadData: () => () => Promise.resolve(),
  },
  {
    path: "/testimonials",
    exact: true,
    component: TestimonialsPage,
    loadData: () => () => Promise.resolve(),
  },
  {
    path: "/customer-service",
    exact: true,
    component: CustomerServicePage,
    loadData: () => () => Promise.resolve(),
  },
  {
    path: "/contact-us",
    exact: true,
    component: CustomerServicePage,
    loadData: () => () => Promise.resolve(),
  },
  {
    component: Home,
  },
];

export default function Router() {
  return (
    <App>
      <Switch>
        {routes.map((route) => (
          <Route key={route.path || "notfound"} {...route} />
        ))}
      </Switch>
    </App>
  );
}
