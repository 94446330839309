import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

const hebrewLabels = {
  name: 'איש קשר',
  phone: 'נייד',
  address: 'כתובת',
  city: 'עיר',
  notes: 'הערות',
  continue: 'המשך',
};

const styles = theme => ({
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  formControl: {
    margin: theme.spacing(),
    width: '80%',
  },
  formLabel: {
    padding: theme.spacing(),
    paddingLeft: 0,
  },
  control: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
});

class Shipping extends React.Component {
  handleChange = event => {
    const { value, name, id } = event.target;
    this.props.onFormFieldChange(id || name, value);
  };

  render() {
    const { classes, personal } = this.props;
    const {
      name = personal.name,
      phone = personal.phone,
      address = '',
      city = '',
      notes = '',
    } = this.props.data;
    const inputPropsForNumbers = {
      pattern: '[0-9]*',
      type: 'number',
      step: '0.01',
    };

    return (
      <div className={classes.content}>
        <FormControl component="fieldset" className={classes.formControl}>
          <TextField
            id="name"
            onChange={this.handleChange}
            label={hebrewLabels.name}
            className={classes.textField}
            value={name}
          />
        </FormControl>

        <FormControl component="fieldset" className={classes.formControl}>
          <TextField
            id="phone"
            inputProps={inputPropsForNumbers}
            onChange={this.handleChange}
            label={hebrewLabels.phone}
            className={classes.textField}
            value={phone}
          />
        </FormControl>

        <FormControl component="fieldset" className={classes.formControl}>
          <TextField
            id="address"
            onChange={this.handleChange}
            label={hebrewLabels.address}
            className={classes.textField}
            value={address}
          />
        </FormControl>

        <FormControl component="fieldset" className={classes.formControl}>
          <TextField
            id="city"
            onChange={this.handleChange}
            label={hebrewLabels.city}
            className={classes.textField}
            value={city}
          />
        </FormControl>

        <FormControl component="fieldset" className={classes.formControl}>
          <TextField
            id="notes"
            onChange={this.handleChange}
            label={hebrewLabels.notes}
            className={classes.textField}
            value={notes}
          />
        </FormControl>
      </div>
    );
  }
}

Shipping.propTypes = {
  classes: PropTypes.object.isRequired,
  onFormFieldChange: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  personal: PropTypes.object.isRequired,
};

export default withStyles(styles)(Shipping);
