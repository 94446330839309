import React from "react";
import { hydrate } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import { HelmetProvider } from "react-helmet-async";

import Router from "../universal/routes";
import createReduxStore from "../universal/createReduxStore";
import { restoreCartFromCookieStorage } from "./../redux/reducers/utils/cart";
import theme from "./../universal/theme";

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__ || { catalogReducer: {} }; // eslint-disable-line no-underscore-dangle

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle

// Ugly hack but will do for now
preloadedState.catalogReducer.cart = restoreCartFromCookieStorage();

// Create Redux store with initial state
const store = createReduxStore({ preloadedState });

function Main() {
  React.useEffect(() => {
    if (document) {
      const jssStyles = document.querySelector("#jss-server-side");
      if (jssStyles) {
        jssStyles.parentElement.removeChild(jssStyles);
      }
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <Provider store={store}>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </Provider>
      </HelmetProvider>
    </ThemeProvider>
  );
}

if (document){
  hydrate(<Main />, document.querySelector("#root"));
} 
