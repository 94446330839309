import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";

import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";

import ResultItemPriceRow from "./../common/TopSellersPriceRow";
import brandLogoUrls from "./../common/logoUrls";

import commons from "./../common/commons";

const hebrewLables = {
  freeShipping: "משלוח חינם",
  setOfTires: "סט צמיגים",
  learnMoreButtonText: "קנה עכשיו",
};

const styles = (theme) => ({
  actions: {
    display: "flex",
  },
  button: {
    margin: theme.spacing(),
    width: "100%",
  },
  chip: {
    marginTop: 8,
  },
  chipBlackFriday: {
    flip: false,
    direction: "ltr",
    fontWeight: 800,
    width: 138,
    top: 59,
    position: "absolute",
    color: "#fafafa",
    background: "#063970",
  },
  cardHeader: {
    flip: false,
    direction: "ltr",
  },
  cardTitle: {
    fontSize: 14,
    fontWeight: 600,
  },
  cardMediaContainer: {
    position: "relative",
  },
  content: {
    padding: 5,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  media: {
    height: 105,
    backgroundPosition: "top",
  },
  brandLogo: {
    position: "absolute",
    background: "#fff",
    maxHeight: 26,
    right: 60,
    bottom: 3,
  },
  card: {
    borderTop: "solid 1px #7a7a7a",
    width: "48%",
    margin: 3,
    position: "relative",
  },
  "@media (min-width: 1024px)": {
    card: {
      maxWidth: 230,
      margin: 8,
    },
  },
});

class TopSellerItem extends React.Component {
  state = { expanded: false };

  render() {
    const { classes, data } = this.props;
    const { displayName, brand, displaySize, cdn = false, ean, name } = data;
    const { url: primaryPhoto } = commons.getProductPrimaryImage(cdn, ean);

    return (
      <Card className={classes.card}>
        <CardHeader
          titleTypographyProps={{ className: classes.cardTitle }}
          className={classes.cardHeader}
          title={displayName}
          subheader={brand}
        />
        <Divider />
        <div className={classes.cardMediaContainer}>
          <div className={classes.brandLogoContainer}>
            <img
              className={classes.brandLogo}
              src={brandLogoUrls[brand.toLowerCase()]}
            />
          </div>

          <CardMedia
            className={classes.media}
            image={primaryPhoto}
            title={`${brand} ${displayName} ${displaySize}`}
          />
        </div>
        <Divider />
        <CardContent className={classes.content}>
          <ResultItemPriceRow data={data} hideExtraField={true} />
          <Chip
            label={displaySize}
            className={classes.chip}
            style={{ minWidth: 120 }}
          />
          {brand.toLowerCase() === "mitas" && (
            <Chip label="MITAS SALE" className={classes.chipBlackFriday} />
          )}
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button
            component={Link}
            variant="contained"
            color="primary"
            className={classes.button}
            to={`/tire/moto/${ean}/${name}`}
          >
            {hebrewLables.learnMoreButtonText}
          </Button>
        </CardActions>
      </Card>
    );
  }
}

TopSellerItem.propTypes = {
  classes: PropTypes.object.isRequired,
  stats: PropTypes.object,
  data: PropTypes.object.isRequired,
};

export default withStyles(styles)(TopSellerItem);
