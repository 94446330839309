import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProductPage from '../components/product-page';
import * as actions from '../redux/actions/catalogActions';

const mapStateToProps = state => {
  return { catalog: state.catalogReducer };
};

const mapDispatchToProps = dispatch => {
  return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);
