import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = () => ({
    content: {
        padding: 15,
        textAlign: 'center'
    }
});

class Loader extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.content}>
                <CircularProgress className={classes.progress} />
            </div>
        );
    }
}

Loader.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Loader);
