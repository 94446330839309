import { combineReducers } from 'redux';
import home from './home';
import about from './about';
import catalogReducer from './catalog'

const reduxState = combineReducers({
  catalogReducer,
  home,
  about
});

export default reduxState;
