import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import OrderList from "./OrderList";
import { GoogleLogin } from "react-google-login";

const styles = (theme) => ({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: 33,
    marginLeft: "auto",
    marginRight: "auto",
  },
  headline: {
    fontSize: "14pt",
    fontWeight: 525,
  },
  para: {
    margin: "15px 8px",
  },
  "@media (min-width: 1024px)": {
    root: {
      marginTop: 80,
      width: 1000,
    },
  },
});

const hebrewLabels = {
  title: "ניהול הזמנות",
};

const basePath =
  process.env.NODE_ENV === "production" ? "/edge" : "http://localhost:3010";
class AdminPage extends React.Component {
  state = { isAuthenticated: false, user: null, token: "" };

  onFailure = () => {};

  googleResponse = (response) => {
    const tokenBlob = new Blob(
      [JSON.stringify({ access_token: response.accessToken }, null, 2)],
      { type: "application/json" }
    );
    const options = {
      method: "POST",
      body: tokenBlob,
      mode: "cors",
      cache: "default",
    };

    fetch(`${basePath}/auth/google`, options).then((r) => {
      const token = r.headers.get("x-auth-token");
      r.json().then((user) => {
        if (token) {
          this.setState({ isAuthenticated: true, user, token });
          this.props.actions.getOrders();
        }
      });
    });
  };

  logout = () => {
    this.setState({ isAuthenticated: false, token: "", user: null });
  };

  updateOrderStatus = (id, status) => {
    this.props.actions.updateStatusInflight();
    this.props.actions.updateOrderProps(id, { status });
  };

  onUpdateTrackingCode = (id, trackingCode) => {
    this.props.actions.updateStatusInflight();
    this.props.actions.updateOrderProps(id, { trackingCode });
  };

  getPaymentDetails = ({ orderId, token }) => {
    this.props.actions.getPaymentDetails({ orderId, token });
  };

  render() {
    const { classes, catalog } = this.props;
    const { orders, products, during } = catalog.admin;
    const { _id = "" } = this.state.user || {};

    const content = this.state.isAuthenticated ? (
      <div>
        {orders.length > 0 && (
          <OrderList
            during={during}
            orders={orders}
            products={products}
            token={_id}
            onGetPaymentDetails={this.getPaymentDetails}
            onUpdateStatus={this.updateOrderStatus}
            onUpdateTrackingCode={this.onUpdateTrackingCode}
          />
        )}
      </div>
    ) : (
      <div>
        <GoogleLogin
          clientId="953186478491-6mps8iroc14e0h7sci1q2mh1q50dsqi6.apps.googleusercontent.com"
          buttonText="Login"
          isSignedIn={true}
          onSuccess={this.googleResponse}
          onFailure={this.googleResponse}
        />
      </div>
    );

    return (
      <div>
        <Paper className={classes.root} elevation={4}>
          <Typography component="h1" variant="h1" className={classes.headline}>
            {hebrewLabels.title}
          </Typography>

          {content}
        </Paper>
      </div>
    );
  }
}

AdminPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminPage);
