import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import OrderCard from "./OrderCard";

const styles = () => ({});

class OrderList extends React.Component {
  render() {
    const { orders, products, during, token } = this.props;

    return (
      <div>
        {orders.map((order, index) => {
          return (
            <OrderCard
              during={during}
              key={index}
              data={order}
              token={token}
              products={products}
              onGetPaymentDetails={this.props.onGetPaymentDetails}
              onUpdateStatus={this.props.onUpdateStatus}
              onUpdateTrackingCode={this.props.onUpdateTrackingCode}
            />
          );
        })}
      </div>
    );
  }
}

OrderList.propTypes = {
  classes: PropTypes.object.isRequired,
  orders: PropTypes.array,
  during: PropTypes.bool,
  token: PropTypes.string,
  products: PropTypes.array,
  onGetPaymentDetails: PropTypes.func,
  onUpdateStatus: PropTypes.func,
  onUpdateTrackingCode: PropTypes.func,
};

export default withStyles(styles)(OrderList);
