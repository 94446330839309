import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';

import ShopCard from './ShopCard';

import { vsprintf } from 'sprintf-js';
import { Typography } from '@material-ui/core';

const hebrewLabels = {
  selectCity: 'בחר עיר:',
  continue: 'המשך',
  manualBookingNote:
    'נא לתאם תור ישירות מול המוסך לאחר קבלת הודעה מאיתנו שהצמיגים הגיעו',
  selectShop: 'מוסך:',
  selectedShop: 'מוסך שנבחר להתקנה:',
  changeShopCTA: 'שנה מוסך',
  selectDate: 'תאריך התקנה:',
  selectSlot: 'שעת התקנה:',
};

const styles = theme => ({
  content: {
    margin: 0,
  },
  formControl: {
    width: '100%',
    margin: '8px 0',
  },
  formLabel: {
    padding: theme.spacing(),
    paddingLeft: 0,
  },
  control: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  changeShopButton: {
    marginTop: theme.spacing(),
  },
  button: {},
  '@media (min-width: 1024px)': {
    content: {
      margin: 8,
    },
    formControl: {
      width: '80%',
      margin: theme.spacing(),
    },
  },
});

class Install extends React.Component {
  pickInstallDate = event => {
    this.props.onPickInstallDate(event.target.value, this.props.selectedShop);
  };

  pickInstallSlot = event => {
    this.props.onPickInstallSlot(event.target.value);
  };

  render() {
    const {
      classes,
      stats,
      cities,
      cart,
      shops,
      selectedCityId,
      dates,
      installDate,
      slots,
      installSlot,
      selectedShop,
    } = this.props;

    let isSet = false;

    cart.items.forEach(item => {
      if ('isSet' in item && item.isSet === true) {
        isSet = true;
      }
    });

    let selectedShopObject;
    let selectedShopDisplayName = '';
    let manualBooking = false;

    if (selectedShop !== '') {
      selectedShopObject = shops.find(shop => shop.id === selectedShop);
      selectedShopDisplayName = `${selectedShopObject.displayName} (${selectedShopObject.address})`;
      if (
        'manualBooking' in selectedShopObject &&
        selectedShopObject.manualBooking === true
      ) {
        manualBooking = true;
      }
    }

    let installDateContent;

    if (dates.length > 0) {
      installDateContent = (
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel className={classes.formLabel} component="legend">
            {hebrewLabels.selectDate}
          </FormLabel>
          <Select onChange={this.pickInstallDate} value={installDate}>
            {dates.map(({ displayText, value }, index) => {
              return (
                <MenuItem key={index} value={value}>
                  {displayText}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    }

    return (
      <div className={classes.content}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel
            className={classes.formLabel}
            htmlFor="city"
            component="legend"
          >
            {hebrewLabels.selectCity}
          </FormLabel>
          <Select
            value={selectedCityId}
            onChange={this.props.onChangeInstallCity}
            inputProps={{
              name: 'city',
              id: 'city',
            }}
          >
            {cities.map(({ displayName, id }) => (
              <MenuItem key={id} value={id}>
                {displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedShop === '' && (
          <FormControl component="fieldset" className={classes.formControl}>
            {shops
              .filter(shop => {
                if (shop.hide && shop.hide === true) {
                  return false;
                }
                return true;
              })
              .map(shop => {
                return (
                  <ShopCard
                    key={shop.id}
                    cart={cart}
                    isSet={isSet}
                    stats={stats}
                    onPickShop={this.props.onPickShop}
                    reportLike={this.props.reportLike}
                    data={shop}
                  />
                );
              })}
          </FormControl>
        )}

        {selectedShop !== '' && (
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel
              className={classes.formLabel}
              htmlFor="selected-shop-view"
              component="legend"
            >
              {hebrewLabels.selectedShop}
            </FormLabel>
            <Input id="selected-shop-view" value={selectedShopDisplayName} />

            <Button
              onClick={this.props.onClearPickShop}
              className={classes.changeShopButton}
              variant="contained"
            >
              {hebrewLabels.changeShopCTA}
            </Button>
          </FormControl>
        )}

        {manualBooking && (
          <React.Fragment>
            <Typography component="p">
              {hebrewLabels.manualBookingNote}
            </Typography>
          </React.Fragment>
        )}

        {!manualBooking && installDateContent}

        {!manualBooking && slots.length > 0 && (
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel className={classes.formLabel} component="legend">
              {hebrewLabels.selectSlot}
            </FormLabel>
            <Select onChange={this.pickInstallSlot} value={installSlot}>
              {slots.map(({ startCursor, vacant }, index) => {
                const displayText = vsprintf('%d:00-%d:00', [
                  startCursor,
                  startCursor + 2,
                ]);
                return (
                  <MenuItem disabled={!vacant} key={index} value={startCursor}>
                    {displayText}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
      </div>
    );
  }
}

Install.propTypes = {
  classes: PropTypes.object.isRequired,
  cities: PropTypes.array.isRequired,
  selectedCityId: PropTypes.string,
  selectedShop: PropTypes.string,
  onContinue: PropTypes.func,
  shops: PropTypes.array.isRequired,
  dates: PropTypes.array.isRequired,
  slots: PropTypes.array.isRequired,
  stats: PropTypes.object,
  cart: PropTypes.object,
  onChangeInstallCity: PropTypes.func.isRequired,
  onClearPickShop: PropTypes.func.isRequired,
  onPickShop: PropTypes.func.isRequired,
  installDate: PropTypes.string.isRequired,
  installSlot: PropTypes.string.isRequired,
  onPickInstallDate: PropTypes.func.isRequired,
  onPickInstallSlot: PropTypes.func.isRequired,
  reportLike: PropTypes.func.isRequired,
};

export default withStyles(styles)(Install);
