import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Chip from '@material-ui/core/Chip';
import { vsprintf } from 'sprintf-js';

const weightLoads = [
  145,
  150,
  155,
  160,
  165,
  170,
  175,
  180,
  185,
  190,
  195,
  200,
  206,
  212,
  218,
  224,
  230,
  236,
  243,
  250,
  257,
  265,
  272,
  280,
  290,
  300,
  307,
  315,
  325,
  335,
  345,
  355,
  365,
  375,
  387,
  400,
  412,
  425,
  437,
  450,
  462,
  475,
  487,
  500,
  515,
  530,
  545,
  560,
  580,
  600,
  615,
  630,
  650,
  670,
  690,
  710,
];

const loadRatings = {};
for (let i = 41, j = 0; i <= 96; i++, j++) {
  loadRatings[i.toString()] = weightLoads[j];
}

const hebrewLables = {
  speedTextGeneric: 'מתאים לנסיעה במהירות של עד {x} קמ״ש',
  speedTextZ: 'מיועד לנסיעה במהירות של 240 קמ״ש ומעלה',
  speedTextWSpecial: 'מיועד לנסיעה במהירות של 270 קמ״ש ומעלה',
  speedRatings: {
    l: 120,
    m: 130,
    n: 140,
    p: 150,
    q: 160,
    r: 170,
    s: 180,
    t: 190,
    u: 200,
    h: 210,
    v: 240,
    w: 270,
    '(w)': 270,
    y: 300,
    z: 240,
    zr: 240,
  },
  loadRatingText: 'קוד עומס {x} למשקל עד {y} ק״ג',
};

const styles = theme => ({
  preOrder: {
    background: '#0d9b4b',
    color: '#fefefe',
  },
  inStock: {
    background: '#359152',
    color: '#fefefe',
  },
  outOfStock: {
    background: '#ba2218',
    color: '#fefefe',
  },
  specTableCell: {
    whiteSpace: 'nowrap',
  },
  onDemand: {
  },
  table: {
    marginTop: 8,
    borderTop: 'solid 1px #666867',
  },
  speedCode: {
    fontWeight: 700,
    color: '#2b2a2a',
  },
});

function renderSpeedRating({ classes, speed = '', load, position = '' }) {
  let speedElem = (
    <React.Fragment>
      {position !== '' && (
        <React.Fragment>
          <span>{position}:</span>
        </React.Fragment>
      )}{' '}
      {hebrewLables.defaultSpeed} <br />
    </React.Fragment>
  );

  if (speed.length > 0) {
    let speedText;
    let loadText;

    if (speed.toLowerCase() === 'z' || speed.toLowerCase() === 'zr') {
      speedText = hebrewLables.speedTextZ;
    } else if (speed.toLowerCase() === '(w)') {
      speedText = hebrewLables.speedTextWSpecial;
    } else {
      speedText = hebrewLables.speedTextGeneric.replace(
        '{x}',
        hebrewLables.speedRatings[speed.toLowerCase()]
      );
    }

    if (load.toString().length > 0) {
      loadText = hebrewLables.loadRatingText
        .replace('{x}', load)
        .replace('{y}', loadRatings[load.toString()]);
    }

    speedElem = (
      <React.Fragment>
        {position !== '' && (
          <React.Fragment>
            <span>{position}:</span>
          </React.Fragment>
        )}
        <span className={classes.speedCode}>
          {load}
          {speed}
        </span>
        <br />
        <span>{speedText}</span>
        <br />
        <span>{loadText}</span>
        <br />
        <br />
      </React.Fragment>
    );
  }

  return speedElem;
}

class ProductDataSheet extends React.Component {
  render() {
    const { classes, data, nextShipment, currentShipment, nextShipmentDeadline } = this.props;

    const {
      displaySize,
      fullPrice,
      set,
      speed,
      load,
      ean,
      dot = false,
      visibility = 'none',
      frontLoad,
      frontSpeed,
      rearLoad,
      rearSpeed,
    } = data;

    const stockStatuses = {
      none: {
        className: classes.onDemand,
        text: 'נא להתקשר'
      },      
      outofstock: {
        className: classes.outOfStock,
        text: 'לא במלאי',
      },
      instock: {
        className: classes.inStock,
        text: 'במלאי מיידי',
      },
      preorder: {
        className: classes.preOrder,
        text: 'מגיע במשלוח הקרוב'
      },
      ondemand: {
        className: classes.onDemand,
        text: 'הזמן היום וקבל במשלוח הבא'
      }
    };

    const stockStatusObj = stockStatuses[visibility];

    let speedElem;
    let dotElement;

    if (dot && dot !== '') {
      if (set && typeof (dot) === 'object') {
        dotElement = (
          <React.Fragment>
            {'front' in dot && <TableRow>
              <TableCell className={classes.specTableCell}>
                יצור צמיג קדמי
              </TableCell>
              <TableCell>{dot.front}</TableCell>
            </TableRow>}
            {'rear' in dot && <TableRow>
              <TableCell className={classes.specTableCell}>
                יצור צמיג אחורי
              </TableCell>
              <TableCell>{dot.rear}</TableCell>
            </TableRow>}
          </React.Fragment>
        );
      } else if (!set) {
        // Single tire
        dotElement = (
          <TableRow>
            <TableCell className={classes.specTableCell}>
              תאריך יצור
            </TableCell>
            <TableCell>{dot}</TableCell>
          </TableRow>
        );
      }
    }

    if (set) {
      speedElem = (
        <React.Fragment>
          {renderSpeedRating({
            classes,
            speed: frontSpeed,
            load: frontLoad,
            position: 'קדמי  ',
          })}
          {renderSpeedRating({
            classes,
            speed: rearSpeed,
            load: rearLoad,
            position: 'אחורי ',
          })}
        </React.Fragment>
      );
    } else {
      speedElem = renderSpeedRating({ classes, speed, load, position: '' });
    }

    return (
      <React.Fragment>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell className={classes.specTableCell}>
                מידת הצמיג
              </TableCell>
              <TableCell className={classes.specTableCell}>
                {displaySize}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.specTableCell}>
                זמינות במלאי
              </TableCell>
              <TableCell>
                <Chip
                  className={stockStatusObj.className}
                  label={stockStatusObj.text}
                />
              </TableCell>
            </TableRow>
            {(visibility === 'preorder') && (
              <TableRow>
                <TableCell className={classes.specTableCell}>
                  צפי הגעה
                </TableCell>
                <TableCell>{currentShipment}</TableCell>
              </TableRow>
            )}

            {(visibility === 'ondemand') && (
              <React.Fragment>
                <TableRow>
                  <TableCell className={classes.specTableCell}>
                    צפי הגעה משוער
                  </TableCell>
                  <TableCell>{nextShipment}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.specTableCell}>
                    תאריך אחרון להזמנה
                  </TableCell>
                  <TableCell>{nextShipmentDeadline}</TableCell>
                </TableRow>
              </React.Fragment>
            )}

            {dotElement}
            {dotElement &&
              <TableRow>
                <TableCell className={classes.specTableCell} colSpan="2">
                  ניתן להרכיב צמיגים עד 5 שנים <br />
                   מיום היצור שלהם ללא דאגה<br />
                  כאשר הצמיגים אוכסנו כהלכה ביצועיהם<br />
                  יהיו כפי שיצאו מהמפעל ביום יצורם.
                </TableCell>
              </TableRow>}
            <TableRow>
              <TableCell className={classes.specTableCell}>מחיר מלא</TableCell>
              <TableCell>{vsprintf('%.2f', [fullPrice])} ₪</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ verticalAlign: 'top' }}
                className={classes.specTableCell}
              >
                קוד מהירות
              </TableCell>
              <TableCell>{speedElem}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.specTableCell}>מקט</TableCell>
              <TableCell>{ean}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ProductDataSheet);
