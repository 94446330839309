import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    padding: 16,
    marginTop: 33,
    marginLeft: "auto",
    marginRight: "auto",
  },
  headline: {
    fontSize: "14pt",
    fontWeight: 525,
  },
  para: {
    display: "list-item",
    margin: "15px 8px",
  },
  "@media (min-width: 1024px)": {
    root: {
      marginTop: 80,
      width: 1000,
    },
  },
});

const hebrewLabels = {
  title: "תנאי השימוש באתר",
  preface:
    "כאשר אתם מבצעים הזמנה באתר ספורטישופ אתם מסכימים לכל האמור במסמך זה אשר מוגדר כתנאי השימוש באתר על כל סעיפיו.",
};

const sections = [
  "במידה ובחרתם באפשרות משלוח צמיג עד הבית או לחלופין איסוף עצמי על אחריות הלקוח לוודא שהוא מתקין את הצמיגים שנרכשו דרכנו בצמיגייה / פנצרייה מאושרת על ידי משרד התחבורה. וזאת על מנת שהאחריות על הצמיגים אותם רכשתם תיהיה תקפה ושנוכל לכבדה. לקוח שירצה לבצע בדיקת אחריות לפגם בצמיג שרכש בספורטישופ ולא יוכל להוכיח הרכבה של הצמיג (חשבונית מס מהצמיגייה)  בטווח של עד שבוע ימים ממועד ההזמנה במערכת שלנו - לא יזכה לטיפול במסגרת אחריות בפנייתו.",
  "על הלקוח לוודא שהוא מזמין צמיגים על פי הרשום ברישיון הרכב שלו. במידה והלקוח טעה במידות הצמיגים והצמיגים כבר הורכבו על האופנוע / רכב  - לא ניתן לקבל החזר כספי בגין הצמיגים שנרכשו",
  "התור במוסך אשר מוזמן דרך האתר נחשב כסופי אך נתון לשינויים בשל בעיות טכניות בלתי צפויות כגון: מוסך סגור, צמיגים אשר נשלחו בדואר שליחים ולא הספיקו להגיע למוסך המבוקש. על הלקוח להבין את ההיבטים הלוגיסטיים בהבאת הצמיגים אל המוסך אשר דרש ולהבין שאנו עובדים עם ספקי משנה בכל הנוגע ללוגיסטיקה האמור. כמוכן למוסך עצמו עלול להיות כשל שהלקוח לא מודע אליו בעת ביצוע ההזמנה כגון: מכונת איזון/הרכבה תקולה. חוסר בכוח אדם ביום הספציפי (מחלה של אחד העובדים או כל סיבה אחרת) ועל כן שינויים בתור אפשרי גם לאחר שנקבע ואושר כסופי.",
  "במידה והתור במוסך נדחה כתוצאה מהמוזכר בסעיף לעיל ספורטישופ או המוסך אינם אחראיים או מחוייבים לפיצוי כלשהוא ללקוח. ועל הלקוח להבין שתור עלול להשתנות ולהתאים את זמנו ועיסוקיו בהתאם. לרבות ימי חופש / דחיית עבודות או כל דבר אחר היוצא בכך",
  "למרות הבדיקות המחמירות אשר אנו מבצעים לפני כניסה לקשר עסקי עם המוסכים השונים המופיעים באתר. המוסכים המופיעים עדיין בגדר קבלי משנה עבורנו והאחריות המקצועית על איכות וטיב ההתקנה נותרים באחריות הבלעדית. כל נזק/תקלה שנגרם לאופנוע/רכב שלכם במהלך התקנת צמיגים אשר נרכשים באתר ספורטישופ הינו באחריות המוסך המתקין אותו בחרתם ואין לראות בספורטישופ אשמה או אחראית לפצות את הלקוח בגין נזקים אלו. חשוב להבין שספורטישופ אינה צד בתהליך ההתקנה הטכני אלא רק גורם מקשר ומתווך.",
  "לעת הנוכחית אנו מקבלים בחנות שלנו את האמצעי התשלום הבאים: ויזה , מסטרקרד ופייפל, העברה בנקאית ושירות מזומן בזמן של הדואר , וכמובן תשלום במקום בכל האמצעים המוזכרים",
  "הערה חשובה: אנא וודא שהפרטים שאתה מוסר בדף התשלום תואמים את הפרטים המוזנים במקור הכלכלי שלך (הבנק למשל) על מנת למנוע אי נעימות של אי התאמה בין הנתונים אנו לא מקבלים מזומן , צקים או תשלום לשליח",
  "בנוסף, אנו לא אחראיים לעמלות שייגבו על ידי הבנק שלכם במקרה והינכם נכנסים למצב של משיכת יתר  כתוצאה מההזמנה שלכם. זאת אחריותכם לשמור תיעוד של פרטי ההזמנה במקרה ויצוץ הצורך להשתמש בנתונים בעתיד.",
  "אם אחד מהפרטים החשובים בהזמנה שלכם השתנה בין מועד קבלת ההזמנה ועד למועד השילוח (למשל כתובת משלוח, טלפון וכדומה..) זו היא אחריותכם ליצור עמנו קשר ולעדכן אותנו במידע החדש.",
  "אם ההזמנה שלכם חוזרת אלינו עקב טעות בכתובת שסיפקתם תחוייבו בעמלה של משלוח חוזר. אנו שומרים חלק מן המידע אותו אתם מעבירים במועד ההזמנה על פי מה שמותר בחוק וכן על פי מה שמצויין בהצהרת הפרטיות שלנו על גבי השרת המאובטח שלנו לצורכיים עסקיים בלבד.",
  "אישור ההזמנה שלכם משקף בדיוק את הפריטים שלהם אתם זכאים מאיתנו ואת הסכום הסופי אותו אנו נגבה מאמצעי התשלום שלכם.",
  "לפעמים קורה שפריטים בחנות האינטרנטית שלנו מוזנים עם טעות במחיר. אם המחיר שמופיע באתר גבוה מהמחיר הנכון של המוצר נזכה אתכם על ההפרש ונשלח לכם את המוצר.",
  "אם מחיר המוצר שמופיע באתר נמוך מהמחיר הנכון של המוצר ניצור עמכם קשר לחיוב של השארית לתשלום או שניתן לכם את האפשרות לבטל את ההזמנה.",
];

class TermsOfUsePage extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Paper className={classes.root} elevation={4}>
          <Typography component="h1" variant="h1" className={classes.headline}>
            {hebrewLabels.title}
          </Typography>

          <Typography className={classes.para} component="p">
            {hebrewLabels.preface}
          </Typography>

          <ol>
            {sections.map((text, index) => {
              return (
                <Typography key={index} className={classes.para} component="li">
                  {text}
                </Typography>
              );
            })}
          </ol>
        </Paper>
      </div>
    );
  }
}

TermsOfUsePage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles)(TermsOfUsePage);
