import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import ShopList from "./../shops/ShopList";

const styles = (theme) => ({
  root: {
    padding: 16,
    marginTop: 33,
    marginLeft: "auto",
    marginRight: "auto",
  },
  headline: {
    fontSize: "14pt",
    fontWeight: 525,
  },
  para: {
    margin: "15px 8px",
  },
  "@media (min-width: 1024px)": {
    root: {
      marginTop: 80,
      width: 1000,
    },
  },
});

const hebrewLabels = {
  title: "נקודות התקנת צמיגים",
  text: "בחר את העיר הרצויה על מנת לצפות בנקודות הרכבה בקרבת ביתך.",
};

class OurShopsPage extends React.Component {
  componentDidMount() {
    // Load up the cities
    this.props.actions.getShopCities();
  }

  onChangeCity = (city) => {
    this.props.actions.changeCityIndexPage(city);
    this.props.actions.getShopsByCityIndexPage(city);
  };

  reportLike = (shopId) => {
    this.props.actions.reportLikeEventForShop(shopId);
  };

  render() {
    const { classes, catalog } = this.props;
    const { cities, selectedCity, shops } = catalog.shopIndex;

    return (
      <div>
        <Paper className={classes.root} elevation={4}>
          <Typography component="h1" variant="h2" className={classes.headline}>
            {hebrewLabels.title}
          </Typography>

          <Typography className={classes.para} component="p">
            {hebrewLabels.text}
          </Typography>

          <ShopList
            cities={cities}
            shops={shops}
            stats={catalog.stats}
            selectedCity={selectedCity}
            onChangeCity={this.onChangeCity}
            reportShopLike={this.reportLike}
          />
        </Paper>
      </div>
    );
  }
}

OurShopsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles)(OurShopsPage);
