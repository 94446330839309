import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    padding: 16,
    marginTop: 33,
    marginLeft: "auto",
    marginRight: "auto",
  },
  imagesBox: {
    margin: 16,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  image: {
    margin: 16,
    maxWidth: 350,
  },
  headline: {
    fontSize: "14pt",
    fontWeight: 525,
  },
  para: {
    margin: "15px 8px",
  },
  "@media (min-width: 1024px)": {
    root: {
      marginTop: 80,
      width: 1000,
    },
    image: {
      maxWidth: 600,
    },
  },
});

const hebrewLabels = {
  title: "מה הלקוחות אומרים עלינו?",
};

const testimonials = [];
for (let i = 1; i <= 25; i++) {
  testimonials.push({ ext: "jpg", alt: "" });
}

testimonials[0].alt =
  "אבל באמת אחי תודה אין עלייך ועל השירות שלך כיף תמיד לחזור";

testimonials[1].alt = "תודה על השירות המקסים לא מובן מאליו בימינו";
testimonials[2].alt =
  "איתמר אירית תודה רבה על השירות המהיר, הצמיגים אצלי שנה טובה וחתימה טובה!";
testimonials[3].alt = "תודה על השירות.. הצמיג פשוט מושלם";
testimonials[4].alt = "הי איתמר, רציתי לומר תודה ענקית על ההצעה ששלחת לי...";
testimonials[5].alt = "אחלה של צמיג תודה על השירות";
testimonials[6].alt =
  "רציתי לומר תודה על הכל! על השירות על האיכות של המוצר ועל זה שהכרת לי מוסך עם אנשים מקצוענים הוגנים ואמינים!!! אמליץ עליך תמיד!! שיהיה המשך יום מדהים!";
testimonials[7].alt = "איתמר אתה אלוף וטוב שיש אותך בקבוצה שלנו";
testimonials[8].alt =
  "תודה איתמר על האיכפתיות גם בשישי מחוץ לשעות העבודה וגם להגיע אלי עם הצמיגים באופן אישי";
testimonials[9].alt = "כל הכבוד! באמת שירות נדיר בארץ";
testimonials[10].alt = "תודה איתמר בשם הקבוצה כולה אם יורשה לי";

testimonials[17].ext = "png";
testimonials[18].ext = "png";
testimonials[19].ext = "png";
testimonials[20].ext = "png";

class TestimonialsPage extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Paper className={classes.root} elevation={4}>
          <Typography component="h1" variant="h1" className={classes.headline}>
            {hebrewLabels.title}
          </Typography>

          <div className={classes.imagesBox}>
            {testimonials.map((t, i) => {
              let src = `https://sportyshop.s3.eu-west-2.amazonaws.com/catalog/testimonials/testimonial_${
                i + 1
              }.${t.ext}`;

              return (
                <img
                  title={t.alt}
                  alt={t.alt}
                  key={i}
                  className={classes.image}
                  src={src}
                />
              );
            })}
          </div>
        </Paper>
      </div>
    );
  }
}

TestimonialsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles)(TestimonialsPage);
