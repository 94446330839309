import * as types from "../types/actionTypes";
import objectAssign from "object-assign";
import fetch from "node-fetch";
import utils from "./../../universal/utils";
import * as cookieStorage from "./common/cookieStorage";

const cStorage = cookieStorage.getCookieStorage();

// const productionEdge = (typeof localStorage === 'undefined') ? process.env.EDGE_URL : '/edge';
// const basePath = (process.env.NODE_ENV === 'production') ? productionEdge : 'http://localhost:3010';

const basePath = utils.getEdgeBasePath();

export function getPopularSizes() {
  return function (dispatch) {
    fetch(`${basePath}/api/v1/popular/sizes?limit=8`).then(function (
      apiResult
    ) {
      return apiResult.json().then(function (data) {
        return dispatch({
          type: types.LOAD_POPULAR_SIZES,
          metrics: data,
        });
      });
    });
  };
}

export function setListPending(aBool) {
  return {
    type: types.LOADING,
    loading: aBool,
  };
}

export function getTopSellers() {
  return function (dispatch) {
    fetch(`${basePath}/api/v1/top/sellers`).then(function (apiResult) {
      return apiResult.json().then(function ({ products }) {
        return dispatch({
          type: types.LOAD_TOP_SELLERS,
          products,
        });
      });
    });
  };
}

export function loadTireDimensionsWidths() {
  return function (dispatch) {
    fetch(`${basePath}/api/v1/sizes/widths/for/MO`).then(function (apiResult) {
      return apiResult.json().then(function (widths) {
        return dispatch({
          type: types.LOAD_TIRE_DIMS_WIDTHS,
          widths,
        });
      });
    });
  };
}

export function getPaymentDetails({ orderId, token }) {
  return function (dispatch) {
    fetch(`${basePath}/api/v1/pdata/${orderId}/${token}`).then(function (
      apiResult
    ) {
      return apiResult.json().then(function (data) {
        return dispatch({
          type: types.ADMIN_LOAD_ORDER_PAYMENT,
          orderId,
          cData: data.cData,
        });
      });
    });
  };
}

export function updateStatusInflight() {
  return {
    type: types.ADMIN_STATUS_INFLIGHT,
  };
}

export function getOrders() {
  return function (dispatch) {
    fetch(`${basePath}/api/v1/orders?token=5b5ef0393d51444556cdfa2f`).then(
      function (apiResult) {
        return apiResult.json().then(function ({ orders, products }) {
          return dispatch({
            type: types.ADMIN_LOAD_ORDERS,
            orders,
            products,
          });
        });
      }
    );
  };
}

export function collectMetric(metadata) {
  const stats = cStorage.getObject("stats");
  const { visitorId } = stats;

  return function (dispatch) {
    fetch(`${basePath}/collector/collect/${visitorId}`, {
      method: "POST",
      body: JSON.stringify(metadata),
      headers: { "Content-Type": "application/json" },
    }).then(function (apiResult) {
      return apiResult.json().then(function (json) {
        if (json.ok) {
          console.log("metric reported correctly");
        }
      });
    });
  };
}

export function reportLikeEventForProduct(productId) {
  const stats = cStorage.getObject("stats");
  const { visitorId } = stats;

  return function (dispatch) {
    fetch(`${basePath}/collector/like/product/${productId}/${visitorId}`).then(
      function (apiResult) {
        return apiResult.json().then(function () {
          return dispatch({
            type: types.PRODUCT_LIKE,
            productId,
            visitorId,
          });
        });
      }
    );
  };
}

export function reportLikeEventForShop(shopId) {
  const stats = cStorage.getObject("stats");
  const { visitorId } = stats;

  return function (dispatch) {
    fetch(`${basePath}/collector/like/shop/${shopId}/${visitorId}`).then(
      function (apiResult) {
        return apiResult.json().then(function () {
          return dispatch({
            type: types.SHOP_LIKE,
            shopId,
            visitorId,
          });
        });
      }
    );
  };
}

export function getTrackingVisitorId() {
  const statsFromCookieStorage = cStorage.getObject("stats");

  if (statsFromCookieStorage !== null) {
    // User has an active visitorId, don't ask for one.
    return function (dispatch) {
      dispatch({
        type: types.STATS_UPDATED,
        stats: statsFromCookieStorage,
      });
    };
  }

  return function (dispatch) {
    fetch(`${basePath}/collector/visitor/id`).then(function (apiResult) {
      return apiResult.json().then(function (result) {
        const stats = {
          data: {
            likes: {
              products: [],
              shops: [],
            },
          },
          visitorId: result.visitorId,
        };

        dispatch({
          type: types.STATS_UPDATED,
          stats,
        });

        cStorage.setObject("stats", stats);
      });
    });
  };
}

export function submitBestOffer(offer) {
  return function (dispatch) {
    fetch(`${basePath}/api/v1/best-offers`, {
      body: JSON.stringify(offer),
      cache: "no-cache",
      headers: {
        "content-type": "application/json",
      },
      method: "POST",
      mode: "cors",
      redirect: "follow",
      referrer: "no-referrer",
    }).then(function (apiResult) {
      return apiResult.json().then(({ data }) => {
        dispatch({
          type: types.BEST_OFFER_SENT,
          data,
        });
      });
    });
  };
}

export function searchTiresByDimensions(width, profile, rim) {
  return function (dispatch) {
    fetch(`${basePath}/api/v1/tires/MO/${width}/${profile}/${rim}`).then(
      function (apiResult) {
        return apiResult.json().then((data) => {
          return dispatch({
            type: types.LOAD_MOTO_TIRES,
            motoTires: data,
          });
        });
      }
    );
  };
}

export function searchTiresByBrand(brand) {
  return (dispatch) => {
    return fetch(`${basePath}/api/v1/bybrand/MO/tires/${brand}`).then(function (
      apiResult
    ) {
      return apiResult.json().then((data) => {
        return dispatch({
          type: types.LOAD_MOTO_TIRES,
          motoTires: data,
        });
      });
    });
  };
}

export function searchMotoTiresByCategory(categoryId) {
  return (dispatch) => {
    return fetch(`${basePath}/api/v1/category/${categoryId}/items`).then(
      (apiResult) => {
        return apiResult.json().then((data) => {
          return dispatch({
            type: types.LOAD_MOTO_TIRES_BY_CAT,
            motoTires: data.result,
            nextShipment: data.settings.nextShipmentDisplayTime,
            category: data.category,
          });
        });
      }
    );
  };
}

export function changeCityIndexPage(city) {
  return {
    type: types.PICK_CITY_SHOPS_PAGE,
    city,
  };
}

export function getShopCities() {
  return function (dispatch) {
    fetch(`${basePath}/api/v1/cities`).then(function (apiResult) {
      return apiResult.json().then(function (cities) {
        return dispatch({
          type: types.LOAD_CITIES_FOR_SHOPS_PAGE,
          cities,
        });
      });
    });
  };
}

export function getShopsByCityIndexPage(city) {
  return function (dispatch) {
    fetch(`${basePath}/api/v1/cities/${city}/shops`).then(function (apiResult) {
      return apiResult.json().then(function (shops) {
        return dispatch({
          type: types.LOAD_SHOPS_INDEX_PAGE,
          shops,
        });
      });
    });
  };
}

export function getProductByEAN(ean) {
  return (dispatch) => {
    return fetch(`${basePath}/api/v1/tires/MO/${ean}`).then((apiResult) => {
      return apiResult.json().then((data) => {
        const products = data.result;
        if (products.length === 0){
          console.log('dispatching this!');
          dispatch({
            type: types.LOAD_PRODUCT,
            productPending: false,
            product: null,
            productNotFound: true,
          });
        }

        if (products && products[0] && products[0].ean === ean) {
          console.log('dispatching that instead!');
          dispatch({
            type: types.LOAD_PRODUCT,
            product: products[0],
            productNotFound: false,
            nextShipment: data.settings.nextShipmentDisplayTime,
            currentShipment: data.settings.currentShipmentDisplayTime,
            nextShipmentDeadline: data.settings.nextShipmentOrderDeadline,
            productPending: false,
          });
        }
      });
    });
  };
}

export function loadTireProfilesByWidth(width) {
  return (dispatch) => {
    fetch(`${basePath}/api/v1/sizes/profiles/for/MO/${width}`)
      .then((apiResult) => {
        return apiResult.json().then((profiles) => {
          dispatch({
            type: types.MOTO_TIRE_DIMS_PROFILES,
            profiles,
          });

          if (profiles.length === 2) {
            dispatch({
              type: types.MOTO_SELECT_PROFILE_DIM,
              selectedProfile: profiles[1],
            });
          }

          return true;
        });
      })
      .catch((err) => err);
  };
}

export function getShopByName(name) {
  return function (dispatch) {
    fetch(`${basePath}/api/v1/shop/${name}`).then(function (apiResult) {
      return apiResult.json().then(function (shop) {
        return dispatch({
          type: types.LOAD_SHOP_BY_NAME,
          shop,
        });
      });
    });
  };
}

export function loadTireRimsByWidthAndProfile(width, profile) {
  return (dispatch) => {
    fetch(`${basePath}/api/v1/sizes/for/MO/${width}/${profile}`)
      .then((apiResult) => {
        return apiResult.json().then((rims) => {
          dispatch({
            type: types.MOTO_TIRE_DIMS_RIMS,
            rims,
          });

          if (rims.length === 1) {
            dispatch({
              type: types.MOTO_SELECT_RIM_DIM,
              selectedRim: rims[0],
            });
          }

          return true;
        });
      })
      .catch((err) => err);
  };
}

export function getInstallCities() {
  return function (dispatch) {
    fetch(`${basePath}/api/v1/checkout/installation/cities`).then(function (
      apiResult
    ) {
      return apiResult.json().then(function (cities) {
        return dispatch({
          type: types.LOAD_INSTALL_CITIES,
          cities,
        });
      });
    });
  };
}

export function getShopsByCity(cityCode) {
  return function (dispatch) {
    fetch(`${basePath}/api/v1/checkout/shops/in/${cityCode}`).then(function (
      apiResult
    ) {
      return apiResult.json().then(function (shops) {
        return dispatch({
          type: types.LOAD_SHOPS,
          shops,
        });
      });
    });
  };
}

export function pickInstallDate(date) {
  return {
    type: types.PICK_INSTALL_DATE,
    date,
  };
}

export function getOpenDatesForShop(shopCodename) {
  return function (dispatch) {
    fetch(`${basePath}/api/v1/checkout/install/dates/${shopCodename}`).then(
      function (apiResult) {
        return apiResult.json().then(function (dates) {
          return dispatch({
            type: types.LOAD_OPENDATES_FOR_INSTALLATION,
            dates,
          });
        });
      }
    );
  };
}

export function getInstallSlotsForDate(date, shopCodename) {
  return function (dispatch) {
    fetch(
      `${basePath}/api/v1/checkout/install/slots/${shopCodename}/${date}`
    ).then(function (apiResult) {
      return apiResult.json().then(function (slots) {
        return dispatch({
          type: types.LOAD_INSTALL_SLOTS,
          slots,
        });
      });
    });
  };
}

function getProductByEANInternal(ean) {
  return fetch(`${basePath}/api/v1/tires/MO/${ean}`).then((result) => {
    return result.json();
  });
}

export function getCartProducts(cart) {
  let nextShipment = false;
  let currentShipmentDisplayTime = "";
  let nextShipmentOrderDeadline = "";

  return (dispatch) => {
    return Promise.all(
      cart.items.map((item) =>
        getProductByEANInternal(item.ean).then((data) => {
          nextShipment = data.settings.nextShipmentDisplayTime;
          currentShipmentDisplayTime = data.settings.currentShipmentDisplayTime;
          nextShipmentOrderDeadline = data.settings.nextShipmentOrderDeadline;
          return data.result[0];
        })
      )
    ).then((results) => {
      return dispatch({
        type: types.CART_PRODUCTS_DATA,
        results,
        nextShipment,
        currentShipmentDisplayTime,
        nextShipmentOrderDeadline,
      });
    });
  };
}

export function pickInstallSlot(slot) {
  return {
    type: types.PICK_INSTALL_SLOT,
    slot,
  };
}

export function clearPickShop() {
  return {
    type: types.CLEAR_PICK_SHOP,
  };
}

export function pickShopById(shopId) {
  return {
    type: types.CHANGE_INSTALL_SHOP,
    shopId,
  };
}

export function changeProductQuantity(ean, quantity) {
  return {
    type: types.CHANGE_PRODUCT_QUANTITY_IN_CART,
    quantity,
    ean,
  };
}

export function changeInstallCity(city) {
  return {
    type: types.CHANGE_INSTALL_CITY,
    city,
  };
}

export function removeItemFromCart(ean) {
  return {
    type: types.REMOVE_PRODUCT_FROM_CART,
    ean,
  };
}

export function changeSip(sip) {
  return {
    type: types.CHANGE_SIP,
    sip,
  };
}

export function toggleSnackbar(toggle, snack) {
  return {
    type: types.TOGGLE_SNACKBAR,
    snack,
    toggle,
  };
}

export function selectWidth(selectedWidth) {
  return {
    type: types.MOTO_SELECT_WIDTH_DIM,
    selectedWidth,
  };
}

export function restoreCart() {
  return {
    type: types.RESTORE_CART,
  };
}

export function onFormFieldChangeInShippingStep(name, value) {
  return {
    type: types.FORM_FIELD_CHANGE_SHIPPING,
    name,
    value,
  };
}

export function onFormFieldChangePersonalStep(name, value) {
  let finalValue = value;
  if (name === "emailaddress") {
    finalValue = value.replace(/[^a-zA-Z0-9@._%+-]/g, "");
  }

  return {
    type: types.FORM_FIELD_CHANGE_PERSONAL,
    name,
    value: finalValue,
  };
}

export function onFormFieldChangeInPaymentStep(name, value) {
  return {
    type: types.FORM_FIELD_CHANGE_PAYMENT,
    name,
    value,
  };
}

export function changePaymentMethod(method) {
  return {
    type: types.CHANGE_PAYMENT_METHOD,
    method,
  };
}

export function updateOrderProps(id, orderProps) {
  return function (dispatch) {
    fetch(`${basePath}/api/v1/order/${id}?token=5b277c13d2a903048f5aeedd`, {
      body: JSON.stringify(orderProps),
      cache: "no-cache",
      headers: {
        "content-type": "application/json",
      },
      method: "POST",
      mode: "cors",
      redirect: "follow",
      referrer: "no-referrer",
    }).then(function (apiResult) {
      return apiResult.json().then(function (result) {
        return dispatch({
          type: types.ADMIN_ORDER_UPDATED,
          result,
        });
      });
    });
  };
}

export function submitOrder(cart, checkout) {
  const shop = checkout.shops.find((s) => s.id === checkout.selectedShop);
  const cleanCheckout = objectAssign({}, checkout, {
    shop,
  });

  delete cleanCheckout.cities;
  delete cleanCheckout.shops;
  delete cleanCheckout.dates;
  delete cleanCheckout.slots;

  return function (dispatch) {
    fetch(`${basePath}/api/v1/checkout`, {
      body: JSON.stringify({
        cart,
        checkout: cleanCheckout,
      }),
      cache: "no-cache",
      headers: {
        "content-type": "application/json",
      },
      method: "POST",
      mode: "cors",
      redirect: "follow",
      referrer: "no-referrer",
    }).then(function (apiResult) {
      return apiResult.json().then(function (result) {
        return dispatch({
          type: types.COMPLETE_ORDER,
          result,
        });
      });
    });
  };
}

export function updateFilters(filters) {
  return {
    type: types.UPDATE_FILTERS,
    filters,
  };
}

export function addProductToCart(ean, isSet = false) {
  return {
    type: types.ADD_PRODUCT_TO_CART,
    ean,
    isSet,
  };
}

export function selectProfile(selectedProfile) {
  return {
    type: types.MOTO_SELECT_PROFILE_DIM,
    selectedProfile,
  };
}

export function resetDimensionsMoto() {
  return {
    type: types.MOTO_RESET_DIMS,
  };
}

export function selectRim(selectedRim) {
  return {
    type: types.MOTO_SELECT_RIM_DIM,
    selectedRim,
  };
}
