import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const cdnPath = 'https://s3.eu-west-2.amazonaws.com/sportyshop/catalog';

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(),
    marginTop: theme.spacing(3),
  },
  priceTagIcon: {
    backgroundImage: `url(${cdnPath}/common/price-tag.svg)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100px 100px',
  },
  serviceIcon: {
    backgroundImage: `url(${cdnPath}/common/like.svg)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100px 100px',
  },
  qualityIcon: {
    backgroundImage: `url(${cdnPath}/common/quality.svg)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100px 100px',
  },
  boxTitle: {
    fontWeight: 700,
    textAlign: 'center',
    color: '#3c3c3d',
    fontSize: '16pt',
    marginTop: 5,
    marginBottom: 15,
  },
  textBox: {
    color: '#3c3c3d',
    textAlign: 'center',
    fontSize: '12pt',
    margin: theme.spacing(),
    marginTop: 4,
  },
  serviceList: {
    listStyle: 'none',
    padding: 0,
  },
  serviceListItem: {
    marginTop: 5,
  },
  bulletCommon: {
    fontSize: '12pt',
  },
  timelineIconBase: {
    width: 100,
    height: 100,
    marginTop: 25,
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  '&:first-child': {
    marginTop: 0,
  },
});

const hebrewLabels = {
  priceTitle: 'מחיר מנצח',
  priceText:
    'אנחנו מייבאים את המוצרים בעצמנו מה שמאפשר את היכולת לתת את המחיר הכי תחרותי וזול בשוק!',
  serviceTitle: 'שירות מעולה',
  serviceText: 'יחס אישי ומקצועי לאורך כל תהליך הרכישה!',
  serviceText2:
    'מערך מוסכים מקצועיים בפריסה ארצית להתקנת הצמיגים שתרכשו דרכנו ',
  serviceText3: 'אפשרות רכישה אונליין מהמתקדמות בעולם כולל קביעת תור במוסך!',
  serviceText4: 'משלוחים תוך 24 שעות לביתכם / לנקודת ההתקנה שבחרתם',
  productsTitle: 'מוצרים איכותיים',
  productsText: 'צמיגים טריים ומלאי מתחדש באופן שוטף',
  productsText2: 'מלאי מיידי של כל החברות והדגמים המובילים בעולם הצמיגים!',
};

class KeyBenefits extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div
          className={`${classes.priceTagIcon} ${classes.timelineIconBase}`}
        />
        <Typography className={classes.boxTitle}>
          {hebrewLabels.priceTitle}
        </Typography>
        <Typography className={classes.textBox}>
          {hebrewLabels.priceText}
        </Typography>

        <div className={`${classes.serviceIcon} ${classes.timelineIconBase}`} />
        <Typography className={classes.boxTitle}>
          {hebrewLabels.serviceTitle}
        </Typography>

        <ul className={`${classes.serviceList} ${classes.textBox}`}>
          <li className={`${classes.serviceListItem} ${classes.bulletCommon}`}>
            {hebrewLabels.serviceText2}
          </li>
          <li className={`${classes.serviceListItem} ${classes.bulletCommon}`}>
            {hebrewLabels.serviceText3}
          </li>
          <li className={`${classes.serviceListItem} ${classes.bulletCommon}`}>
            {hebrewLabels.serviceText4}
          </li>
          <li className={`${classes.serviceListItem} ${classes.bulletCommon}`}>
            {hebrewLabels.serviceText}
          </li>
        </ul>

        <div className={`${classes.qualityIcon} ${classes.timelineIconBase}`} />
        <Typography className={classes.boxTitle}>
          {hebrewLabels.productsTitle}
        </Typography>

        <ul className={`${classes.serviceList} ${classes.textBox}`}>
          <li className={`${classes.serviceListItem} ${classes.bulletCommon}`}>
            {hebrewLabels.productsText}
          </li>
          <li className={`${classes.serviceListItem} ${classes.bulletCommon}`}>
            {hebrewLabels.productsText2}
          </li>
        </ul>
      </div>
    );
  }
}

KeyBenefits.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(KeyBenefits);
