import React from "react";
import PropTypes from "prop-types";
import { Redirect, Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import Chip from "@material-ui/core/Chip";

import SearchByMotoTireSize from "./SearchByMotoTireSize";
import OurBrands from "./OurBrands";
import ThreePilars from "./ThreePilars";
import TopSellers from "./TopSellers";

const baseCoverUrl =
  "https://s3.eu-west-2.amazonaws.com/sportyshop/catalog/homepage/covers/";
const mobile = ["cover.jpeg"];
const desktop = [
  "desktop/32002310_m.jpg",
  "desktop/81789642_m.jpg",
  "desktop/99150499_m.jpg",
  "desktop/99150501_m.jpg",
];

const homepageVideoUrl =
  "https://sportyshop.s3.eu-west-2.amazonaws.com/catalog/video/sportyshop-homepage.mp4";

const styles = (theme) => ({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: 0,
    width: "initial",
    marginLeft: "auto",
    marginRight: "auto",

    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  homepageRoot: {
    marginTop: 8,
  },
  button: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  banner: {
    marginTop: 10,
    position: "relative",
    display: "block",
  },
  imageBackdrop: {
    display: "block",
    height: 196,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  sloganContainer: {
    justifyContent: "center",
    display: "flex",
    flexFlow: "column",
    position: "absolute",
    cursor: "default",
    top: 0,
    left: 0,
    height: 196,
  },
  homeContainer: {
    display: "block",
  },
  homeContainerDesktop: {
    display: "none",
  },
  pillarsContainer: {
    background: "#fff",
  },
  topSellerInnerContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  recentSizesContainer: {},
  formGroup: {
    display: "block",
    marginTop: 0,
    marginLeft: "auto",
    marginRight: "auto",
  },
  popularSizesCont: {
    margin: 8,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  videoContainer: {
    position: "absolute",
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  overlayVideo: {
    position: "relative",
    height: 196,
    minWidth: "100%",
    minHeight: "100%",
    objectFit: "cover",
  },
  pillarsDesktop: {
    display: "none",
  },
  pillarsMobile: {
    display: "flex",
    width: "100%",
  },
  "@media (min-width: 1024px)": {
    recentSizesContainer: {
      display: "none",
    },
    desktopSubmitButton: {
      width: 240,
      margin: "12px auto",
    },
    formGroup: {
      display: "flex",
      justifyContent: "center",
    },
    pillarsMobile: {
      display: "none",
    },
    pillarsDesktop: {
      display: "block",
    },
    homeBoxTitle: {
      fontSize: 22,
      margin: 0,
      padding: 0,
    },
    sloganDesktop: {
      background: "#4f4f4f",
      padding: "0 8px",
      fontSize: "50px",
      textShadow: "4px 0px 7px rgba(42, 45, 51, 0.5)",
      fontWeight: "540",
      color: "#fefefe",
    },
    sloganSubtitleDesktop: {
      background: "#4f4f4f",
      padding: "0 8px",
      fontSize: "24px",
      fontWeight: "540",
      color: "#ffe89c",
    },
    sloganContainerDesktop: {
      width: 1000,
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "right",
      position: "relative",
      top: 25,
      marginLeft: "auto",
      marginRight: "auto",
    },
    homeContainerDesktop: {
      display: "block",
      position: "relative",
      width: 1000,
      marginLeft: "auto",
      marginRight: "auto",
    },
    banner: {
      marginTop: 49,
      height: 760,
    },
    videoContainer: {
      position: "absolute",
      height: "100%",
      width: "100%",
      overflow: "hidden",
    },
    overlayVideo: {
      position: "relative",
      top: 0,
      minWidth: "100%",
      minHeight: "100%",
      objectFit: "cover",
    },
    homeContainer: {
      display: "none",
    },
    pillarsContainer: {
      background: "#fff",
    },
    topSellerInnerContainer: {
      width: 1000,
      marginLeft: "auto",
      marginRight: "auto",
    },
    pillarsInnerContainer: {
      width: 1000,
      marginLeft: "auto",
      marginRight: "auto",
    },
    root: {
      position: "absolute",

      width: 448,
      top: 230,
      left: 0,
    },
    sloganContainer: {
      display: "none",
    },
    imageBackdrop: {
      display: "none",
    },
  },
  popularSize: {
    margin: 4,
    fontSize: 12,
    cursor: "pointer",
  },
  slogan: {
    padding: "0 8px 0 8px",
    textAlign: "center",
    fontSize: "17pt",
    margin: "12px 0",
    textShadow: "4px 0px 7px rgba(42, 45, 51, 0.5)",
    fontWeight: "540",
    color: "#fefefe",
  },
  subtitleSlogan: {
    margin: "0 20px",
    textAlign: "left",
    fontSize: 13,
    fontWeight: "540",
    color: "#ffe89c",
  },
  stepsRoot: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  homeH3: {
    borderBottom: "solid 1px #cecece",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: "11pt",
  },
  stepChip: {
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    fontSize: 15,
    color: "#fefefe",
  },
  sloganButton: {
    border: "solid 2px #fefefe",
    margin: "10px auto",
    width: "50%",
    color: "#fefefe",
  },
});

const hebrewLabels = {
  slogan1: "צמיגים לאופנוע בזול - חפש, השווה וחסוך",
  subtitle:
    "בחר ממגוון צמיגים רחב, השווה התקנה במגוון מוסכים ברחבי הארץ כולל תור בקליק.",
  subtitle2: "המחיר הזול ביותר מובטח",
  titles: {
    popularSizes: "מידות פופלריות",
    searchBySize: "חיפוש לפי מידת צמיג",
    homeJumboTitle: "חפש את הצמיג המושלם עבורך",
  },
  bannerCTA: "מידע נוסף",
};

class HomePage extends React.Component {
  constructor() {
    super();
    this.state = {
      fireRedirect: false,
    };
  }

  componentDidMount() {
    // Load up the top sellers
    this.props.actions.getTopSellers();
    this.props.actions.getPopularSizes();
  }

  validateForm = (e) => {
    e.preventDefault();
    this.setState({ fireRedirect: true });
  };

  render() {
    const { classes, actions, catalog } = this.props;
    const { fireRedirect } = this.state;

    const { topSellers, popularSizes = [] } = catalog;

    // Deduced values
    const isSubmitDisabled =
      catalog.selectedWidth === "" ||
      catalog.selectedProfile === "" ||
      catalog.selectedRim === ""
        ? true
        : false;

    return (
      <div className={classes.homepageRoot}>
        <div className={classes.banner}>
          <div className={classes.videoContainer}>
            <video
              playsInline
              autoPlay
              muted
              loop
              className={classes.overlayVideo}
            >
              <source src={homepageVideoUrl} type="video/mp4" />
            </video>
          </div>

          <div className={classes.imageBackdrop} />
          <div className={classes.sloganContainer}>
            <Typography className={classes.slogan} component="h1">
              {hebrewLabels.slogan1}
            </Typography>

            <Typography className={classes.subtitleSlogan} component="h2">
              {hebrewLabels.subtitle}
            </Typography>

            <Typography className={classes.subtitleSlogan} component="h2">
              {hebrewLabels.subtitle2}
            </Typography>

            <Button
              component={Link}
              to="/our-direct-business-model"
              className={classes.sloganButton}
            >
              {hebrewLabels.bannerCTA}
            </Button>
          </div>

          <div className={classes.homeContainerDesktop}>
            <Paper className={classes.root} elevation={4}>
              <Typography component="h3" className={classes.homeBoxTitle}>
                {hebrewLabels.titles.homeJumboTitle}
              </Typography>

              <h3 className={classes.homeH3}>
                {hebrewLabels.titles.popularSizes}
              </h3>
              <div className={classes.popularSizesCont}>
                {popularSizes.map((size, idx) => {
                  const displaySize = `${size.width}/${size.profile}-${size.rim}`;
                  const to = `/list/moto/${size.width}/${size.profile}/${size.rim}`;

                  return (
                    <Chip
                      key={idx}
                      component={Link}
                      to={to}
                      className={classes.popularSize}
                      label={displaySize}
                    />
                  );
                })}
              </div>

              <h3 className={classes.homeH3}>
                {hebrewLabels.titles.searchBySize}
              </h3>
              <FormGroup row className={classes.formGroup}>
                <SearchByMotoTireSize
                  actions={actions}
                  widths={catalog.widths}
                  profiles={catalog.profiles}
                  rims={catalog.rims}
                  selectedWidth={catalog.selectedWidth}
                  selectedProfile={catalog.selectedProfile}
                  selectedRim={catalog.selectedRim}
                />
                <Button
                  type="button"
                  variant="contained"
                  disabled={isSubmitDisabled}
                  color="primary"
                  className={classNames(
                    classes.button,
                    classes.desktopSubmitButton
                  )}
                  onClick={this.validateForm}
                >
                  חפש צמיגים
                </Button>
              </FormGroup>
              {fireRedirect && (
                <Redirect
                  to={`/list/moto/${catalog.selectedWidth}/${catalog.selectedProfile}/${catalog.selectedRim}`}
                />
              )}
            </Paper>

            <div className={classes.sloganContainerDesktop}>
              <Typography className={classes.sloganDesktop} component="h1">
                {hebrewLabels.slogan1}
              </Typography>

              <Typography
                className={classes.sloganSubtitleDesktop}
                component="h2"
              >
                {hebrewLabels.subtitle}
              </Typography>

              <Typography
                className={classes.sloganSubtitleDesktop}
                component="h2"
              >
                {hebrewLabels.subtitle2}
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.homeContainer}>
          <Paper className={classes.root} elevation={4}>
            <h3 className={classes.homeH3}>
              {hebrewLabels.titles.popularSizes}
            </h3>

            {popularSizes.map((size, idx) => {
              const displaySize = `${size.width}/${size.profile}-${size.rim}`;
              const to = `/list/moto/${size.width}/${size.profile}/${size.rim}`;

              return (
                <Chip
                  key={idx}
                  component={Link}
                  to={to}
                  className={classes.popularSize}
                  label={displaySize}
                />
              );
            })}

            <h3 className={classes.homeH3}>
              {hebrewLabels.titles.searchBySize}
            </h3>
            <FormGroup row className={classes.formGroup}>
              <SearchByMotoTireSize
                actions={actions}
                widths={catalog.widths}
                profiles={catalog.profiles}
                rims={catalog.rims}
                selectedWidth={catalog.selectedWidth}
                selectedProfile={catalog.selectedProfile}
                selectedRim={catalog.selectedRim}
              />
              <Button
                type="button"
                variant="contained"
                disabled={isSubmitDisabled}
                color="primary"
                className={classes.button}
                onClick={this.validateForm}
              >
                חפש צמיגים
              </Button>
            </FormGroup>
            {fireRedirect && (
              <Redirect
                to={`/list/moto/${catalog.selectedWidth}/${catalog.selectedProfile}/${catalog.selectedRim}`}
              />
            )}
          </Paper>
        </div>

        <div className={classes.pillarsContainer}>
          <div className={classes.topSellerInnerContainer}>
            <TopSellers data={topSellers} />
          </div>
        </div>

        <ThreePilars className={classNames(classes.pillarsContainer)} />

        <div className={classes.pillarsContainer}>
          <div className={classes.topSellerInnerContainer}>
            <OurBrands />
          </div>
        </div>
      </div>
    );
  }
}

HomePage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomePage);
