import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    padding: 16,
    marginTop: 33,
    marginLeft: "auto",
    marginRight: "auto",
  },
  headline: {
    fontSize: "14pt",
    fontWeight: 525,
  },
  para: {
    margin: "15px 8px",
  },
  "@media (min-width: 1024px)": {
    root: {
      marginTop: 80,
      width: 1000,
    },
  },
});

const hebrewLabels = {
  title: "שירות לקוחות",
  text: "שירות הלקוחות שלנו זמין לשירותכם מראשון עד חמישי",
  text1: "מהשעה 09:00 בבוקר ועד 17:00 בערב",
  sendUsEmail: "שלח לנו מייל",
  text2: "אנו זמינים באימייל service@sportyshop.co.il או בטלפון 054-9200911",
};

class CustomerServicePage extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Paper className={classes.root} elevation={4}>
          <Typography component="h1" variant="h1" className={classes.headline}>
            {hebrewLabels.title}
          </Typography>

          <Typography className={classes.para} component="p">
            {hebrewLabels.text}
          </Typography>
          <Typography className={classes.para} component="p">
            {hebrewLabels.text1}
          </Typography>
          <Typography className={classes.para} component="p">
            {hebrewLabels.text2}
          </Typography>

          <Button
            component="a"
            href="mailto:service@sportyshop.co.il"
            variant="contained"
            color="primary"
          >
            {hebrewLabels.sendUsEmail}
          </Button>
        </Paper>
      </div>
    );
  }
}

CustomerServicePage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomerServicePage);
