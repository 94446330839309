/* eslint-disable import/no-named-as-default */
import React from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";

import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Paper from "@material-ui/core/Paper";
import SetsFilterSwitch from "./SetsFilterSwitch";
import BrandFilter from "./BrandFilter";

const styles = {
  appBar: {
    background: "#ffffff",
    position: "fixed",
    top: 59,
    width: "100%",
    borderBottom: "solid 1px #dcdcdc",
  },
  root: {
    display: "flex",
    justifyContent: "center",
  },
  desktop: {
    display: "none",
  },
  "@media (min-width: 1024px)": {
    appBar: {
      //top: 61,
      display: "none",
    },
    desktop: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      margin: "17px 0px",
      background: "#fefefe",
    },
  },
};

class FilterBar extends React.Component {
  updateSetsToggle = (checked) => {
    const newFilters = cloneDeep(this.props.data);
    newFilters.hideSets = checked;
    this.props.updateFilters(newFilters);
  };

  updateSelectedBrands = (brand) => {
    if (!brand) {
      return;
    }

    const newFilters = cloneDeep(this.props.data);
    if (!newFilters.brands) {
      newFilters.brands = [];
    }

    const bIndex = newFilters.brands.findIndex(
      (b) => b.toLowerCase() === brand.toLowerCase()
    );
    if (bIndex !== -1) {
      newFilters.brands.splice(bIndex, 1);
    } else {
      newFilters.brands.push(brand);
    }

    this.props.updateFilters(newFilters);
  };

  render() {
    const { classes, data, tires } = this.props;
    /*eslint no-unused-vars: 0*/
    let showSetsFilter = true; // To be used when more filters are avail

    const sets = tires.filter((p) => {
      if ("set" in p && p.set) {
        return true;
      }
      return false;
    });

    let stylesOfRoot = {
      background: "#fefefe",
    };
    if (sets.length === 0) {
      showSetsFilter = false;
      stylesOfRoot = {
        display: "none",
      };
    }

    return (
      <React.Fragment>
        <AppBar
          position="static"
          className={classes.appBar}
          style={stylesOfRoot}
        >
          <SetsFilterSwitch
            toggleSets={data.sets}
            changeHandler={this.updateSetsToggle}
          />
        </AppBar>
        <Paper className={classes.desktop} style={stylesOfRoot}>
          <SetsFilterSwitch
            toggleSets={data.sets}
            changeHandler={this.updateSetsToggle}
          />

          <BrandFilter
            tires={tires}
            brands={this.props.data.brands}
            changeHandler={this.updateSelectedBrands}
          />
        </Paper>
      </React.Fragment>
    );
  }
}

FilterBar.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  tires: PropTypes.array,
  updateFilters: PropTypes.func,
};

export default withStyles(styles)(FilterBar);
