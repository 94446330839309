/* eslint-disable import/no-named-as-default */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  mobile: {
    marginTop: 8,
  },
  desktop: {
    display: 'none',
  },
  sectionTitle: {
    textDecoration: 'none',
  },
  typoLink: {
    fontSize: 15,
    textDecoration: 'none',
    display: 'block',
    color: '#3D3C3C',
  },
  logoImg: {
    height: 120,
  },
  tireSize: {
    direction: 'rtl',
    display: 'inline-flex',
  },
  '@media (min-width: 1024px)': {
    paymentMethodLogo: {
      height: 58,
    },
    mobile: {
      display: 'none',
    },
    item: {
      maxWidth: 315,
    },
    desktop: {
      display: 'flex',
    },
    root: {
      width: '100%',
      marginTop: 0,
      backgroundColor: theme.palette.background.paper,
    },
    disclaimer: {
      textAlign: 'center',
      padding: theme.spacing(2),
    },
    inner: {
      borderTop: 'solid 1px #b0b0b0',
      paddingTop: 16,
      paddingBottom: 100,
      width: 1000,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    footer: {},
  },
});

class CatalogFooter extends React.Component {
  render() {
    const { classes } = this.props;

    const footers = [
      {
        title: 'יצרני צמיגים',
        key: 'manufacturers',
        description: [
          {
            key: 'pirelli-moto-tires',
            text: 'צמיגי פירלי',
            url: '/list/moto/by/brand/Pirelli',
          },
          {
            key: 'bridgestone-moto-tires',
            text: 'צמיגי ברידג׳סטון',
            url: '/list/moto/by/brand/Bridgestone',
          },
          {
            key: 'michelin-moto-tires',
            text: 'צמיגי מישלין',
            url: '/list/moto/by/brand/Michelin',
          },
          {
            key: 'continental-moto-tires',
            text: 'צמיגי Continental',
            url: '/list/moto/by/brand/Continental',
          },
          {
            key: 'metzeler-moto-tires',
            text: 'צמיגי מצלר',
            url: '/list/moto/by/brand/Metzeler',
          },
          {
            key: 'sunf-moto-tires',
            text: 'צמיגי Sun-F',
            url: '/list/moto/by/brand/SUNF',
          },
          {
            key: 'maxxis-moto-tires',
            text: 'צמיגי Maxxis',
            url: '/list/moto/by/brand/Maxxis',
          },
        ],
      },
      {
        title: 'מידות צמיגים',
        key: 'tire_sizes',
        description: [
          {
            key: 'tires-of-size-180',
            text: 'צמיגים לאופנועים במידה 180/55ZR17',
            url: '/list/moto/180/55/17',
          },
          {
            key: 'tires-of-size-160',
            text: 'צמיגים לאופנועים במידה 160/60ZR17',
            url: '/list/moto/160/60/17',
          },
          {
            key: 'tires-of-size-120-70-17',
            text: 'צמיגים לאופנועים במידה 120/70ZR17',
            url: '/list/moto/120/70/17',
          },
          {
            key: 'tires-of-size-140-70-17',
            text: 'צמיגים לאופנועים במידה 140/70R17',
            url: '/list/moto/140/70/17',
          },
          {
            key: 'tires-of-size-120-90-18',
            text: 'צמיגים לאופנועים במידה 120/90-18',
            url: '/list/moto/120/90/18',
          },
          {
            key: 'tires-of-size-110-100-18',
            text: 'צמיגים לאופנועים במידה 110/100-18',
            url: '/list/moto/110/100/18',
          },
        ],
      },
    ];

    return (
      <footer className={classNames(classes.footer, classes.layout)}>
        <div className={classes.inner}>
          <Grid container spacing={1} className={classes.desktop}>
            {footers.map(footer => (
              <Grid item xs={12} key={footer.key} className={classes.item}>
                {footer.title && (
                  <Typography
                    variant="h6"
                    gutterBottom
                    className={classes.sectionTitle}
                  >
                    {footer.title}
                  </Typography>
                )}
                {footer.description &&
                  footer.description.map(item => {
                    return (
                      <React.Fragment key={item.key}>
                        {item.external === undefined && (
                          <Typography
                            component={Link}
                            to={item.url}
                            variant="body2"
                            className={`${classes.typoLink} ${
                              classes[item.cls]
                            }`}
                          >
                            {item.text}
                          </Typography>
                        )}

                        {item.external && item.external === true && (
                          <Typography
                            className={`${classes.typoLink} ${
                              classes[item.cls]
                            }`}
                            component="a"
                            href={item.url}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {item.text}
                          </Typography>
                        )}
                      </React.Fragment>
                    );
                  })}
                {footer.content !== undefined && footer.content}
              </Grid>
            ))}
          </Grid>
        </div>

        <div className={classes.mobile}>
          <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
            {footers.map(footer => (
              <Grid item sm={6} key={footer.key}>
                {footer.title && (
                  <Typography
                    variant="h6"
                    gutterBottom
                    className={classes.sectionTitle}
                  >
                    {footer.title}
                  </Typography>
                )}
                {footer.description &&
                  footer.description.map(item => {
                    return (
                      <React.Fragment key={item.key}>
                        {item.external === undefined && (
                          <Typography
                            component={Link}
                            to={item.url}
                            variant="body2"
                            className={classes.typoLink}
                          >
                            {item.text}
                          </Typography>
                        )}

                        {item.external && item.external === true && (
                          <Typography
                            className={classes.typoLink}
                            component="a"
                            href={item.url}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {item.text}
                          </Typography>
                        )}
                      </React.Fragment>
                    );
                  })}
                {footer.content !== undefined && footer.content}
              </Grid>
            ))}
          </Grid>
        </div>
      </footer>
    );
  }
}

CatalogFooter.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(CatalogFooter);
