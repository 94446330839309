import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  content: {},
  formControl: {
    margin: theme.spacing(),
    marginTop: theme.spacing(2),
  },
  formLabel: {
    padding: theme.spacing(),
    paddingLeft: 0,
  },
  control: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  button: {
    width: '100%',
  },
});

class Pickup extends React.Component {
  render() {
    return <React.Fragment></React.Fragment>;
  }
}

Pickup.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Pickup);
