import React from 'react';
import PropTypes from 'prop-types';

class SimpleAnchor extends React.Component {
    render() {
        const { children, to } = this.props;

        return (
            <a {...this.props} href={to}>{children}</a>
        );
    }
}

SimpleAnchor.propTypes = {
    to: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default SimpleAnchor;