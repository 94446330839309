import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';

const styles = theme => ({
  container: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'row',
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 100,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formGroup: {
    margin: '0 auto',
  },
});

class SearchByMotoTireSize extends React.Component {
  componentDidMount() {
    this.props.actions.loadTireDimensionsWidths();
  }

  componentWillReceiveProps(props) {
    if (props.selectedWidth !== this.props.selectedWidth) {
      this.props.actions.loadTireProfilesByWidth(props.selectedWidth);
    }

    if (props.selectedProfile !== this.props.selectedProfile) {
      this.props.actions.loadTireRimsByWidthAndProfile(
        props.selectedWidth,
        props.selectedProfile
      );
    }
  }

  handleWidthSelection = event => {
    this.props.actions.resetDimensionsMoto();
    this.props.actions.selectWidth(event.target.value);
  };

  handleProfileSelection = event => {
    this.props.actions.selectProfile(event.target.value);
  };

  handleRimSelection = event => {
    this.props.actions.selectRim(event.target.value);
  };

  render() {
    const {
      classes,
      widths,
      selectedWidth,
      profiles,
      selectedProfile,
      rims,
      selectedRim,
    } = this.props;

    const widthsMenuItems = widths.map((width) => (
      <MenuItem key={width.toString()} value={width}>
        {width}
      </MenuItem>
    ));

    const profilesMenuItems = profiles.map((profile) => (
      <MenuItem key={profile.toString()} value={profile}>
        {profile}
      </MenuItem>
    ));

    const rimsMenuItems = rims.map((rim) => (
      <MenuItem key={rim.toString()} value={rim}>
        {rim}
      </MenuItem>
    ));

    return (
      <form className={classes.container} autoComplete="off">
        <FormGroup row className={classes.formGroup}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="width">רוחב</InputLabel>
            <Select
              value={selectedWidth}
              onChange={this.handleWidthSelection}
              inputProps={{
                name: 'width',
                id: 'width',
              }}
            >
              {widthsMenuItems}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="height">חתך</InputLabel>
            <Select
              value={selectedProfile}
              onChange={this.handleProfileSelection}
              disabled={profiles.length === 0}
              inputProps={{
                name: 'profile',
                id: 'profile',
              }}
            >
              {profilesMenuItems}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="rim">חישוק</InputLabel>
            <Select
              value={selectedRim}
              onChange={this.handleRimSelection}
              disabled={rims.length === 0}
              inputProps={{
                name: 'rim',
                id: 'rim',
              }}
            >
              {rimsMenuItems}
            </Select>
          </FormControl>
        </FormGroup>
      </form>
    );
  }
}

SearchByMotoTireSize.propTypes = {
  classes: PropTypes.object,
  actions: PropTypes.object,
  widths: PropTypes.array,
  profiles: PropTypes.array,
  rims: PropTypes.array,
  selectedWidth: PropTypes.string,
  selectedProfile: PropTypes.string,
  selectedRim: PropTypes.string,
};

export default withStyles(styles)(SearchByMotoTireSize);
