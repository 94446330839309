import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";

import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";

import CartItemPriceRow from "./CartItemPriceRow";
import brandLogoUrls from "./../common/logoUrls";
import commons from "./../common/commons";

const hebrewLables = {
  freeShipping: "משלוח חינם",
  lastPurchase: "רכישה אחרונה: {x}",
  outStock: "לא במלאי",
  preSale: "צפי הגעה: %s",
  inStock: "במלאי מיידי",
  ondemandText: "הזמן היום, קבל ב-%s",
  likeButtonTextToggleOff: "לחץ לסמן שאתה אוהב את המוצר",
  learnMoreButtonText: "מידע נוסף",
  addToCartButtonText: "הזמנה",
  showMoreArrowText: "פתח לעוד מידע",
  removeItemFromCart: "הסר מהעגלה",
};

const styles = (theme) => ({
  card: {
    maxWidth: 400,
    marginTop: 40,
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
  },
  content: {
    padding: 5,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  media: {
    height: 194,
    backgroundPosition: "top",
  },
  brandLogo: {
    position: "absolute",
    maxHeight: 26,
    top: 3,
    right: 3,
  },
  outOfStock: {
    margin: 8,
    background: "#ba2218",
    color: "#fefefe",
  },
  onDemand: {
    margin: 8,
  },
  button: {
    margin: theme.spacing(),
  },
  cardHeader: {
    flip: false,
    direction: "ltr",
  },
  cardMediaContainer: {
    position: "relative",
  },
  preOrder: {
    margin: 8,
    background: "#0d9b4b",
    color: "#fefefe",
  },
  chip: {
    flip: false,
    direction: "ltr",
    position: "absolute",
    top: "5px",
    right: "5px",
  },
});

class CartItem extends React.Component {
  state = { expanded: false };

  removeItemFromCart = () => {
    this.props.onRemoveFromCart(this.props.data.ean);
  };

  render() {
    const { classes, data, cardCount, nextShipment, currentShipment } =
      this.props;

    const {
      displayName,
      brand,
      ean,
      cdn = false,
      displaySize,
      visibility = "none",
    } = data;

    const primaryPhoto = commons.getProductPrimaryImage(cdn, ean);

    const extraStyles = {};
    if (cardCount === 0) {
      extraStyles.marginTop = "10px";
    }

    let stockLabel;
    const displayGreenVisibilityChip =
      visibility === "instock" || visibility === "preorder" ? true : false;
    const displayOnDemandChip = visibility === "ondemand";
    const displayOutOfStock = visibility === "outofstock";

    switch (visibility) {
      case "instock":
        stockLabel = hebrewLables.inStock;
        break;
      case "preorder":
        stockLabel = hebrewLables.preSale.replace("%s", currentShipment);
        break;
    }

    if (displayOnDemandChip) {
      stockLabel = hebrewLables.ondemandText.replace("%s", nextShipment);
    }

    return (
      <Card className={classes.card} style={extraStyles}>
        <CardHeader
          className={classes.cardHeader}
          title={displayName}
          subheader={brand}
        />
        <Divider />
        <div className={classes.cardMediaContainer}>
          <div className={classes.brandLogoContainer}>
            <img
              className={classes.brandLogo}
              src={brandLogoUrls[brand.toLowerCase()]}
            />
          </div>
          <Chip label={displaySize} className={classes.chip} />
          <CardMedia
            className={classes.media}
            image={primaryPhoto.url}
            title={`${brand} ${displayName} ${displaySize}`}
          />
        </div>
        <Divider />
        <CardContent className={classes.content}>
          <CartItemPriceRow
            data={data}
            quantity={this.props.quantity}
            onChangeQuantity={this.props.onChangeQuantity}
          />

          {displayOutOfStock && (
            <div className={classes.chipsContainer}>
              <Chip
                className={classNames(classes.outOfStock)}
                label={hebrewLables.outStock}
              />
            </div>
          )}

          {displayGreenVisibilityChip && (
            <div className={classes.chipsContainer}>
              <Chip
                className={classNames(classes.preOrder)}
                label={stockLabel}
              />
            </div>
          )}

          {displayOnDemandChip && (
            <div className={classes.chipsContainer}>
              <Chip className={classes.onDemand} label={stockLabel} />
            </div>
          )}
        </CardContent>
        <CardActions className={classes.actions}>
          <Button variant="contained" onClick={this.removeItemFromCart}>
            {hebrewLables.removeItemFromCart}
          </Button>
        </CardActions>
      </Card>
    );
  }
}

CartItem.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  nextShipment: PropTypes.string,
  currentShipment: PropTypes.string,
  quantity: PropTypes.number.isRequired,
  cardCount: PropTypes.number.isRequired,
  onRemoveFromCart: PropTypes.func.isRequired,
  onChangeQuantity: PropTypes.func.isRequired,
};

export default withStyles(styles)(CartItem);
