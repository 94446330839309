import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import ShopCard from './../shops/ShopCard';

const hebrew = {
  selectCity: 'בחר עיר',
};

const styles = theme => ({
  formControl: {
    margin: theme.spacing(),
    width: '100%',
  },
  formLabel: {
    padding: theme.spacing(),
    paddingLeft: 0,
  },
  control: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    width: '100%',
  },
  shopsListCont: {
    margin: 8,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  '@media (min-width: 1024px)': {
    control: {
      width: 'initial',
    },
    shopsListCont: {
      width: 770,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    formControl: {
      width: 200,
    },
  },
  changeShopButton: {
    marginTop: theme.spacing(),
    width: '100%',
  },
  button: {
    width: '100%',
  },
});

class ShopList extends React.Component {
  renderShops(shops) {
    const { stats, reportShopLike } = this.props;

    return shops.map((shop, index) => {
      return (
        <ShopCard
          key={index}
          data={shop}
          stats={stats}
          reportLike={reportShopLike}
        />
      );
    });
  }

  changeCity = evt => {
    this.props.onChangeCity(evt.target.value);
  };

  render() {
    const { cities, classes, selectedCity, shops } = this.props;

    return (
      <div>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel
            className={classes.formLabel}
            htmlFor="city"
            component="legend"
          >
            {hebrew.selectCity}
          </FormLabel>
          <Select
            value={selectedCity}
            onChange={this.changeCity}
            inputProps={{
              name: 'city',
              id: 'city',
            }}
          >
            {cities.map(({ displayName, id }) => (
              <MenuItem key={id} value={id}>
                {displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedCity !== '' && (
          <div className={classes.shopsListCont}>{this.renderShops(shops)}</div>
        )}
      </div>
    );
  }
}

ShopList.propTypes = {
  classes: PropTypes.object.isRequired,
  cities: PropTypes.array.isRequired,
  shops: PropTypes.array.isRequired,
  stats: PropTypes.object,
  selectedCity: PropTypes.string.isRequired,
  onChangeCity: PropTypes.func.isRequired,
  reportShopLike: PropTypes.func.isRequired,
};

export default withStyles(styles)(ShopList);
