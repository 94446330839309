import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import App from '../components/app';
import * as actions from '../redux/actions/catalogActions';

const mapStateToProps = (state, ownProps) => {
  return { catalog: state.catalogReducer };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return { actions: bindActionCreators(actions, dispatch) }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
