import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: 33,
    marginLeft: "auto",
    marginRight: "auto",
  },
  headline: {
    fontSize: "14pt",
    fontWeight: 525,
  },
  para: {
    margin: "15px 8px",
  },
  "@media (min-width: 1024px)": {
    root: {
      marginTop: 80,
      width: 1000,
    },
    para: {
      width: "70%",
    },
  },
});

const hebrewLabels = {
  title: "הצהרת נגישות",
  paras: [
    "ספורטישופ שמה דגש ונותנת חשיבות להנגשת אתר האינטרנט שלה, לשוויון, אי אפליה ושרות נגיש לאנשים עם מוגבלות.",
    "הנגשת האתר בוצעה על ידי חברת ספורטישופ",
    {
      list: true,
      items: [
        "אתר האינטרנט עומד בדרישות תקנות שוויון זכויות לאנשים עם מוגבלות (התאמות נגישות לשירות), התשע”ג 2013.",
        "התאמות הנגישות בוצעו עפ”י המלצות התקן הישראלי (ת”י 5568) לנגישות תכנים באינטרנט ברמת AA ומסמך WCAG2.0 הבינלאומי.",
        "האתר מספק מבנה סמנטי עבור טכנולוגיות מסייעות ותמיכה בדפוס השימוש המקובל להפעלה עם מקלדת בעזרת מקשי החיצים, Enter ו- Esc ליציאה מתפריטים וחלונות.",
        "לשם קבלת חווית גלישה מיטבית עם תוכנת הקראת מסך, אנו ממליצים לשימוש בתוכנת NVDA העדכנית ביותר.",
      ],
    },
    {
      title: "כיצד עובדת ההנגשה באתר?",
      paras: [
        "באתר מוצב תפריט הנגשה. לחיצה על התפריט מאפשרת פתיחת כפתורי ההנגשה. לאחר בחירת נושא בתפריט יש להמתין לטעינת הדף.",
        "התוכנה פועלת בדפדפנים הפופולריים: Chrome, Firefox, Safari, Opera",
        "הגלישה במצב נגישות מומלצת בדפדפן Google Chrome",
      ],
    },
    "אפשרויות הנגישות בתפריט:",
    {
      list: true,
      items: [
        "הגדלת פונט האתר ל-4 גדלים שונים",
        "התאמות ניגודיות - שינוי ניגודיות צבעי האתר",
        "התאמת האתר לעיוורי צבעים",
        "החלפת צבע גופן",
        "בחירת צבע רקע לכותרות",
        "שינוי סגנון הגופן בכיוונו וכן הדגשתו (בולד , איטליקס)",
        "הדגשת קישורים באתר",
        "הדגשת כותרות באתר",
        "מעבר לשחור / לבן",
        "הסתרת תמונות  / הצגת תיאור אלטרנטיבי לתמונות",
        "הגדלת האותיות באנגלית לאותיות גדולות וקריאות יותר",
        "הצהרת נגישות",
      ],
    },
    {
      title: "פניה לרכז הנגישות",
      paras: [
        "אם במהלך הגלישה באתר נתקלתם בקושי בנושא נגישות, אנו עומדים לרשותכם במגוון ערוצים לפנייה בנושאי נגישות, נשמח לקבל מכם משוב.",
      ],
    },
    {
      title: "פרטי רכז/ת נגישות בחברה",
      paras: [
        "לפניות בנושא הנגשה באתר ספורטישופ ,ניתן לפנות אל:",
        "שם: איתמר ארגואן",
        "תפקיד : מנהל האתר",
        "טלפון : 054-9200911",
        "אימייל :  info@sportyshop.co.il",
        "כתובת למשלוח דואר :  אליהו איתן 32, ראשון לציון, מיקוד 7570331",
      ],
    },
    "הצהרת נגישות זו עודכנה ביום  2.2.2023",
  ],
};

class GDPRPage extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Paper className={classes.root} elevation={4}>
          <Typography component="h1" variant="h1" className={classes.headline}>
            {hebrewLabels.title}
          </Typography>

          {hebrewLabels.paras.map((pa) => {
            if (typeof pa === "object" && "paras" in pa) {
              return (
                <React.Fragment>
                  <Typography
                    component="h2"
                    variant="h2"
                    className={classes.headline}
                  >
                    {pa.title}
                  </Typography>

                  {pa.paras.map((p) => (
                    <Typography className={classes.para} component="p">
                      {p}
                    </Typography>
                  ))}
                </React.Fragment>
              );
            } else if (typeof pa === "object" && "list" in pa) {
              return (
                <React.Fragment>
                  <ul>
                    {pa.items.map((item) => {
                      return (
                        <li>
                          <Typography>{item}</Typography>
                        </li>
                      );
                    })}
                  </ul>
                </React.Fragment>
              );
            } else {
              return (
                <Typography className={classes.para} component="p">
                  {pa}
                </Typography>
              );
            }
          })}
        </Paper>
      </div>
    );
  }
}

GDPRPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles)(GDPRPage);
