import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import KeyBenefits from "./../common/KeyBenefits";

const cdnPath = "https://s3.eu-west-2.amazonaws.com/sportyshop/catalog";

const styles = (theme) => ({
  root: {
    padding: 16,
    marginTop: 33,
    marginLeft: "auto",
    marginRight: "auto",
  },
  headline: {
    fontSize: "14pt",
    fontWeight: 525,
  },
  factoryIcon: {
    backgroundImage: `url(${cdnPath}/business-model/factory-1.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100px 100px",
  },
  hangarIcon: {
    backgroundImage: `url(${cdnPath}/business-model/hangar.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100px 100px",
  },
  deliveryIcon: {
    backgroundImage: `url(${cdnPath}/business-model/delivery-truck.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100px 100px",
  },
  storeIcon: {
    backgroundImage: `url(${cdnPath}/business-model/store.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100px 100px",
  },
  motoIcon: {
    backgroundImage: `url(${cdnPath}/business-model/motorbike.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100px 100px",
  },
  timelineIconBase: {
    width: 100,
    height: 100,
    marginRight: "auto",
    marginLeft: "auto",
  },
  timeline: {
    position: "relative",
    marginTop: 1,
    marginBottom: 1,
    width: 4,
    backgroundColor: "#a0a7b2",
    marginRight: "auto",
    marginLeft: "auto",
  },
  tmlPre: {
    height: 25,
  },
  timelineItem: {
    position: "relative",
    marginTop: 20,
  },
  textBox: {
    color: "#3c3c3d",
    textAlign: "center",
    fontSize: "12pt",
    margin: theme.spacing(),
    marginTop: 4,
  },
  endTextBox: {
    borderTop: "solid 1px #d4d4d6",
    color: "#3c3c3d",
    textAlign: "center",
    margin: theme.spacing(),
    paddingTop: 25,
    marginTop: 25,
  },
  disabledTextBox: {
    color: "#ababad",
    textAlign: "center",
    fontSize: "12pt",
    margin: theme.spacing(),
    marginTop: 4,
  },
  commonTextSize: {
    fontSize: "12pt",
  },
  disabledIcon: {
    opacity: 0.4,
  },
  "@media (min-width: 1024px)": {
    root: {
      marginTop: 80,
      width: 1000,
    },
  },
});

const hebrewLabels = {
  title: "היתרון בצד שלך עם שיטת המכירה הישירה",
  factoryText: "הצמיגים מיוצרים במפעלי היצרנים השונים ונשלחים אל מחסני הפצה",
  storageText: "הצמיגים מופצים אל יבואני הצמיגים השונים ברחבי העולם",
  shopText: "הצמיגים נמכרים למוסך על ידי היבואן הרשמי",
  shopTextUs:
    "על השלב הזה אנחנו מדלגים ומוכרים ישירות אליך הלקוח הפרטי מה שאומר חסכון בעלויות עבורך!",
  motoText: "הצמיגים מותקנים על האופנוע שלך",
  finishText: "הדילוג על שלב המוסכים מפחית את עלות החלפת הצמיגים",
  finishText2: "בצורה משמעותית ובכך חוסך עבורכם מאות שקלים בכל החלפה!",
  ourBenefitsText:
    "ואם זה לא מספיק יש עוד שלל יתרונות נוספים לקניית הצמיגים הבאים שלכם דרכנו",
};

class BusinessModelPage extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Paper className={classes.root} elevation={4}>
          <Typography component="h1" variant="h1" className={classes.headline}>
            {hebrewLabels.title}
          </Typography>

          <div className={classes.timelineItem}>
            <div
              className={`${classes.factoryIcon} ${classes.timelineIconBase}`}
            />
            <div className={`${classes.timeline} ${classes.tmlPre}`} />
            <Typography className={classes.textBox}>
              {hebrewLabels.factoryText}
            </Typography>

            <div className={`${classes.timeline} ${classes.tmlPre}`} />
            <div
              className={`${classes.hangarIcon} ${classes.timelineIconBase}`}
            />
            <div className={`${classes.timeline} ${classes.tmlPre}`} />
            <Typography className={classes.textBox}>
              {hebrewLabels.storageText}
            </Typography>
            <div className={`${classes.timeline} ${classes.tmlPre}`} />
            <div
              className={`${classes.deliveryIcon} ${classes.timelineIconBase}`}
            />
            <div className={`${classes.timeline} ${classes.tmlPre}`} />

            <Typography className={classes.disabledTextBox}>
              {hebrewLabels.shopText}
            </Typography>
            <Typography className={classes.textBox}>
              {hebrewLabels.shopTextUs}
            </Typography>
            <div className={`${classes.timeline} ${classes.tmlPre}`} />
            <div
              className={`${classes.storeIcon} ${classes.timelineIconBase} ${classes.disabledIcon}`}
            />
            <div className={`${classes.timeline} ${classes.tmlPre}`} />
            <Typography className={classes.textBox}>
              {hebrewLabels.motoText}
            </Typography>
            <div className={`${classes.timeline} ${classes.tmlPre}`} />
            <div
              className={`${classes.motoIcon} ${classes.timelineIconBase}`}
            />
          </div>

          <div className={classes.endTextBox}>
            <Typography component="p" className={classes.commonTextSize}>
              {hebrewLabels.finishText} {hebrewLabels.finishText2}
            </Typography>
            <Typography component="p" className={classes.commonTextSize}>
              {hebrewLabels.ourBenefitsText}
            </Typography>
          </div>

          <KeyBenefits />
        </Paper>
      </div>
    );
  }
}

BusinessModelPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles)(BusinessModelPage);
