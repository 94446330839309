import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';

const hebrewLabels = {
    pickSIPmethodPreface: 'בחר אחת מן האפשרויות הבאות:',
    shippingPreface: 'כיצד תרצה לקבל את הצמיגים?',
    shipping: 'משלוח עד הבית עם שליח',
    install: 'הרכבה במוסך',
    pickup: 'איסוף עצמי',
    disclaimer: 'הרכבה או משלוח בתוספת תשלום',
    continue: 'המשך',
    titles: {
        deliveryAddress: 'פרטי המשלוח',
    }
};

const styles = theme => ({
    content: {
    },
    rightIcon: {
        marginLeft: theme.spacing(),
    },
    title: {
        fontSize: 20,
    },
    formLabel: {
        padding: theme.spacing(),
        paddingLeft: 0
    },
    preface: {
        width: '100%',
        margin: theme.spacing(),
    },
    button: {
        width: '100%',
        margin: theme.spacing(),
    }
});

class SIPFork extends React.Component {
    onPickSip = (e) => {
        this.props.onChangeSip(e.target.value);
    };

    render() {
        const { classes, sip } = this.props;

        return (
            <div className={classes.content}>
                <Typography className={classes.title} component="h3">
                    {hebrewLabels.titles.deliveryAddress}
                </Typography>

                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">{hebrewLabels.shippingPreface}</FormLabel>
                    <RadioGroup
                        aria-label={hebrewLabels.pickSIPmethodPreface}
                        name="sip"
                        value={sip}
                        className={classes.group}
                        onChange={this.onPickSip}
                    >
                        <FormControlLabel value="install" control={<Radio />} label={hebrewLabels.install} />
                        <FormControlLabel value="pickup" control={<Radio />} label={hebrewLabels.pickup} />
                        <FormControlLabel value="shipping" control={<Radio />} label={hebrewLabels.shipping} />
                    </RadioGroup>

                </FormControl>
            </div>
        );
    }
}

SIPFork.propTypes = {
    classes: PropTypes.object.isRequired,
    onChangeSip: PropTypes.func.isRequired,
    onContinue: PropTypes.func,
};

export default withStyles(styles)(SIPFork);
