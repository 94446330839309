import * as actions from './../catalogActions';

export default function getData(matchedRoute) {
  return dispatch => {
    return new Promise(resolve => {
      const fakeDispatch = objToDispatch => {
        dispatch(objToDispatch);
        resolve();
      };

      return actions.getProductByEAN(matchedRoute.params.ean)(fakeDispatch);
    });
  };
}
