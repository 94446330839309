import React from 'react';
import PropTypes from 'prop-types';

class FBPixelTrigger extends React.Component {
  componentDidMount() {
    const { ean, price } = this.props.data;

    /*eslint no-undef: 0*/

    if (typeof fbq === 'function') {
      fbq('track', 'ViewContent', {
        content_ids: [ean],
        content_type: 'product',
        value: price,
        currency: 'ILS',
      });
    }
  }

  render() {
    return null;
  }
}

FBPixelTrigger.propTypes = {
  data: PropTypes.object,
};

export default FBPixelTrigger;
