import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import CheckIcon from '@material-ui/icons/Check';
import DoubleCheckIcon from '@material-ui/icons/DoneAll';
import ShippingIcon from '@material-ui/icons/LocalShipping';

import brandLogoUrls from './../common/logoUrls';
import commons from './../common/commons';
import CartItemPriceRow from './CartItemPriceRow';

const hebrewLables = {
  freeShipping: 'משלוח חינם',
  lastPurchase: 'רכישה אחרונה: {x}',
  setOfTires: 'סט צמיגים',
  outStock: 'לא במלאי',
  preSale: 'צפי הגעה: %s',
  inStock: 'במלאי מיידי',
  ondemandText: 'הזמן היום, קבל ב-%s',  
  likeButtonTextToggleOff: 'לחץ לסמן שאתה אוהב את המוצר',  
  learnMoreButtonText: 'מידע נוסף',
  addToCartButtonText: 'הזמנה',
  showMoreArrowText: 'פתח לעוד מידע',
  removeItemFromCart: 'הסר מהעגלה',
};

const styles = theme => ({
  card: {
    maxWidth: 400,
    marginTop: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
  },
  content: {
    padding: 5,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  media: {
    height: 194,
    backgroundPosition: 'top',
  },
  brandLogo: {
    position: 'absolute',    
    maxHeight: 26,
    top: 3,
    right: 3,
  },
  button: {
    margin: theme.spacing(),
  },
  cardHeader: {
    flip: false,
    direction: 'ltr',
  },
  cardMediaContainer: {
    position: 'relative',
  },
  chip: {
    flip: false,
    direction: 'ltr',
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
  '@media (min-width: 1024px)': {
    card: {
      display: 'none',
    },
    desktopBox: {
      margin: '8px 0',
      padding: 8,
      background: '#fefefe',
      display: 'block',
    },
    brandLogo: {
      position: 'initial',
      top: 'initial',
      right: 'initial',
      height: 18,
    },
    brandContainer: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'center',
    },
    desktopDataContainer: {
      position: 'relative',
    },
    desktopControlContainer: {
      display: 'inline-block',
      position: 'absolute',
      width: 'auto',
      bottom: 0,
      right: 0,
    },
    desktopDataInnerContainer: {
      // direction: 'ltr',
      display: 'block',
      paddingLeft: 16,
    },
    primaryPhotoContainer: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'center',
    },
    title: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 24,
      margin: 8,
    },
    tireSize: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 17,
      direction: 'ltr',
      margin: '4px 8px',
    },
    preOrder: {
      background: '#0d9b4b',
      color: '#fefefe',
    },
    outOfStock: {
      background: '#ba2218',
      color: '#fefefe',
    },
    subheading: {
      margin: '4px 8px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 15,
      fontWeight: 400,
      color: '#9a9a9a',
    },
    featuresPool: {
      direction: 'ltr',
      margin: '4px 8px',
    },
    featureItem: {
      margin: 4,
    },
    desktopPrimaryPhoto: {
      height: 194,
    },
  },
});

class CartItemDesktop extends React.Component {
  removeItemFromCart = () => {
    this.props.onRemoveFromCart(this.props.data.ean);
  };

  render() {
    const { classes, data, cardCount, nextShipment, currentShipment } = this.props;

    const {
      displayName,
      brand,
      ean,
      set,
      visibility = 'none',
      free_shipping,
      tireType,
      cdn = false,
      displaySize,
    } = data;

    const { url: primaryPhoto } = commons.getProductPrimaryImage(cdn, ean);

    const extraStyles = {};
    if (cardCount === 0) {
      extraStyles.marginTop = '10px';
    }

    let stockLabel;
    const displayGreenVisibilityChip = (visibility === 'instock' || visibility === 'preorder') ? true : false;
    const displayOnDemandChip = visibility === 'ondemand';
    const displayOutOfStock = visibility === 'outofstock';

    switch (visibility) {
      case 'instock':
        stockLabel = hebrewLables.inStock;
        break;
      case 'preorder':
        stockLabel = hebrewLables.preSale.replace('%s', currentShipment);
        break;
    }

    if (displayOnDemandChip) {
      stockLabel = hebrewLables.ondemandText.replace('%s', nextShipment);
    }

    return (
      <React.Fragment>
        <Paper className={classes.desktopBox}>
          <Grid container spacing={0} className={classes.desktopDataContainer}>
            <Grid item xs={3}>
              <Grid container spacing={0}>
                <Grid item className={classes.primaryPhotoContainer}>
                  <img
                    className={classes.desktopPrimaryPhoto}
                    alt={`${brand} ${displayName} ${displaySize}`}
                    src={primaryPhoto}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item className={classes.brandContainer}>
                  <img
                    alt={brand}
                    className={classes.brandLogo}
                    src={brandLogoUrls[brand.toLowerCase()]}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <CartItemPriceRow
                data={data}
                quantity={this.props.quantity}
                onChangeQuantity={this.props.onChangeQuantity}
              />
              <Grid
                container
                spacing={0}
                className={classes.desktopDataInnerContainer}
              >
                <h3 className={classes.title}>{displayName}</h3>

                <h4 className={classes.subheading}>{brand}</h4>

                <Chip className={classes.tireSize} label={displaySize} />

                <Grid container spacing={0} className={classes.featuresPool}>
                  {displayOutOfStock && <Chip
                    className={classNames(
                      classes.featureItem,
                      classes.outOfStock
                    )}
                    label={hebrewLables.outStock}
                  />}

                  {displayGreenVisibilityChip && <Chip
                    className={classNames(
                      classes.featureItem,
                      classes.preOrder
                    )}
                    label={stockLabel}
                  />}

                  {displayOnDemandChip && <Chip
                    className={classes.featureItem}
                    label={stockLabel}
                  />}

                  {set && (
                    <Chip
                      className={classNames(classes.featureItem)}
                      avatar={
                        <Avatar>
                          <DoubleCheckIcon />
                        </Avatar>
                      }
                      label={hebrewLables.setOfTires}
                    />
                  )}

                  {free_shipping && (
                    <Chip
                      className={classNames(
                        classes.featureItem,
                        classes.freeShippingChip
                      )}
                      avatar={
                        <Avatar>
                          <ShippingIcon />
                        </Avatar>
                      }
                      label={hebrewLables.freeShipping}
                    />
                  )}

                  <Chip className={classes.featureItem} label={tireType} />                  
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                className={classes.desktopControlContainer}
              >
                <Button variant="contained" onClick={this.removeItemFromCart}>
                  {hebrewLables.removeItemFromCart}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </React.Fragment>
    );
  }
}

CartItemDesktop.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  nextShipment: PropTypes.string,
  currentShipment: PropTypes.string,
  quantity: PropTypes.number.isRequired,
  cardCount: PropTypes.number.isRequired,
  onRemoveFromCart: PropTypes.func.isRequired,
  onChangeQuantity: PropTypes.func.isRequired,
};

export default withStyles(styles)(CartItemDesktop);
