import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';

import LoyaltyIcon from '@material-ui/icons/Loyalty';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import FaceIcon from '@material-ui/icons/Face';
import EventIcon from '@material-ui/icons/Event';

import TopSellerItem from './TopSellerItem';

const styles = () => ({
    loyalty: {
        background: '#d8331a',
        color: '#f7e7bb'
    },
    shipping: {
        background: '#f4b342',
        color: '#313330'
    },
    service: {
        background: '#7bc625'
    },
    root: {
        width: '100%',
        display: 'flex',
        flexFlow: 'row wrap',
        background: '#fff',
        alignContent: 'flex-start',
        justifyContent: 'center',
        overflow: 'auto',
        flexGrow: 0,
        marginBottom: 8
    },
    install: {
        background: '#4286f4'
    },
    itemsText: {
        color: '#313330'
    },
    pillarMobileItem: {
        position: 'relative',
        width: 180,
        height: 120,
        margin: 16
    },
    topSellersTitle: {
        fontSize: 20,
        fontWeight: 600,
        margin: 8
    },
    pillarsListDesktop: { display: 'none' },
    pillarsListMobile: {
        display: 'initial',
        display: 'flex',
        flexWrap: 'wrap'
    },
    '@media (min-width: 1024px)': {
        pillarsListMobile: {
            display: 'none',
        },
        pillarsListDesktop: {
            display: 'initial',
            display: 'flex',
            flexWrap: 'wrap'
        },
        topSellersTitle: {
            fontSize: 20,
            width: '100%',
            fontWeight: 600,
            margin: 8
        },
        pillarDesktopItem: {
            position: 'relative',
            width: 180,
            height: 120,
            margin: 16
        },
        pillarDesktopAvatar: {
            position: 'absolute',
            top: 10,
            left: 53,
            width: 75,
            height: 75,
            '& svg': {
                fontSize: 52,
            },
        },
        pillarDesktopText: {
            width: 180,
            position: 'absolute',
            textAlign: 'center',
            fontSize: 16,
            fontWeight: 600,
            bottom: 5,
        }
    }
});

const hebrew = {
    priceTitle: 'המחיר הזול בארץ!',
    installTitle: 'פרישת מוסכים ארצית + קביעת תור במוסך בקליק',
    installText: 'בחר ממגוון מוסכים ברחבי הארץ ישירות באתר , קבע תור בקליק, והצמיגים? יחכו לכם במוסך הנבחר ביום ההתקנה!',
    priceText: 'יבוא מקביל של המותגים המובילים בעולם מאפשר לנו לתת מחיר נמוך. מצאתם יותר זול? נשווה ונוריד ממנו!',
    shippingText: 'שלל מוצרים מובילים במשלוח חינם עד אליכם הביתה!',
    freeShippingTitle: 'משלוחים חינם!',
    serviceTitle: 'שירות לקוחות מעולה!',
    ourTopSellers: 'המוצרים הנמכרים ביותר שלנו',
    serviceText: 'מענה טלפוני מקצועי, אדיב ומהיר גם מעבר לשעות העבודה וגם אחרי שהתקנתם את הצמיגים - אנחנו תמיד כאן בשבילכם!',
    desktop: {
        install: 'קביעת תור בקליק'
    }
};

class TopSellers extends React.Component {
    render() {
        const { classes, data } = this.props;

        return (
            <div className={classes.root}>
                <Typography className={classes.topSellersTitle} component="h3">{hebrew.ourTopSellers}</Typography>

                <div className={classes.pillarsListMobile}>
                    {data.map((product, index) => {
                        return (<TopSellerItem className={classes.pillarMobileItem} key={index} data={product}></TopSellerItem>);
                    })}
                </div>

                <div className={classes.pillarsListDesktop}>
                    {data.map((product, index) => {
                        return (<TopSellerItem className={classes.pillarDesktopItem} key={index} data={product}></TopSellerItem>);
                    })}
                </div>
            </div>
        );
    }
}

TopSellers.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
};

export default withStyles(styles)(TopSellers);