import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { vsprintf } from 'sprintf-js';

const hebrewLables = {
    totalToPayText: 'סה״כ לתשלום עבור הצמיגים:',
    totalToPayForInstall: 'סה״כ לתשלום במוסך:',
};

const styles = theme => ({
    total: {
        fontFamily: 'Lato',
        whiteSpace: 'nowrap',
        paddingTop: 2,
        direction: 'ltr',
        fontWeight: 700,
        fontSize: '14pt',
        clear: 'both'
    },
    totalToPayCell: {
        whiteSpace: 'nowrap',
        color: '#8a8a8a',
        fontSize: '14pt',
    },
    desktopContent: {
        display: 'none'
    },
    '@media (min-width: 1024px)': {
        content: {
            display: 'none'
        },
        totalToPayCell: {
            display: 'inline'
        },
        desktopContent: {
            display: 'block'
        }
    }
});

class CheckoutCartTotal extends React.Component {
    render() {
        const { classes, cart, cartProducts, checkout } = this.props;

        let installTotal = 0;
        let cartTotal = cart.items.reduce((total, item) => {
            const productIndex = cartProducts.findIndex((product => product.ean === item.ean));
            if (productIndex !== -1) {
                const product = cartProducts[productIndex];
                return total + product.price * item.quantity;
            }
            return 0;
        }, 0);

        if (checkout.sip === 'install' && checkout.selectedShop) {
            const shop = checkout.shops.find(o => o.id === checkout.selectedShop);
            const price = shop.price;
            installTotal = cart.items.reduce((total, item) => {
                return total + ((item.isSet) ? price * 2 : price);
            }, 0);
        }

        return (
            <React.Fragment>
                <Paper className={classes.content}>
                    <Typography variant="h1" className={classes.totalToPayCell}>
                        {hebrewLables.totalToPayText}
                    </Typography>
                    <span className={classes.total}>{vsprintf('%.2f', [cartTotal])} ₪</span>
                </Paper>
                <div className={classes.desktopContent}>
                    <Table width="200">
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h2" className={classes.totalToPayCell}>
                                        {hebrewLables.totalToPayText}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <span className={classes.total}>{vsprintf('%.2f', [cartTotal])} ₪</span>
                                </TableCell>
                            </TableRow>
                            {(checkout.sip === 'install' && checkout.selectedShop) ? (<TableRow>
                                <TableCell>
                                    <Typography variant="h1" className={classes.totalToPayCell}>
                                        {hebrewLables.totalToPayForInstall}
                                    </Typography>
                                </TableCell><TableCell><span className={classes.total}>{vsprintf('%.2f', [installTotal])} ₪</span></TableCell></TableRow>) : null}
                        </TableBody>
                    </Table>
                </div>
            </React.Fragment>
        );
    }
}

CheckoutCartTotal.propTypes = {
    classes: PropTypes.object.isRequired,
    cartProducts: PropTypes.array.isRequired,
    checkout: PropTypes.object.isRequired,
    cart: PropTypes.object.isRequired
};

export default withStyles(styles)(CheckoutCartTotal);
