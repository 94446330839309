import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import StarRateIcon from '@material-ui/icons/Star';
import { vsprintf } from 'sprintf-js';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  priceTitle: {
    fontSize: 15,
    paddingLeft: 5,
  },
  paper: {
    height: 50,
  },
  paperPrice: {
    height: 50,
    width: 100,
    position: 'relative',
  },
  control: {
    padding: theme.spacing(2),
  },
  sizePaper: {
    width: 150,
  },
  strikePrice: {
    textDecoration: 'line-through',
    textAlign: 'center',
    fontFamily: 'Lato',
    direction: 'ltr',
    fontSize: '8pt',
    position: 'absolute',
    color: '#8e8e8e',
    top: 5,
    right: 5,
  },
  '@media (min-width: 1024px)': {
    paper: {
      width: 100,
    },
  },
});

const hebrewLables = {
  additionalTitle: 'מידע נוסף',
  additionalDefault: 'מצויין בעיר',
  editorScore: 'ציון',
  price: 'מחיר',
};

class ResultItemPriceRow extends React.Component {
  state = {
    spacing: '1',
  };

  render() {
    const { classes, data, hideExtraField = false } = this.props;
    const { spacing } = this.state;

    const {
      price,
      fullPrice,
      score,
      tireType = hebrewLables.additionalDefault,
    } = data;

    return (
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={Number(spacing)}>
            {!hideExtraField && (
              <Grid key="editor_score" item className={classes.extraInfoGrid}>
                <Paper className={classes.paper}>
                  <Typography
                    className={classes.priceTitle}
                    variant="h3"
                    component="h3"
                  >
                    {hebrewLables.additionalTitle}
                  </Typography>
                  <div
                    className="result-item-pricing"
                    style={{ fontSize: '12pt' }}
                  >
                    {tireType}
                  </div>
                </Paper>
              </Grid>
            )}
            <Grid key="price" item>
              <Paper className={classes.paperPrice}>
                <div className={classes.strikePrice}>
                  {vsprintf('%.2f', [fullPrice])} ₪
                </div>
                <Typography
                  className={classes.priceTitle}
                  variant="h3"
                  component="h3"
                >
                  מחיר
                </Typography>
                <div className="result-item-pricing">
                  {vsprintf('%.2f', [price])} ₪
                </div>
              </Paper>
            </Grid>
            <Grid key="customer_score" item>
              <Paper className={classes.paper}>
                <Typography
                  className={classes.priceTitle}
                  variant="h3"
                  component="h3"
                >
                  {hebrewLables.editorScore}
                </Typography>
                <div className="result-item-pricing">
                  <span>{score}</span> <StarRateIcon />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ResultItemPriceRow.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  hideExtraField: PropTypes.bool,
};

export default withStyles(styles)(ResultItemPriceRow);
