import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

import MotoList from "./../list/MotoList";
import FilterBar from "./../list/FilterBar";

const styles = (theme) => ({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: 150,
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    margin: "80px auto",
    width: "90%",
    display: "flex",
    justifyContent: "center",
  },
  motoList: {
    marginTop: 55,
  },
  progress: {
    margin: theme.spacing(2),
  },
  pageRoot: {
    display: "flex",
    justifyContent: "center",
  },
  "@media (min-width: 1024px)": {
    filterBoxMobile: { display: "none" },
    paper: {
      margin: "150px auto",
      width: 1000,
    },
    pageRoot: {
      width: 1000,
      margin: "130px auto",
    },
  },
});

class CategoryResultsPage extends React.Component {
  state = {
    redirectToCheckout: false,
  };

  componentDidMount() {
    const { category } = this.props.match.params;
    this.props.actions.setListPending(true);
    this.props.actions.searchMotoTiresByCategory(category);
  }

  expressAddToCart = (ean, isSet) => {
    // Metrics
    this.props.actions.collectMetric({
      kind: "PRODUCT_ADD_TO_CART",
      source: "list_page_by_category",
      ean,
    });

    this.props.actions.addProductToCart(ean, isSet);
    this.setState({
      redirectToCheckout: true,
    });
  };

  submitBestOffer = (offer) => {
    this.props.actions.submitBestOffer(offer);
  };

  updateFilters = (filters) => {
    this.props.actions.updateFilters(filters);
  };

  reportLike = (productId) => {
    this.props.actions.reportLikeEventForProduct(productId);
  };

  render() {
    const { classes, catalog } = this.props;
    const { redirectToCheckout } = this.state;
    const { listPending, filters, category, bestOfferEANs } = catalog;

    const metaTitle = category.seo.title;
    const metaDesc =
      category.seo.description ||
      "מגוון רחב של צמיגים " +
        " תוכלו למצוא אצלנו במגוון מותגים מובילים - עד 70% הנחה - פירלי, מישלין, מצלר, קונטיננטל, ברידגסטון ועוד";

    return (
      <React.Fragment>
        <Helmet>
          <meta property="og:title" content={metaTitle} />
          {<meta property="og:description" content={metaDesc} />}
          {<meta property="description" content={metaDesc} />}
          <title>{metaTitle}</title>
        </Helmet>

        {listPending && (
          <Paper className={classes.paper}>
            <CircularProgress className={classes.progress} size={30} />
          </Paper>
        )}

        {!listPending && (
          <div className={classes.motoList}>
            <Grid container spacing={0} className={classes.pageRoot}>
              <Grid
                item
                xs={12}
                md={3}
                lg={3}
                className={classes.desktopSidebar}
              >
                <FilterBar
                  data={filters}
                  tires={catalog.motoTires}
                  updateFilters={this.updateFilters}
                />
              </Grid>

              <Grid item xs={12} md={9} lg={9}>
                <MotoList
                  nextShipment={catalog.nextShipment}
                  className={classes.motoListContainer}
                  tires={catalog.motoTires}
                  bestOfferEANs={bestOfferEANs}
                  submitBestOffer={this.submitBestOffer}
                  stats={catalog.stats}
                  filters={catalog.filters}
                  addToCart={this.expressAddToCart}
                  reportLike={this.reportLike}
                />
              </Grid>
            </Grid>
          </div>
        )}

        {redirectToCheckout && <Redirect to="/cart" />}
      </React.Fragment>
    );
  }
}

CategoryResultsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
  match: PropTypes.object,
};

export default withStyles(styles)(CategoryResultsPage);
