import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';

import LoyaltyIcon from '@material-ui/icons/Loyalty';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import FaceIcon from '@material-ui/icons/Face';
import EventIcon from '@material-ui/icons/Event';

const styles = () => ({
    loyalty: {
        background: '#d8331a',
        color: '#f7e7bb'
    },
    shipping: {
        background: '#f4b342',
        color: '#313330'
    },
    service: {
        background: '#7bc625'
    },
    root: {
        width: '100%',
        display: 'flex',
        flexFlow: 'row wrap',
        background: '#fff',
        alignContent: 'flex-start',
        justifyContent: 'center',
        overflow: 'auto',
        flexGrow: 0,
        marginBottom: 8
    },
    install: {
        background: '#4286f4'
    },
    itemsText: {
        color: '#313330'
    },
    pillarsListDesktop: { display: 'none' },
    pillarsListMobile: {

    },
    '@media (min-width: 1024px)': {
        pillarsListMobile: {
            display: 'none',
        },
        pillarsListDesktop: {
            margin: 16,
            display: 'initial',
            display: 'flex',
            flexWrap: 'wrap'
        },
        pillarDesktopItem: {
            position: 'relative',
            width: 180,
            height: 120,
            margin: 16
        },
        pillarDesktopAvatar: {
            position: 'absolute',
            top: 10,
            left: 53,
            width: 75,
            height: 75,
            '& svg': {
                fontSize: 52,
            },
        },
        pillarDesktopText: {
            width: 180,
            position: 'absolute',
            textAlign: 'center',
            fontSize: 16,
            fontWeight: 600,
            bottom: 5,
        }
    }
});

const hebrew = {
    priceTitle: 'המחיר הזול בארץ!',
    installTitle: 'פרישת מוסכים ארצית + קביעת תור במוסך בקליק',
    installText: 'בחר ממגוון מוסכים ברחבי הארץ ישירות באתר , קבע תור בקליק, והצמיגים? יחכו לכם במוסך הנבחר ביום ההתקנה!',
    priceText: 'יבוא מקביל של המותגים המובילים בעולם מאפשר לנו לתת מחיר נמוך.',
    shippingText: 'משלוח חינם בהזמנת זוג צמיגים ומעלה',
    freeShippingTitle: 'משלוח חינם',
    serviceTitle: 'שירות לקוחות מעולה!',
    serviceText: 'מענה טלפוני מקצועי, אדיב ומהיר גם מעבר לשעות העבודה וגם אחרי שהתקנתם את הצמיגים - אנחנו תמיד כאן בשבילכם!',
    desktop: {
        install: 'קביעת תור בקליק'
    }
};

class ThreePilars extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <List className={classes.pillarsListMobile}>
                    <ListItem className={classes.listItem}>
                        <Avatar className={classes.loyalty}>
                            <LoyaltyIcon />
                        </Avatar>
                        <ListItemText
                            primary={hebrew.priceTitle}
                            secondary={hebrew.priceText} />
                    </ListItem>
                    <li>
                        <Divider variant="inset" />
                    </li>
                    <ListItem className={classes.listItem}>
                        <Avatar className={classes.install}>
                            <EventIcon />
                        </Avatar>
                        <ListItemText
                            primary={hebrew.installTitle}
                            secondary={hebrew.installText} />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem className={classes.listItem}>
                        <Avatar className={classes.shipping}>
                            <LocalShippingIcon />
                        </Avatar>
                        <ListItemText
                            primary={hebrew.freeShippingTitle}
                            secondary={hebrew.shippingText} />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem className={classes.listItem}>
                        <Avatar className={classes.service}>
                            <FaceIcon />
                        </Avatar>
                        <ListItemText
                            primary={hebrew.serviceTitle}
                            secondary={hebrew.serviceText} />
                    </ListItem>
                </List>
 
                <div className={classes.pillarsListDesktop}>
                    <Paper className={classes.pillarDesktopItem}>
                        <Avatar className={`${classes.pillarDesktopAvatar} ${classes.loyalty}`} >
                            <LoyaltyIcon className={classes.pillarDesktopIcon} />
                        </Avatar>
                        <Typography className={classes.pillarDesktopText}>{hebrew.priceTitle}</Typography>
                    </Paper>
                    <Paper className={classes.pillarDesktopItem}>
                        <Avatar className={`${classes.pillarDesktopAvatar} ${classes.install}`} >
                            <EventIcon classes={classes.pillarDesktopIcon} />
                        </Avatar>
                        <Typography className={classes.pillarDesktopText}>{hebrew.desktop.install}</Typography>
                    </Paper>
                    <Paper className={classes.pillarDesktopItem}>
                        <Avatar className={`${classes.pillarDesktopAvatar} ${classes.shipping}`} >
                            <LocalShippingIcon classes={classes.pillarDesktopIcon} />
                        </Avatar>
                        <Typography className={classes.pillarDesktopText}>{hebrew.freeShippingTitle}</Typography>
                    </Paper>
                    <Paper className={classes.pillarDesktopItem}>
                        <Avatar className={`${classes.pillarDesktopAvatar} ${classes.service}`} >
                            <FaceIcon classes={classes.pillarDesktopIcon} />
                        </Avatar>
                        <Typography className={classes.pillarDesktopText}>{hebrew.serviceTitle}</Typography>
                    </Paper>
                </div>
            </div>
        );
    }
}

ThreePilars.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ThreePilars);