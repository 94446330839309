import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  root: {
    padding: 16,
    marginTop: 33,
    marginLeft: "auto",
    marginRight: "auto",
  },
  headline: {
    fontSize: "18pt",
    fontWeight: "bold",
  },
  subheadline: {
    fontWeight: "bold",
    fontSize: "14pt",
  },
  para: {
    margin: "15px 8px",
  },
  "@media (min-width: 1024px)": {
    root: {
      marginTop: 80,
      width: 1000,
    },
  },
});

const hebrewLabels = {
  title: "מדיניות החזרות והחזר כספי",
  text: "במידה ואינכם מרוצים מהצמיגים שרכשתם ואתם מעוניינים להחזירם זה כמובן אפשרי!",
  text1: "ניתן להחזיר צמיגים שנרכשו באתר עד 14 ימים מיום יציאת הצמיגים אליכם",
  text2:
    "החזר כספי ינתן באותה צורה שבה שילמתם, לדוגמה: אם שילמתם בכרטיס אשראי, תזוכו לכרטיס האשראי בסכום העסקה",
  text3:
    "במידה ותצטרכו עזרה בהבאת המוצר חזרה אלינו נוכל לעזור לכם תמורת שליחת שליח לאיסוף הצמיג מכם בשווי השווה למשלוח רגיל 60 שקלים",
  text4:
    "החזרות ניתן לבצע לכתובת המחסן שלנו בלבד בראשון לציון, לתיאום יש ליצור קשר טלפוני איתנו",
  text5:
    "גם אם אתם מחזירים מוצרים מכל סיבה שהיא, מקווים שחווית השירות שלכם עדיין הייתה טובה ונעימה ובמידה ולא נשמח לשמוע ממכם ולקבל ביקורת - אנחנו כאן בשבילכם ומעוניינים להשתפר ולדעת אם יש משהו שניתן לשפר ברמת השירות שלנו",
};

class ReturnsAndRefundPolicyPage extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Paper className={classes.root} elevation={4}>
          <Typography component="h1" variant="h1" className={classes.headline}>
            {hebrewLabels.title}
          </Typography>
          <Typography className={classes.para} component="p">
            {hebrewLabels.text}
          </Typography>
          <br />
          <br />

          <Typography component="h2" className={classes.subheadline}>
            תקופת הביטול / מסגרת זמן
          </Typography>
          <Typography className={classes.para} component="p">
            {hebrewLabels.text1}
          </Typography>
          <Typography className={classes.para} component="p">
            ניתן לבטל את ההזמנה עוד לפני יציאת הסחורה אליכם במידה ואינם יצאו
            עדיין למשלוח
          </Typography>

          <br />
          <Typography component="h2" className={classes.subheadline}>
            אמצעים להחזר כספי
          </Typography>
          <Typography className={classes.para} component="p">
            {hebrewLabels.text2}
          </Typography>
          <Typography className={classes.para} component="p">
            {hebrewLabels.text3}
          </Typography>

          <br />

          <Typography component="h2" className={classes.subheadline}>
            מוצרים פגומים, שניזוקו במשלוח או מוצר לא נכון
          </Typography>
          <Typography className={classes.para} component="p">
            במידה וקיבלתם מוצר פגום, או שניזוק במהלך המשלוח או לחלופין במידה
            וטעינו ושלחנו אליכם את המוצר שלא אותו הזמנתם אנו נבצע משלוח / נדאג
            לאסוף ממכם את המוצר על חשבוננו ולהחליפו.
          </Typography>
          <br />

          <Typography component="h2" className={classes.subheadline}>
            איך ניתן להתחיל תהליך החזרה או ביטול?
          </Typography>
          <Typography className={classes.para} component="p">
            על מנת להתחיל בתהליך החזרה יש ליצור איתנו בקשר באחד מהדרכים הבאות:{" "}
            <br />
            - טלפונית במספר 054-9200911
            <br />- באימייל כפי שמופיע בתחתית האתר
          </Typography>

          <br />

          <Typography component="h2" className={classes.subheadline}>
            עלויות שילוח במקרה של ביטול
          </Typography>

          <Typography className={classes.para} component="p">
            במידה ואינכם יכולים להביא להביא את המוצר אלינו בעצמכם, נוכל להוציא
            אליכם שליח בעלות של 60 ש״ח כולל מע״מ
          </Typography>
          <Typography className={classes.para} component="p">
            {hebrewLabels.text4}
          </Typography>

          <Typography component="h2" className={classes.subheadline}>
            חיוב על עלות משלוח חוזר במקרה של דחיית המשלוח
          </Typography>

          <Typography className={classes.para} component="p">
            במידה ותסרבו לקבל את החבילה שכבר הגיע עם שליח לדלת שלכם, תחוייבו
            בדמי המשלוח בסך 60 ש״ח שכן אנו שילמנו לחברת המשלוחים על המשלוח.
            ביטול העסקה אינו כולל החזר עבור דמי המשלוח ששולמו ולא ניתן לקבלו
            בחזרה - גם במידה ובעסקה עצמה המשלוח כביכול בחינם. בחשבונית המס שאותה
            אתם מקבלים יצויין דמי משלוח כמוצר נפרד הכלול במחיר המשוקלל של העסקה.
          </Typography>

          <br />

          <Typography className={classes.para} component="p">
            ניתן ומומלץ לעיין בתנאי השירות והשימוש שלנו כדי לקבל מידע נוסף על
            השימוש באתר <br />
            <Typography component={Link} to="/terms-of-use" variant="body2">
              תנאי השימוש באתר
            </Typography>
          </Typography>
          <br />

          <Typography className={classes.para} component="p">
            {hebrewLabels.text5}
          </Typography>
        </Paper>
      </div>
    );
  }
}

ReturnsAndRefundPolicyPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReturnsAndRefundPolicyPage);
