import React from "react";
import PropTypes from "prop-types";

import brandLogoUrls from "./../common/logoUrls";

import { withStyles } from "@material-ui/core/styles";
import { Chip, Checkbox } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    maxWidth: 240,
    margin: 8,
  },
  "@media (min-width: 1024px)": {
    checkBox: {
      padding: 4,
    },
    brandLogoButton: {
      cursor: "pointer",
      border: "1px #ccc solid",
      width: "95%",
      position: "relative",
      padding: 6,
      borderRadius: 5,
      margin: "8px auto",
    },
    chip: {
      fontWeight: "bold",
      position: "absolute",
      right: 9,
      borderRadius: 7,
    },
    brandLogoImg: {
      height: 20,
      position: "relative",
      maxWidth: 142,
      top: 6,
      left: 1,
    },
    root: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
});

class BrandFilter extends React.Component {
  handleChange = (event) => {
    let databrand =
      event.target.getAttribute("databrand") ||
      event.target.parentElement.getAttribute("databrand") ||
      event.target.parentElement.parentElement.getAttribute("databrand");

    this.props.changeHandler(databrand);
  };

  render() {
    const { classes, tires, brands = [] } = this.props;

    return (
      <div className={classes.root}>
        {Object.keys(brandLogoUrls)
          .filter((b) => {
            const result = tires.find(
              (t) => t.brand.toLowerCase() === b.toLowerCase()
            );
            if (result !== undefined) {
              return true;
            }
            return false;
          })
          .map((b, i) => {
            let brand = brandLogoUrls[b];

            const tiresFromBrand = tires.filter(
              (t) => t.brand.toLowerCase() === b.toLowerCase()
            );

            let checked = false;
            if (
              brands.findIndex((bb) => bb.toLowerCase() === b.toLowerCase()) !==
              -1
            ) {
              checked = true;
            }

            return (
              <span
                key={`b_${i}`}
                className={classes.brandLogoButton}
                databrand={b.toLowerCase()}
              >
                <Checkbox
                  databrand={b.toLowerCase()}
                  onClick={this.handleChange}
                  checked={checked}
                  className={classes.checkBox}
                />
                <img
                  onClick={this.handleChange}
                  src={brand}
                  className={classes.brandLogoImg}
                />
                <Chip
                  className={classes.chip}
                  label={tiresFromBrand.length}
                  variant="outlined"
                />
              </span>
            );
          })}
      </div>
    );
  }
}

BrandFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  changeHandler: PropTypes.func,
};

export default withStyles(styles)(BrandFilter);
