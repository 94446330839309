import * as actions from './../catalogActions';

export default function getData(matchedRoute) {
  return (dispatch) => {
    // here is where you can make async api requests for data
    return new Promise((resolve) => {
      const fakeDispatch = (objToDispatch) => {
        dispatch(objToDispatch);
        resolve();
      };

      const { width, profile, rim } = matchedRoute.params;

      actions.searchTiresByDimensions(width, profile, rim)(fakeDispatch);
    });
  };
}
