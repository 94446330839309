import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const hebrewLabels = {
  showSets: "הסתר סטים בתוצאות החיפוש",
};

const styles = (theme) => ({
  root: {
    margin: theme.spacing(),
    marginTop: 0,
    marginBottom: 0,
  },
  "@media (min-width: 1024px)": {
    root: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
});

class SetsFilterSwitch extends React.Component {
  handleChange = (event) => {
    this.props.changeHandler(event.target.checked);
  };

  render() {
    const { classes } = this.props;

    return (
      <FormGroup row className={classes.root}>
        <FormControlLabel
          control={<Switch onChange={this.handleChange} value="sets" />}
          label={hebrewLabels.showSets}
          style={{ color: "#000" }}
        />
      </FormGroup>
    );
  }
}

SetsFilterSwitch.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleSets: PropTypes.bool,
  changeHandler: PropTypes.func,
};

export default withStyles(styles)(SetsFilterSwitch);
