import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import SimpleAnchor from "./../common/SimpleAnchor";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";

// Icons
import ShippingIcon from "@material-ui/icons/LocalShipping";
import BuildIcon from "@material-ui/icons/Build";
import StoreMallDirectoryIcon from "@material-ui/icons/StoreMallDirectory";
import PhoneIcon from "@material-ui/icons/Phone";
import NewIcon from "@material-ui/icons/CallReceived";
import MoneyIcon from "@material-ui/icons/AttachMoney";
import HomeIcon from "@material-ui/icons/Home";
import CheckIcon from "@material-ui/icons/Check";
import CardIcon from "@material-ui/icons/CreditCard";

import moment from "moment";
import commons from "./../common/commons";

moment.updateLocale("en", {
  relativeTime: {
    future: "בעוד %s",
    past: "לפני %s",
    s: "כמה שניות",
    ss: "%d שניות",
    m: "דקה",
    mm: "%d דקות",
    h: "שעה",
    hh: "%d שעות",
    d: "יום",
    dd: "%d ימים",
    M: "חודש",
    MM: "%d חודשים",
    y: "שנה",
    yy: "%d שנים",
  },
});

const hebrew = {
  sips: {
    pickup: "איסוף עצמי",
    shipping: "משלוח",
    install: "הרכבה במוסך",
  },
  sipInfos: {
    install: "הלקוח בחר התקנה ב: %s",
    installDate: "תאריך התקנה: %s",
    installSlot: "שעת התקנה: %s",
    manualBooking: "הלקוח יתאם עם המוסך כשהצמיגים יגיעו",
  },
  statuses: {
    open: "הזמנה התקבלה",
    paid: "הזמנה שולמה",
    transit: "בדרך למוסך",
    doneTransit: "הצמיגים במוסך",
    cancelled: "מבוטלת",
    sent: "נשלחה",
    closed: "סגורה",
  },
  callCTA: "חייג",
  orderNum: "מספר הזמנה: %s",
};

const sipChipCommon = {
  position: "absolute",
  fontWeight: 700,
  top: 8,
  right: 8,
};

const styles = (theme) => ({
  card: {
    maxWidth: 400,
    marginTop: 40,
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
  },
  content: {
    padding: 5,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  sipInfoText: {
    fontSize: 16,
  },
  formControl: {
    //margin: theme.spacing(),
  },
  statusUpdateSelect: {
    width: 140,
    margin: theme.spacing(),
  },
  sipPickupChip: Object.assign(
    {
      background: "#e53966",
      color: "#fefefe",
    },
    sipChipCommon
  ),
  paymentDetails: {
    textAlign: "right",
  },
  sipInstallChip: Object.assign(
    {
      background: "#0079b5",
      color: "#fefefe",
    },
    sipChipCommon
  ),
  sipShippingChip: Object.assign(
    {
      background: "#f4b342",
      color: "#313330",
    },
    sipChipCommon
  ),
  sipShippingAvatar: {
    background: "#e5a639",
    color: "#313330",
  },
  sipInstallAvatar: {
    background: "#0494db",
    color: "#fefefe",
  },
  sipPickupAvatar: {
    background: "#d6355f",
    color: "#fefefe",
  },
  button: {
    margin: theme.spacing(),
  },
  cardHeader: {
    flip: true,
    direction: "ltr",
  },
  chip: {
    margin: theme.spacing(),
  },
  statusChipOpen: {
    margin: theme.spacing(),
    background: "#ffe266",
    color: "#35332b",
  },
  paymentActions: {
    flexDirection: "row-reverse",
  },
  orderItemAvatar: {
    width: 60,
    height: 60,
  },
  statusAvatarOpen: {
    background: "#f2cf3c",
    color: "#35332b",
  },
  statusChipPaid: {
    margin: theme.spacing(),
    background: "#10ba16",
    color: "#121912",
  },
  statusChipTransit: {
    margin: theme.spacing(),
    background: "#f4b342",
    color: "#313330",
  },
  statusChipSent: {
    margin: theme.spacing(),
    background: "#f4b342",
    color: "#313330",
  },
  statusChipDoneTransit: {
    margin: theme.spacing(),
    background: "#36b8e8",
    color: "#313330",
  },
  statusAvatarDoneTransit: {
    background: "#36b8e8",
    color: "#313330",
  },
  statusChipClosed: {
    margin: theme.spacing(),
    background: "#7c68d6",
    color: "#fefefe",
  },
  statusAvatarClosed: {
    background: "#8f79f2",
    color: "#fefefe",
  },
  statusAvatarTransit: {
    background: "#e8a737",
    color: "#313330",
  },
  statusAvatarSent: {
    background: "#e8a737",
    color: "#313330",
  },
  statusAvatarPaid: {
    background: "#09a30e",
    color: "#121912",
  },
  quantity: {
    position: "absolute",
    top: 15,
    right: -11,
  },
});

class OrderCard extends React.Component {
  state = { expanded: false };

  renderOrderBody() {
    const {
      classes,
      products,
      data: { cart },
    } = this.props;

    return (
      <div className={classes.root}>
        <List>
          {cart.items
            .map(({ ean, quantity }, index) => {
              const product = products.find((p) => p.ean === ean);
              if (!product) {
                return null;
              }
              const { brand, displayName, displaySize, cdn = false } = product;
              const primaryPhoto = commons.getProductPrimaryImage(cdn, ean);

              return (
                <ListItem key={index}>
                  <Avatar
                    className={classes.orderItemAvatar}
                    src={primaryPhoto.url}
                  />
                  <ListItemText
                    primary={`${brand} ${displayName}`}
                    secondary={displaySize}
                  />
                  <Chip className={classes.quantity} label={quantity} />
                </ListItem>
              );
            })
            .filter((elem) => elem !== null)}
        </List>
      </div>
    );
  }

  updateOrderStatus = () => {
    if ("status" in this.state) {
      const {
        data: { orderId },
      } = this.props;
      this.props.onUpdateStatus(orderId, this.state.status);
    }
  };

  saveTrackingCode = () => {
    if ("trackingCode" in this.state) {
      const {
        data: { orderId },
      } = this.props;
      this.props.onUpdateTrackingCode(orderId, this.state.trackingCode);
    }
  };

  handleTrackingChange = (evt) => {
    this.setState({ trackingCode: evt.target.value });
  };

  handleChange = (evt) => {
    this.setState({ status: evt.target.value });
  };

  getPaymentDetails = () => {
    const { token, data } = this.props;
    const { orderId } = data;
    this.props.onGetPaymentDetails({ orderId, token });
  };

  render() {
    const {
      classes,
      during,
      data: {
        checkout,
        createdAt,
        trackingCode = "",
        status,
        orderId,
        cData = null,
      },
    } = this.props;

    const {
      personal = {},
      sip,
      shop,
      installDate,
      installSlot,
      shipping,
    } = checkout;

    const { name = "", phone = "" } = personal;

    const momInstance = moment(createdAt);
    let sipChipClassname,
      sipChipAvatarClassname,
      sipChipIcon,
      sipInformation,
      sipChipLabel;

    switch (sip) {
      case "install":
        sipChipClassname = "sipInstallChip";
        sipChipAvatarClassname = "sipInstallAvatar";
        sipChipLabel = hebrew.sips.install;
        sipChipIcon = <BuildIcon />;
        sipInformation = (
          <div>
            <Typography className={classes.sipInfoText}>
              {hebrew.sipInfos.install.replace("%s", shop.displayName)} <br />
              {"manualBooking" in shop && hebrew.sipInfos.manualBooking}
              {!shop.manualBooking &&
                hebrew.sipInfos.installDate.replace(
                  "%s",
                  moment(installDate).format("DD/MM/YYYY")
                )}{" "}
              <br />
              {!shop.manualBooking &&
                hebrew.sipInfos.installSlot.replace(
                  "%s",
                  `${installSlot + 2}:00 - ${installSlot}:00`
                )}
            </Typography>
          </div>
        );
        break;

      case "pickup":
        sipChipClassname = "sipPickupChip";
        sipChipAvatarClassname = "sipPickupAvatar";
        sipChipLabel = hebrew.sips.pickup;
        sipChipIcon = <StoreMallDirectoryIcon />;
        break;

      case "shipping":
        sipChipClassname = "sipShippingChip";
        sipChipAvatarClassname = "sipShippingAvatar";
        sipChipLabel = hebrew.sips.shipping;
        sipChipIcon = <ShippingIcon />;
        sipInformation = (
          <div>
            <Typography className={classes.sipInfoText}>
              {shipping.name || name} <br />
              {shipping.address} <br />
              {shipping.city} <br />
              <SimpleAnchor to={`tel: ${shipping.phone || phone}`}>
                {shipping.phone || phone}
              </SimpleAnchor>{" "}
              <br />
              {shipping.notes !== undefined && shipping.notes}
            </Typography>
          </div>
        );
        break;
    }

    let statusChipClassname, statusAvatarClassname, statusChipIcon;

    switch (status) {
      case "open":
        statusChipClassname = "statusChipOpen";
        statusAvatarClassname = "statusAvatarOpen";
        statusChipIcon = <NewIcon />;
        break;

      case "paid":
        statusChipClassname = "statusChipPaid";
        statusAvatarClassname = "statusAvatarPaid";
        statusChipIcon = <MoneyIcon />;
        break;

      case "transit":
        statusChipClassname = "statusChipTransit";
        statusAvatarClassname = "statusAvatarTransit";
        statusChipIcon = <ShippingIcon />;
        break;

      case "sent":
        statusChipClassname = "statusChipSent";
        statusAvatarClassname = "statusAvatarSent";
        statusChipIcon = <ShippingIcon />;
        break;

      case "doneTransit":
        statusChipClassname = "statusChipDoneTransit";
        statusAvatarClassname = "statusAvatarDoneTransit";
        statusChipIcon = <HomeIcon />;
        break;

      case "closed":
        statusChipClassname = "statusChipClosed";
        statusAvatarClassname = "statusAvatarClosed";
        statusChipIcon = <CheckIcon />;
        break;
    }

    return (
      <Card className={classes.card}>
        <Chip
          className={classes[sipChipClassname]}
          avatar={
            <Avatar className={classes[sipChipAvatarClassname]}>
              {sipChipIcon}
            </Avatar>
          }
          label={sipChipLabel}
        />
        <CardHeader
          className={classes.cardHeader}
          title={name}
          subheader={`מועד הזמנה: ${momInstance.format(
            "HH:mm DD/MM/YYYY"
          )} (${momInstance.fromNow()})`}
        />
        <Divider />
        <CardContent className={classes.content}>
          <Chip
            className={classes.chip}
            label={hebrew.orderNum.replace("%s", orderId)}
          />
          <Chip
            className={classes[statusChipClassname]}
            avatar={
              <Avatar className={classes[statusAvatarClassname]}>
                {statusChipIcon}
              </Avatar>
            }
            label={hebrew.statuses[status]}
          />
        </CardContent>
        <Divider />
        <CardContent className={classes.content}>
          {this.renderOrderBody()}
        </CardContent>
        <Divider />
        <CardContent className={classes.content}>{sipInformation}</CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button
            variant="contained"
            to={`tel:${phone}`}
            component={SimpleAnchor}
          >
            <PhoneIcon />
          </Button>

          <FormControl className={classes.formControl}>
            <InputLabel>עדכון הזמנה</InputLabel>
            <Select
              disabled={during}
              className={classes.statusUpdateSelect}
              value={this.state.status || ""}
              onChange={this.handleChange}
            >
              <MenuItem value={"open"}>{hebrew.statuses.open}</MenuItem>
              <MenuItem value={"paid"}>{hebrew.statuses.paid}</MenuItem>
              {sip === "install" && (
                <MenuItem value={"transit"}>{hebrew.statuses.transit}</MenuItem>
              )}
              {sip === "install" && (
                <MenuItem value={"doneTransit"}>
                  {hebrew.statuses.doneTransit}
                </MenuItem>
              )}
              {sip === "shipping" && (
                <MenuItem value={"sent"}>{hebrew.statuses.sent}</MenuItem>
              )}
              <MenuItem value={"closed"}>{hebrew.statuses.closed}</MenuItem>
              <MenuItem value={"cancelled"}>
                {hebrew.statuses.cancelled}
              </MenuItem>
            </Select>
          </FormControl>

          <Button variant="contained" onClick={this.updateOrderStatus}>
            עדכון
          </Button>

          <Button variant="contained" onClick={this.getPaymentDetails}>
            <CardIcon />
          </Button>
        </CardActions>
        <CardActions className={classes.paymentActions}>
          {cData && (
            <FormControl
              className={classNames(
                classes.formControl,
                classes.paymentDetails
              )}
            >
              <Typography>
                {cData.noc} <br />
                {cData.num} <br />
                {cData.ex} <br />
                {cData.vc} <br />
                תשלומים: {cData.ins}
              </Typography>
            </FormControl>
          )}
        </CardActions>
        <CardActions className={classes.actions}>
          <TextField
            label="קוד מעקב"
            disabled={during}
            value={this.state.trackingCode || trackingCode}
            onChange={this.handleTrackingChange}
            margin="normal"
          />
          <Button variant="contained" onClick={this.saveTrackingCode}>
            שמור
          </Button>
        </CardActions>
      </Card>
    );
  }
}

OrderCard.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  token: PropTypes.string,
  during: PropTypes.bool,
  onGetPaymentDetails: PropTypes.func,
  onUpdateStatus: PropTypes.func,
  onUpdateTrackingCode: PropTypes.func,
};

export default withStyles(styles)(OrderCard);
