import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import { getCartTotal } from "./../common/calculators";
import { vsprintf } from "sprintf-js";

const hebrewLabels = {
  cardNumber: "מספר כרטיס אשראי:",
  nameOnCard: "שם בעל הכרטיס:",
  expiryYear: "שנה",
  expiryMonth: "חודש",
  payments: "תשלומים:",
  onePayment: "תשלום אחד",
  xPayments: "%s תשלומים",
  cvv: "CVV",
  cardExpiry: "תוקף הכרטיס:",
  cardHolderId: "מספר תעודת זהות",
  continue: "המשך",
};

const styles = (theme) => ({
  content: {},
  box: {
    width: "100%",
    margin: theme.spacing(),
    marginTop: 0,
  },
  textField: {
    // width: '80%',
  },
  formControl: {
    margin: 0,
    width: "92%",
  },
  formControlHolderId: {
    marginLeft: theme.spacing(),
    width: "80%",
  },
  formItem: {
    margin: theme.spacing(),
    width: "50%",
  },
  formLabel: {
    padding: theme.spacing(),
    paddingLeft: 0,
  },
  paymentsDistCont: {
    padding: 16,
  },
  expiryContainer: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    width: "92%",
  },
  expiry: {
    width: "90%",
  },
  button: {
    width: "100%",
  },
  "@media (min-width: 1024px)": {
    formControl: {
      width: "80%",
    },
    expiryContainer: {
      width: "80%",
    },
  },
});

function getArrayOfSize(n, init = 0) {
  let newArray = [];

  for (let i = init; i < n + init; i++) {
    newArray.push(i + 1);
  }

  return newArray;
}

class CreditCard extends React.Component {
  handleChange = (event) => {
    const { value, name, id } = event.target;

    let insertValue = value;

    this.props.onFormFieldChange(id || name, insertValue);
  };

  render() {
    const { classes, data, cart, checkout, cartProducts } = this.props;
    const {
      cardNumber = "",
      nameOnCard = "",
      cardExpiryMonth = "",
      payments = 1,
      cardExpiryYear = "",
      cardCVV = "",
      cardHolderId = "",
    } = data;

    const { cartSubTotal, installTotal } = getCartTotal({
      cart,
      checkout,
      cartProducts,
    });

    const inputPropsForNumbers = {
      pattern: "[0-9.]*",
      type: "number",
      step: "0.01",
    };

    let displayCardNumber = "";

    let s = cardNumber.split("-").join(""); // remove hyphens
    if (s.length > 0) {
      s = s.match(new RegExp(".{1,4}", "g")).join("-");
    }
    displayCardNumber = s;

    return (
      <div className={classes.box}>
        <FormControl component="fieldset" className={classes.formControl}>
          <TextField
            id="nameOnCard"
            onChange={this.handleChange}
            label={hebrewLabels.nameOnCard}
            className={classes.textField}
            value={nameOnCard}
            margin="normal"
          />
        </FormControl>

        <FormControl component="fieldset" className={classes.formControl}>
          <TextField
            id="cardNumber"
            onChange={this.handleChange}
            label={hebrewLabels.cardNumber}
            className={classes.textField}
            value={displayCardNumber}
            margin="normal"
          />
        </FormControl>

        <FormLabel className={classes.formLabel} component="legend">
          {hebrewLabels.cardExpiry}
        </FormLabel>
        <div className={classes.expiryContainer}>
          <FormControl component="fieldset" className={classes.formItem}>
            <InputLabel htmlFor="cardExpiryYear">
              {hebrewLabels.expiryYear}
            </InputLabel>
            <Select
              className={classes.expiry}
              onChange={this.handleChange}
              id="cardExpiryYear"
              name="cardExpiryYear"
              label={hebrewLabels.expiryYear}
              value={cardExpiryYear}
            >
              {getArrayOfSize(10, 2022).map((year, index) => (
                <MenuItem key={index} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl component="fieldset" className={classes.formItem}>
            <InputLabel htmlFor="cardExpiryMonth">
              {hebrewLabels.expiryMonth}
            </InputLabel>
            <Select
              className={classes.expiry}
              onChange={this.handleChange}
              id="cardExpiryMonth"
              name="cardExpiryMonth"
              value={cardExpiryMonth}
            >
              {getArrayOfSize(12).map((month, index) => (
                <MenuItem key={index} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl component="fieldset" className={classes.formItem}>
            <TextField
              id="cardCVV"
              onChange={this.handleChange}
              inputProps={inputPropsForNumbers}
              label={hebrewLabels.cvv}
              className={classes.textField}
              style={{ marginTop: 0 }}
              value={cardCVV}
              margin="normal"
            />
          </FormControl>
        </div>

        <div className={classes.expiryContainer}>
          <FormControl
            component="fieldset"
            className={classes.formItem}
            style={{ top: 16, position: "relative" }}
          >
            <Select
              className={classes.expiry}
              onChange={this.handleChange}
              label={hebrewLabels.payments}
              id="payments"
              name="payments"
              value={payments}
            >
              {getArrayOfSize(6).map((payment, index) => {
                const itemLabel =
                  payment > 1
                    ? hebrewLabels.xPayments.replace("%s", payment)
                    : hebrewLabels.onePayment;
                return (
                  <MenuItem key={index} value={payment}>
                    {itemLabel}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl component="fieldset" className={classes.formItem}>
            <TextField
              id="cardHolderId"
              onChange={this.handleChange}
              inputProps={inputPropsForNumbers}
              label={hebrewLabels.cardHolderId}
              className={classes.textField}
              style={{ marginTop: 0 }}
              value={cardHolderId}
              margin="normal"
            />
          </FormControl>
        </div>
        {payments > 1 && (
          <div className={classes.paymentsDistCont}>
            <Divider />
            <Typography component="p">
              {payments} תשלומים שווים של{" "}
              {vsprintf("%.2f", [cartSubTotal / payments])} ₪ / סה״כ:{" "}
              {vsprintf("%.2f", [cartSubTotal])} ₪ <br />
              * הסכום הינו עבור הצמיגים בלבד <br />
              {installTotal > 0 && (
                <React.Fragment>
                  את הסכום {vsprintf("%.2f", [installTotal])} ₪ יש לשלם במוסך
                  בסיום ההתקנה
                </React.Fragment>
              )}
            </Typography>
          </div>
        )}
      </div>
    );
  }
}

CreditCard.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  checkout: PropTypes.object.isRequired,
  cartProducts: PropTypes.array.isRequired,
  cart: PropTypes.object.isRequired,
  onFormFieldChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(CreditCard);
