import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import ShopProfileCard from "./../shops/ShopProfileCard";

const styles = (theme) => ({
  root: {
    padding: 16,
    marginTop: 33,
    marginLeft: "auto",
    marginRight: "auto",
  },
  headline: {
    fontSize: "14pt",
    fontWeight: 525,
  },
  para: {
    margin: "15px 8px",
  },
  "@media (min-width: 1024px)": {
    root: {
      marginTop: 80,
      width: 1000,
    },
  },
});

class ShopPage extends React.Component {
  componentDidMount() {
    // Load up the required shop
    const { name } = this.props.match.params;
    this.props.actions.getShopByName(name);
  }

  reportLike = (shopId) => {
    this.props.actions.reportLikeEventForShop(shopId);
  };

  render() {
    const { classes, catalog } = this.props;
    const { shop } = catalog.shopIndex;

    return (
      <div>
        <Paper className={classes.root} elevation={4}>
          {shop !== null && (
            <ShopProfileCard
              stats={catalog.stats}
              data={shop}
              reportLike={this.reportLike}
            />
          )}
        </Paper>
      </div>
    );
  }
}

ShopPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired,
  match: PropTypes.object,
};

export default withStyles(styles)(ShopPage);
