import * as cookieStorage from './../../actions/common/cookieStorage';

const cStorage = cookieStorage.getCookieStorage();

export function addToCart(ean, isSet = false) {
    let cartFromStorage;

    cartFromStorage = cStorage.getObject('cart');

    let cart;
    let eanIndex;

    if (cartFromStorage === null) { // Cart is empty, let's init it!
        cart = {
            items: []
        };
    } else {
        cart = cartFromStorage;
    }

    eanIndex = cart.items.findIndex((item) => item.ean === ean);

    if (eanIndex >= 0) { // Product already exists in the cart
        cart.items[eanIndex].quantity++;
    } else {
        cart.items.push({
            ean,
            quantity: 1,
            isSet
        });
    }

    // Update the cart into local storage
    cStorage.setObject('cart', cart);

    return cart;
}

export function restoreCartFromCookieStorage() {
    let cart;

    const cartFromStorage = cStorage.getObject('cart');

    if (cartFromStorage === null) { // Cart is empty, let's init it!
        cart = {
            items: [],
        };
    } else {
        cart = cartFromStorage;
    }

    return cart;
}

export function updateProductQuantity(ean, newQuantity) {
    const cart = restoreCartFromCookieStorage();
    const eanIndex = cart.items.findIndex((item) => item.ean === ean);

    if (eanIndex >= 0) {
        cart.items[eanIndex].quantity = newQuantity;
    }

    // Update the cart into local storage
    cStorage.setObject('cart', cart);

    return cart;
}

export function removeItemFromCart(ean) {
    const cart = restoreCartFromCookieStorage();
    const eanIndex = cart.items.findIndex((item) => item.ean === ean);

    if (eanIndex >= 0) {
        cart.items.splice(eanIndex, 1);
    }

    // Update the cart into local storage
    cStorage.setObject('cart', cart);

    return cart;
}

export function resetCart() {
    cStorage.clear('cart');
}

export function getCartItemsCount() {
    const cart = restoreCartFromCookieStorage();
    return cart.items.reduce((total, item) => {
        return total + item.quantity;
    }, 0);
}