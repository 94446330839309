let serverSideCookieStorage;

export function setServerCookieStorage(cookies) {
    serverSideCookieStorage = cookies;
}

export function getCookieStorage() {
    return {
        setItem,
        getItem,
        clear,
        setObject,
        getObject,
    };
}

function clear(k) {
    return eraseCookie(k);
}

function eraseCookie(name) {
    if (typeof localStorage !== 'undefined') {
        document.cookie = name + '=; Max-Age=-99999999;';
    }
}

function getItem(k) {
    if (typeof localStorage === 'undefined') {
        if (!serverSideCookieStorage) {
            return null;
        }
        return serverSideCookieStorage[k] || null;
    }
    return getCookie(k);
}

function setItem(key, value) {
    if (typeof localStorage !== 'undefined') {
        setCookie(key, value, 365);
    }
}

function setCookie(name, value, days) {
    if (typeof localStorage === 'undefined') {
        return;
    }

    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function setObject(key, value) {
    this.setItem(key, JSON.stringify(value));
};

function getObject(key) {
    const value = this.getItem(key);
    return value && JSON.parse(value);
};