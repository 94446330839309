import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    padding: 16,
    marginTop: 33,
    marginLeft: "auto",
    marginRight: "auto",
  },
  headline: {
    fontSize: "14pt",
    fontWeight: 525,
  },
  para: {
    margin: "15px 8px",
  },
  "@media (min-width: 1024px)": {
    root: {
      marginTop: 80,
      width: 1000,
    },
    para: {
      width: "70%",
    },
  },
});

const hebrewLabels = {
  title: "פרטים משפטיים של נותן השירות",
  paras: [
    "הגוף המפעיל ומתחזק את המידע שנמצא בכתובת בה הינך גולש:",
    "איתמר ארזואן שירותים",
    "רחוב התאנה 9 , יד רמב״ם, מיקוד: 9979300",
    "תא דואר 51359",
    "כתובת מייל:  service@sportyshop.co.il",
    "עניינים משפטיים ידונו בבית המשפט רמלה או רחובות בהתאם להתנהלות תיק מס הכנסה",
    "מנהל בפועל: איתמר ארזואן",
    "מספר עוסק מורשה: 03696453-4",
    "פרטים משפטיים אלו חלים גם על דף הפייסבוק שלנו ומי שעומד מאחוריו אשר נמצא בכתובת:",
    <a href="http://www.facebook.com/sportyshop.moto">
      http://www.facebook.com/sportyshop.moto
    </a>,
  ],
};

class ImprintPage extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Paper className={classes.root} elevation={4}>
          <Typography component="h1" variant="h1" className={classes.headline}>
            {hebrewLabels.title}
          </Typography>

          {hebrewLabels.paras.map((pa) => {
            return (
              <Typography className={classes.para} component="p">
                {pa}
              </Typography>
            );
          })}
        </Paper>
      </div>
    );
  }
}

ImprintPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImprintPage);
