import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import { vsprintf } from 'sprintf-js';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  priceTitle: {
    fontSize: 15,
    paddingLeft: 5,
  },
  formControl: {
    display: 'flex',
    contentJustify: 'center',
  },
  select: {
    margin: theme.spacing(2),
    marginTop: 0,
  },
  paper: {
    height: 55,
    width: 100,
    position: 'relative',
  },
  paperPrice: {
    height: 50,
    width: 100,
    position: 'relative',
  },
  control: {
    padding: theme.spacing(2),
  },
  sizePaper: {
    width: 150,
  },
  strikePrice: {
    textDecoration: 'line-through',
    textAlign: 'center',
    fontFamily: 'Lato',
    direction: 'ltr',
    fontSize: '8pt',
    position: 'absolute',
    color: '#8e8e8e',
    top: 5,
    right: 5,
  },
  '@media (min-width: 1024px)': {
    root: {
      position: 'absolute',
      width: 350,
      right: 5,
      top: 5,
    },
  },
});

function getArrayOfSize(n) {
  const newArray = [];

  for (let i = 0; i < n; i++) {
    newArray.push(i + 1);
  }

  return newArray;
}

const hebrewLables = {
  price: 'מחיר',
  quantity: 'כמות',
  lineTotalPrice: 'סה״כ',
};

class CartItemPriceRow extends React.Component {
  state = {
    spacing: '1',
  };

  changeProductQuantity = event => {
    this.props.onChangeQuantity(this.props.data.ean, event.target.value);
  };

  render() {
    const { classes, data } = this.props;
    const { spacing } = this.state;

    const { price, fullPrice } = data;

    return (
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Grid
            container
            className={classes.demo}
            justifyContent="center"
            spacing={Number(spacing)}
          >
            <Grid key="price" item>
              <Paper className={classes.paper}>
                <div className={classes.strikePrice}>
                  {vsprintf('%.2f', [fullPrice])} ₪
                </div>
                <Typography
                  className={classes.priceTitle}
                  variant="h3"
                  component="h3"
                >
                  {hebrewLables.price}
                </Typography>
                <div className="result-item-pricing">
                  {vsprintf('%.2f', [price])} ₪
                </div>
              </Paper>
            </Grid>

            <Grid key="quantity" item>
              <Paper className={classes.paper}>
                <Typography
                  className={classes.priceTitle}
                  variant="h3"
                  component="h3"
                >
                  {hebrewLables.quantity}
                </Typography>
                <FormControl className={classes.formControl}>
                  <Select
                    className={classes.select}
                    value={this.props.quantity}
                    onChange={this.changeProductQuantity}
                  >
                    {getArrayOfSize(10).map(item => {
                      return (
                        <MenuItem value={item} key={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Paper>
            </Grid>

            <Grid key="line_total" item>
              <Paper className={classes.paper}>
                <Typography
                  className={classes.priceTitle}
                  variant="h3"
                  component="h3"
                >
                  {hebrewLables.lineTotalPrice}
                </Typography>
                <div className="result-item-pricing">
                  {vsprintf('%.2f', [price * this.props.quantity])} ₪
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

CartItemPriceRow.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
  onChangeQuantity: PropTypes.func.isRequired,
};

export default withStyles(styles)(CartItemPriceRow);
