import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import PaymentIcon from "@material-ui/icons/Payment";
import Grid from "@material-ui/core/Grid";

import CartItem from "./CartItem";
import CartItemDesktop from "./CartItemDesktop";
import CartTotal from "./CartTotal";

const styles = (theme) => ({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: 150,
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },

  cartRoot: {
    marginTop: 15,
    paddingTop: 16,
    paddingBottom: 16,
    marginLeft: "auto",
    marginRight: "auto",
  },
  emptyCart: {
    padding: theme.spacing(4),
  },

  loaderRoot: {
    margin: theme.spacing(),
    marginTop: 35,
    display: "flex",
    justifyContent: "center",
  },
  rightIcon: {
    marginLeft: theme.spacing(),
  },
  button: {
    width: "100%",
    fontSize: "13pt",
    fontWeight: 500,
  },
  paymentCTAContainer: {
    margin: theme.spacing(),
  },
  comment: {
    textAlign: "center",
  },
  progress: {
    margin: theme.spacing(2),
  },
  cartDesktopRoot: {
    display: "none",
  },
  "@media (min-width: 1024px)": {
    cartRoot: {
      display: "none",
    },
    loaderRoot: {
      marginTop: 80,
      width: 1000,
      marginLeft: "auto",
      marginRight: "auto",
    },
    comment: {
      textAlign: "right",
      position: "relative",
      top: 5,
      right: 9,
    },
    nonFlexPaper: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(),
      height: 52,
      textAlign: "right",
      position: "relative",
    },
    rightIcon: {
      position: "relative",
      top: 7,
    },
    button: {
      display: "inline",
      margin: theme.spacing(),
    },
    cartDesktopRoot: {
      marginTop: 85,
      width: 1000,
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
    },
    paper: {
      padding: 8,
    },
  },
});

const hebrewLabels = {
  cartIsEmpty: "העגלה ריקה",
  controlComment: "קביעת התור במוסך מתבצעת בשלב הבא",
  itemRemovedSuccessText: "המוצר הוסר מהעגלה",
  itemQuantityChangedSuccessText: "הכמות שונתה בהצלחה",
  moveToPaymentButtonText: "המשך בתהליך ההזמנה",
  continueShoppingCTA: "המשך בקניות",
};

class CartPage extends React.Component {
  componentDidMount() {
    // Load up the product given or redirect to not found page.
    if (this.props.catalog.cart.items.length > 0) {
      this.props.actions.getCartProducts(this.props.catalog.cart);
    }
  }

  onChangeProductQuantity = (ean, newQuantity) => {
    this.props.actions.changeProductQuantity(ean, newQuantity);
    this.props.actions.toggleSnackbar(true, {
      message: hebrewLabels.itemQuantityChangedSuccessText,
      action: null,
    });
  };

  onRemoveFromCart = (ean) => {
    // Metrics
    this.props.actions.collectMetric({
      kind: "PRODUCT_REMOVE_FROM_CART",
      ean,
    });

    this.props.actions.removeItemFromCart(ean);
    this.props.actions.toggleSnackbar(true, {
      message: hebrewLabels.itemRemovedSuccessText,
      action: null,
    });
  };

  reportInitiateCheckoutToFacebook = () => {
    const { cart, cartProducts } = this.props.catalog;

    const cartTotal = cart.items.reduce((total, item) => {
      const productIndex = cartProducts.findIndex(
        (product) => product.ean === item.ean
      );

      const product = cartProducts[productIndex];
      return total + product.price * item.quantity;
    }, 0);

    cart.items.map((item) => {
      // Metrics
      this.props.actions.collectMetric({
        kind: "CHECKOUT_INIT",
        ean: item.ean,
      });
    });

    const data = {
      value: cartTotal,
      currency: "ILS",
    };

    /*eslint no-undef: 0*/
    if (typeof fbq === "function") {
      fbq("track", "InitiateCheckout", data);
    }
  };

  render() {
    const { classes, catalog } = this.props;
    const { cart, nextShipment, currentShipment } = catalog;

    let content;

    if (catalog.cartProducts.length > 0 && catalog.cart.items.length > 0) {
      content = (
        <React.Fragment>
          <div className={classes.cartDesktopRoot}>
            <Grid container spacing={0}>
              <Grid item lg={12}>
                {cart.items.map((item, index) => {
                  const productIndex = catalog.cartProducts.findIndex(
                    (product) => {
                      return product.ean === item.ean;
                    }
                  );

                  if (productIndex !== -1) {
                    return (
                      <CartItemDesktop
                        key={catalog.cartProducts[productIndex].ean}
                        cardCount={index}
                        nextShipment={nextShipment}
                        currentShipment={currentShipment}
                        onRemoveFromCart={this.onRemoveFromCart}
                        onChangeQuantity={this.onChangeProductQuantity}
                        data={catalog.cartProducts[productIndex]}
                        quantity={item.quantity}
                      />
                    );
                  }

                  return <React.Fragment key={index}></React.Fragment>;
                })}
              </Grid>
            </Grid>

            <Grid container spacing={0}>
              <Grid item lg={12}>
                <Paper className={classes.nonFlexPaper}>
                  <CartTotal
                    cart={catalog.cart}
                    cartProducts={catalog.cartProducts}
                  />

                  <Button
                    variant="contained"
                    component={Link}
                    to="/"
                    className={classes.button}
                  >
                    {hebrewLabels.continueShoppingCTA}
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.reportInitiateCheckoutToFacebook}
                    component={Link}
                    to="/checkout"
                    className={classes.button}
                  >
                    {hebrewLabels.moveToPaymentButtonText}
                    <PaymentIcon className={classes.rightIcon} />
                  </Button>

                  <Typography component="p" className={classes.comment}>
                    {hebrewLabels.controlComment}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </div>

          <div className={classes.cartRoot}>
            <div className={classes.paymentCTAContainer}>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                onClick={this.reportInitiateCheckoutToFacebook}
                to="/checkout"
                className={classes.button}
              >
                {hebrewLabels.moveToPaymentButtonText}
                <PaymentIcon className={classes.rightIcon} />
              </Button>

              <Typography component="p" className={classes.comment}>
                {hebrewLabels.controlComment}
              </Typography>

              <Button
                variant="contained"
                component={Link}
                to="/"
                style={{ marginTop: 10 }}
                className={classes.button}
              >
                {hebrewLabels.continueShoppingCTA}
              </Button>
            </div>

            <CartTotal
              cart={catalog.cart}
              cartProducts={catalog.cartProducts}
            />

            {cart.items.map((item, index) => {
              const productIndex = catalog.cartProducts.findIndex((product) => {
                return product.ean === item.ean;
              });

              if (productIndex !== -1) {
                return (
                  <CartItem
                    key={catalog.cartProducts[productIndex].ean}
                    cardCount={index}
                    nextShipment={nextShipment}
                    currentShipment={currentShipment}
                    onRemoveFromCart={this.onRemoveFromCart}
                    onChangeQuantity={this.onChangeProductQuantity}
                    data={catalog.cartProducts[productIndex]}
                    quantity={item.quantity}
                  />
                );
              }

              return <React.Fragment key={index}></React.Fragment>;
            })}
            <CartTotal
              cart={catalog.cart}
              cartProducts={catalog.cartProducts}
            />

            <div className={classes.paymentCTAContainer}>
              <Button
                variant="contained"
                component={Link}
                to="/"
                style={{ marginBottom: 10 }}
                className={classes.button}
              >
                {hebrewLabels.continueShoppingCTA}
              </Button>

              <Typography component="p" className={classes.comment}>
                {hebrewLabels.controlComment}
              </Typography>

              <Button
                variant="contained"
                color="primary"
                onClick={this.reportInitiateCheckoutToFacebook}
                component={Link}
                to="/checkout"
                className={classes.button}
              >
                {hebrewLabels.moveToPaymentButtonText}
                <PaymentIcon className={classes.rightIcon} />
              </Button>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      content = (
        <Paper className={classes.loaderRoot}>
          <Typography className={classes.emptyCart}>
            {hebrewLabels.cartIsEmpty}
          </Typography>
        </Paper>
      );
    }

    return <div>{content}</div>;
  }
}

CartPage.propTypes = {
  classes: PropTypes.object.isRequired,
  nextShipment: PropTypes.string,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles)(CartPage);
