import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import SIPFork from "./SIP_Fork";
import SIPInfo from "./SIP_Info";
import CheckoutCartTotal from "./CheckoutCartTotal";

const hebrewLabels = {
  phone: "מספר נייד",
  nameForInvoice: "שם עבור החשבונית",
  titles: {
    billingTitle: "פרטי הלקוח",
  },
  invoiceOnDifferentName: "ארצה חשבונית על שם אחר או חברה",
  terms: {
    install: "* התשלום באתר הינו עבור הצמיגים בלבד",
    install2: "** התשלום עבור ההתקנה משולם ישירות במוסך",
    pickup: "איסוף עצמי אפשרי מהמחסן שלנו בכתובת: אליהו איתן 32, ראשון לציון",
    pickup2:
      "לאחר ההזמנה, נציג מטעמנו יצור קשר לתיאום האיסוף. אין להגיע לאסוף ללא תיאום מראש!",
  },
  companyId: "ח.פ / ע.מ",
  name: "שם מלא",
  emailaddress: "כתובת מייל",
  continue: "המשך",
};

const styles = (theme) => ({
  content: {
    padding: 16,
  },
  sipGrid: {
    width: "100%",
  },
  "@media (min-width: 1024px)": {
    sipGrid: {
      width: "initial",
    },
  },
  invoiceNameText: {
    color: "#858585",
    fontSize: 13,
  },
  formControl: {
    margin: theme.spacing() / 2,
    width: "90%",
  },
  commentsSection: {
    padding: 8,
  },
  formLabel: {
    padding: theme.spacing(),
    paddingLeft: 0,
  },
  totalsRow: {
    margin: 8,
  },
  sectionTitle: {
    fontSize: 20,
  },
  button: {
    width: "90%",
  },
});

class Personal extends React.Component {
  handleChange = (event) => {
    const { value, name, id } = event.target;
    let _value = value;

    if (id === "custom_invoice_name_cb" && !event.target.checked) {
      _value = "";
    }

    this.props.onFormFieldChange(id || name, _value);
  };

  render() {
    const {
      classes,
      data,
      handleSipChange,
      catalog,
      getInstallCities,
      onChangeInstallCity,
      onPickShop,
      onClearPickShop,
      onFormFieldChangeForShipping,
      onPickInstallDate,
      onPickInstallSlot,
      reportLike,
    } = this.props;
    const { checkout, stats, cart } = catalog;
    const {
      name = "",
      phone = "",
      emailaddress = "",
      nameforinvoice = "",
      companyOrBussinessVatId = "",
      custom_invoice_name_cb = "",
    } = data;

    const inputProps = {
      pattern: "[0-9]*",
      type: "number",
      step: "0.01",
    };

    const inputPropsForEmails = {
      pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$",
      type: "email",
    };

    return (
      <div className={classes.content}>
        <Grid container spacing={0}>
          <Grid item lg={6}>
            <Typography component="h2" className={classes.sectionTitle}>
              {hebrewLabels.titles.billingTitle}
            </Typography>

            <FormControl component="fieldset" className={classes.formControl}>
              <TextField
                id="name"
                onChange={this.handleChange}
                label={hebrewLabels.name}
                className={classes.textField}
                value={name}
                margin="normal"
              />
            </FormControl>

            <FormControl component="fieldset" className={classes.formControl}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      custom_invoice_name_cb === "custom_invoice_name"
                        ? true
                        : false
                    }
                    id="custom_invoice_name_cb"
                    onChange={this.handleChange}
                    value="custom_invoice_name"
                    color="primary"
                  />
                }
                label={
                  <span className={classes.invoiceNameText}>
                    {hebrewLabels.invoiceOnDifferentName}
                  </span>
                }
              />
            </FormControl>

            {custom_invoice_name_cb === "custom_invoice_name" && (
              <FormControl component="fieldset" className={classes.formControl}>
                <TextField
                  id="nameforinvoice"
                  onChange={this.handleChange}
                  label={hebrewLabels.nameForInvoice}
                  className={classes.textField}
                  value={nameforinvoice}
                  margin="normal"
                />
              </FormControl>
            )}

            {custom_invoice_name_cb === "custom_invoice_name" && (
              <FormControl component="fieldset" className={classes.formControl}>
                <TextField
                  id="companyOrBussinessVatId"
                  onChange={this.handleChange}
                  label={hebrewLabels.companyId}
                  className={classes.textField}
                  value={companyOrBussinessVatId}
                  margin="normal"
                />
              </FormControl>
            )}

            <FormControl component="fieldset" className={classes.formControl}>
              <TextField
                id="phone"
                inputProps={inputProps}
                onChange={this.handleChange}
                label={hebrewLabels.phone}
                className={classes.textField}
                value={phone}
                margin="normal"
              />
            </FormControl>

            <FormControl component="fieldset" className={classes.formControl}>
              <TextField
                id="emailaddress"
                inputProps={inputPropsForEmails}
                onChange={this.handleChange}
                label={hebrewLabels.emailaddress}
                className={classes.textField}
                value={emailaddress}
                margin="normal"
              />
            </FormControl>
          </Grid>

          <Grid item lg={6} className={classes.sipGrid}>
            <SIPFork
              sip={checkout.sip}
              onChangeSip={handleSipChange}
              onContinue={this.onContinueSIPFork}
            />

            <SIPInfo
              checkout={checkout}
              sip={checkout.sip}
              stats={stats}
              cart={cart}
              getInstallCities={getInstallCities}
              onChangeInstallCity={onChangeInstallCity}
              onPickShop={onPickShop}
              onClearPickShop={onClearPickShop}
              onPickInstallDate={onPickInstallDate}
              onPickInstallSlot={onPickInstallSlot}
              reportLike={reportLike}
              onFormFieldChangeForShipping={onFormFieldChangeForShipping}
            />
          </Grid>
        </Grid>

        <Divider />

        <Grid container spacing={0} className={classes.totalsRow}>
          <Grid item lg={6}>
            <CheckoutCartTotal
              checkout={checkout}
              cartProducts={catalog.cartProducts}
              cart={cart}
            />
          </Grid>

          <Grid item lg={6} className={classes.commentsSection}>
            {checkout.sip === "install" && (
              <React.Fragment>
                <Typography component="p">
                  {hebrewLabels.terms.install}
                </Typography>
                <Typography component="p">
                  {hebrewLabels.terms.install2}
                </Typography>
              </React.Fragment>
            )}

            {checkout.sip === "pickup" && (
              <React.Fragment>
                <Typography component="p">
                  {hebrewLabels.terms.pickup}
                </Typography>
                <Typography component="p">
                  {hebrewLabels.terms.pickup2}
                </Typography>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

Personal.propTypes = {
  classes: PropTypes.object.isRequired,
  onContinue: PropTypes.func.isRequired,

  // Action handlers
  handleSipChange: PropTypes.func.isRequired,

  getInstallCities: PropTypes.func.isRequired,
  onChangeInstallCity: PropTypes.func.isRequired,
  onPickShop: PropTypes.func.isRequired,
  onFormFieldChangeForShipping: PropTypes.func.isRequired,
  onClearPickShop: PropTypes.func.isRequired,
  onPickInstallDate: PropTypes.func.isRequired,
  onPickInstallSlot: PropTypes.func.isRequired,
  reportLike: PropTypes.func.isRequired,

  catalog: PropTypes.object,
  checkout: PropTypes.object,
  data: PropTypes.object,
  onFormFieldChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(Personal);
