/* eslint-disable import/no-named-as-default */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// import 'typeface-roboto';

import { withStyles } from "@material-ui/core/styles";

import AccessibilityWidget from "./../accessiblity-widget/AccessibilityWidget";

import Snackbar from "@material-ui/core/Snackbar";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";

import ChatIcon from "@material-ui/icons/Chat";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PhoneIcon from "@material-ui/icons/Phone";
import PersonIcon from "@material-ui/icons/Person";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import ContactUsIcon from "@material-ui/icons/MailOutline";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";

import { getCartItemsCount } from "./../../redux/reducers/utils/cart";
import SimpleAnchor from "./../common/SimpleAnchor";
import { Typography } from "@material-ui/core";

const hebrewLabels = {
  shoppingCartCTA: "לעגלת קניות",
  mobileMenu: {
    home: "דף הבית",
    customerService: "שירות לקוחות",
    directSales: "שיטת המכירה הישירה",
    installPoints: "נקודות הרכבה",
    qanda: "שאלות ותשובות",
    aboutUs: "אודות ספורטישופ",
    contactUs: "צור קשר",
    testimonials: "מה אומרים עלינו",
  },
  footer: {
    privacyPolicy: "מדיניות פרטיות",
    termsOfUse: "תנאי השימוש",
    serviceMail: "service@sportyshop.co.il",
    servicePhone: "054&ndash;9200911",
  },
};

const styles = {
  appBar: {
    background: "#ffffff",
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    width: "100%",
    borderBottom: "solid 1px #dcdcdc",
  },
  menuNavBarDesktop: {
    display: "none",
  },
  cartIcon: {
    color: "#545454",
    position: "absolute",
    left: 16,
  },
  accessibilityIcon: {
    color: "#545454",
    position: "absolute",
    left: 55,
  },
  phoneIcon: {
    color: "#545454",
    position: "absolute",
    left: 50,
  },
  cartEmpty: {
    color: "#545454",
    position: "absolute",
    left: 16,
  },
  menuButton: {
    color: "#545454",
    position: "absolute",
    right: 16,
  },
  logo: {},
  root: {
    display: "flex",
    justifyContent: "center",
  },
  corona: {
    background: "#013064",
    position: "fixed",
    display: "initial",
    zIndex: 1000,
    width: "100%",
    height: 53,
    padding: "5px 0",
    textAlign: "center",
    color: "#fefefe",
  },
  michelinPromoPre: {
    fontFamily: "Noto Sans Hebrew, sans-serif",
    color: "#ffffff",
    position: "absolute",
    left: 11,
    top: 6,
    fontSize: 17,
  },
  daysRange: {
    fontFamily: "Montserrat, sans-serif",
    color: "#ffe721",
    float: "right",
    position: "absolute",
    right: 281,
    top: 37,
    fontSize: 17,
  },
  days: {
    fontFamily: "Montserrat, sans-serif",
    color: "#ffffff",
    position: "absolute",
    left: 14,
    top: 26,
    fontSize: 20,
  },
  michelinDaysLogo: {
    float: "right",
    height: 51,
    bottom: -7,
    right: 5,
    position: "relative",
  },
  toolbar: {
    display: "flex",
    justifyContent: "center",
  },
  "@media (min-width: 1024px)": {
    toolbar: {
      width: 1000,
      margin: "auto",
    },
    corona: {
      height: 65,
    },
    appBar: {
      top: 0,
    },
    michelinPromoPre: {
      fontFamily: "Noto Sans Hebrew, sans-serif",
      color: "#ffffff",
      float: "left",
      position: "relative",
      left: 5,
      top: 0,
      fontSize: 21,
    },
    daysRange: {
      fontFamily: "Montserrat, sans-serif",
      color: "#ffe721",
      float: "right",
      position: "relative",
      right: 18,
      top: 24,
      fontSize: 25,
    },
    days: {
      fontFamily: "Montserrat, sans-serif",
      color: "#ffffff",
      float: "right",
      position: "relative",
      right: -9,
      top: 24,
      fontSize: 25,
    },
    michelinDaysLogo: {
      float: "right",
      height: 60,
      bottom: "-4px",
      right: 0,
      position: "relative",
    },
    coronaInner: {
      width: 570,
      margin: "auto",
    },
    menuNavToolBar: {
      width: 1000,
      margin: "auto",
    },
    phoneIcon: {
      display: "none",
    },
    navBarButton: {
      color: "#fefefe",
      fontSize: "12pt",
    },
    menuNavBarDesktop: {
      background: "#0085B2",
      position: "fixed",
      display: "initial",
      width: "100%",
      borderBottom: "solid 1px #555656",
      top: 66,
    },
    logo: {
      position: "absolute",
      left: 0,
    },
    rightIcon: {
      marginRight: 8,
    },
    accessibilityIcon: {
      left: "initial",
      right: 55,
    },
    cartIcon: {
      left: "initial",
      right: 16,
    },
    menuButton: {
      display: "none",
    },
  },
  list: {
    width: 250,
  },
  logoImg: {
    height: 33,
  },
  link: {
    textDecoration: "none",
  },
};

const snackBarActions = {
  addToCart: (
    <Button component={Link} color="primary" to="/cart" variant="contained">
      {hebrewLabels.shoppingCartCTA}
    </Button>
  ),
};

class AppMenuBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { drawer: false, accessibilityDash: false };
  }

  componentDidMount() {
    this.props.actions.getTrackingVisitorId();
  }

  handleSnackbarClose = () => {
    this.props.actions.toggleSnackbar(false);
  };

  toggleDrawer = (open) => () => {
    this.setState({
      drawer: open,
    });
  };

  render() {
    const { classes } = this.props;

    const sideList = (
      <div className={classes.list}>
        <List>
          <ListItem button component={Link} to="/">
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={hebrewLabels.mobileMenu.home} />
          </ListItem>
          <ListItem button component={Link} to="/customer-service">
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={hebrewLabels.mobileMenu.customerService} />
          </ListItem>
          <ListItem button component={Link} to="/our-direct-business-model">
            <ListItemIcon>
              <MoneyOffIcon />
            </ListItemIcon>
            <ListItemText primary={hebrewLabels.mobileMenu.directSales} />
          </ListItem>

          <ListItem button component={Link} to="/our-shops">
            <ListItemIcon>
              <MotorcycleIcon />
            </ListItemIcon>
            <ListItemText primary={hebrewLabels.mobileMenu.installPoints} />
          </ListItem>
          <ListItem button component={Link} to="/about-us">
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary={hebrewLabels.mobileMenu.aboutUs} />
          </ListItem>
          <ListItem button component={Link} to="/contact-us">
            <ListItemIcon>
              <ContactUsIcon />
            </ListItemIcon>
            <ListItemText primary={hebrewLabels.mobileMenu.contactUs} />
          </ListItem>
          <ListItem button component={Link} to="/testimonials">
            <ListItemIcon>
              <ChatIcon />
            </ListItemIcon>
            <ListItemText primary={hebrewLabels.mobileMenu.testimonials} />
          </ListItem>
        </List>
      </div>
    );

    const vertical = "bottom";
    const horizontal = "center";
    const snackBarOpenToggle = this.props.catalog.toggleSnackbar;
    const { snackbar } = this.props.catalog;
    const cartTotalItems = getCartItemsCount();

    let cartMenuIcon;
    const cartUrl = "/cart";

    if (cartTotalItems > 0) {
      cartMenuIcon = (
        <IconButton component={Link} className={classes.cartIcon} to={cartUrl}>
          <Badge badgeContent={cartTotalItems} overlap="rectangular" color="primary">
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
      );
    } else {
      cartMenuIcon = (
        <IconButton component={Link} className={classes.cartIcon} to={cartUrl}>
          <ShoppingCartIcon />
        </IconButton>
      );
    }

    return (
      <div className={classes.root}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              onClick={this.toggleDrawer(true)}
              className={classes.menuButton}
              color="primary"
              aria-label="Menu"
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              component={SimpleAnchor}
              to="tel:0549200911"
              className={classes.phoneIcon}
              color="primary"
              aria-label="חייגו אלינו"
            >
              <PhoneIcon />
            </IconButton>
            {cartMenuIcon}

            <a className={classes.logo} href="/">
              <img
                alt="Sportyshop"
                className={classes.logoImg}
                src="https://s3.eu-west-2.amazonaws.com/sportyshop/catalog/logos/home_logo.png"
              />
            </a>
          </Toolbar>
        </AppBar>

        <AppBar position="static" className={classes.menuNavBarDesktop}>
          <Toolbar className={classes.menuNavToolBar}>
            <Button className={classes.navBarButton} component={Link} to="/">
              <HomeIcon className={classes.rightIcon} />
              {hebrewLabels.mobileMenu.home}
            </Button>
            <Button
              className={classes.navBarButton}
              component={Link}
              to="/customer-service"
            >
              <PersonIcon className={classes.rightIcon} />
              {hebrewLabels.mobileMenu.customerService}
            </Button>
            <Button
              className={classes.navBarButton}
              component={Link}
              to="/our-direct-business-model"
            >
              <MoneyOffIcon className={classes.rightIcon} />
              {hebrewLabels.mobileMenu.directSales}
            </Button>
            <Button
              className={classes.navBarButton}
              component={Link}
              to="/our-shops"
            >
              <MotorcycleIcon className={classes.rightIcon} />
              {hebrewLabels.mobileMenu.installPoints}
            </Button>
            <Button
              className={classes.navBarButton}
              component={Link}
              to="/contact-us"
            >
              <ContactUsIcon className={classes.rightIcon} />
              {hebrewLabels.mobileMenu.contactUs}
            </Button>
            <Button
              className={classes.navBarButton}
              component={Link}
              to="/testimonials"
            >
              <ChatIcon className={classes.rightIcon} />
              {hebrewLabels.mobileMenu.testimonials}
            </Button>
          </Toolbar>
        </AppBar>

        <AccessibilityWidget />

        {snackBarOpenToggle && (
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={snackBarOpenToggle}
            onClose={this.handleSnackbarClose}
            message={<span id="message-id">{snackbar.message}</span>}
            action={snackBarActions[snackbar.type]}
          />
        )}
        <Drawer
          anchor="left"
          open={this.state.drawer}
          onClose={this.toggleDrawer(false)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer(false)}
            onKeyDown={this.toggleDrawer(false)}
          >
            {sideList}
          </div>
        </Drawer>
      </div>
    );
  }
}

AppMenuBar.propTypes = {
  children: PropTypes.element,
  actions: PropTypes.object,
  catalog: PropTypes.object,
  classes: PropTypes.object,
};

export default withStyles(styles)(AppMenuBar);
