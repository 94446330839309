const brandsCdnPath = 'https://s3.eu-west-2.amazonaws.com/sportyshop/catalog/logos';

const brandLogoUrls = {
    michelin: `${brandsCdnPath}/michelin_list_logo.png`,
    pirelli: `${brandsCdnPath}/pirelli_list_logo.png`,
    bridgestone: `${brandsCdnPath}/bt_list_logo.png`,
    metzeler: `${brandsCdnPath}/metzeler_list_logo.png`,
    continental: `${brandsCdnPath}/conti_list_logo.png`,
    goldentyre: `${brandsCdnPath}/goldentyre_list_logo.png`,
    avon: `${brandsCdnPath}/avon.png`,
    maxxis: `${brandsCdnPath}/maxxis_small.png`,
    sunf: `${brandsCdnPath}/sunf_logo_small.png`,
    mitas: `${brandsCdnPath}/mitas.png`,
    dunlop: `${brandsCdnPath}/dunlop_list_logo.png`,
};

export default brandLogoUrls;