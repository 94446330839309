import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    padding: 16,
    marginTop: 33,
    marginLeft: "auto",
    marginRight: "auto",
  },
  headline: {
    fontSize: "14pt",
    fontWeight: 525,
  },
  para: {
    margin: "15px 8px",
  },
  "@media (min-width: 1024px)": {
    root: {
      marginTop: 80,
      width: 1000,
    },
    para: {
      width: "70%",
    },
  },
});

const hebrewLabels = {
  title: "אז מי אנחנו?",
  paras: [
    "ספורטישופ נוסדה במהלך שנת 2013 במאמץ להפוך את מחיר מוצרי הדו גלגלי בישראל נגישים ושפויים אנו עוסקים ומתמחים בשלב זה בתחום יבוא צמיגים עבור: אופנועי כביש, רייזרים, טרקטורונים, אופנועי שטח וקטנועים.",
    "במחסנים השונים שלנו נמצאים מעל 2000 צמיגים בכל רגע נתון ממגוון מותגים מובילים שונים אשר אותם אנחנו מייבאים באופן שוטף וביניהם:  מישלין, פירלי, מצלר, ברידג'סטון, דנלופ, קונטיננטל, מקסיס ו SunF",
    "מדובר בעסק משפחתי שהתחיל באהבה לאופנועים מגיל צעיר של הבעלים, איתמר ארג'ואן אשר בעצמו רכב על מספר אופנועים כבדים במהלך השנים וכן על אופנועי שטח וטרקטורונים כילד קטן יותר. ביקורים במסלולי MotoGP ברחבי העולם ומעקב אחרי האנשים המובילים בתחום כמו ולנטינו רוסי עוד משנת 97-98. ",
    "אנחנו משתדלים לתת יחס אישי לכל לקוח אשר מגיע אלינו ונהנים לתת מהידע שלנו ללקוחות שסומכים עלינו ועל הנסיון שצברנו במהלך השנים בתחום הצמיגים והאופנועים בכלליות.",
    "אנחנו עדיין בתחילת ועוד מוצרים יתווספו עם הזמן ליבוא סדיר בבוא העת",
    "אנו רואים בעצמנו חלק ממכלול גדול יותר במדינה אשר מעודד תחרות, מנרמל מחירים שהיום כבר נחשבים נחלת העבר. עסק אשר משתלב בנוף ולא מחפש לשלוט בכל פיסה בתחום ונמצא בסינרגיה עם אחרים.",
    "חלק מהמודל העסקי שלנו כולל שילוב של מוסכים ונקודות התקנה באתר שלנו ויצירת קשרים עסקיים עם מגוון רחב של מתקינים על מנת לספק ללקוחות חווית קנייה איכותית המאפשרת להתקין את הצמיגים קרוב לביתם ועדיין לרכוש באינטרנט מנוחות הבית שלהם במספר קליקים בודדים",
  ],
};

class AboutUsPage extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Paper className={classes.root} elevation={4}>
          <Typography component="h1" variant="h1" className={classes.headline}>
            {hebrewLabels.title}
          </Typography>

          {hebrewLabels.paras.map((pa) => {
            return (
              <Typography className={classes.para} component="p">
                {pa}
              </Typography>
            );
          })}
        </Paper>
      </div>
    );
  }
}

AboutUsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles)(AboutUsPage);
