/* eslint-disable import/no-named-as-default */
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet-async";

import Menu from "./Menu";
import CatalogFooter from "./Footer-new";
import CatalogFooter2 from "./Footer2";
import RTL from "./RTL";

const styles = {
  spacer: {
    height: 40,
  },
  "@media (min-width: 1024px)": {
    spacer: {
      height: 82,
    },
  },
};

class App extends React.Component {
  render() {
    const { classes } = this.props;

    const baseDescription =
      "צמיגים לאופנועים מהיבואן לצרכן עד 70% הנחה כולל תור בקליק במוסכים מקצועיים ברחבי הארץ שירות אדיב ומלאי ענקי";

    return (
      <RTL>
        <div>
          <Menu actions={this.props.actions} catalog={this.props.catalog} />
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="Content-language" content="he" />
            <meta
              property="og:title"
              content="צמיגים לאופנועים | ספורטישופ | מהיבואן לצרכן | פירלי | מישלין | מצלר | קונטיננטל | דנלופ"
            />
            <meta
              property="og:image"
              content="https://s3.eu-west-2.amazonaws.com/sportyshop/catalog/homepage/covers/cover.jpeg"
            />
            <meta property="og:site_name" content="ספורטישופ יבוא צמיגים" />
            <meta property="og:description" content={baseDescription} />
            <meta property="description" content={baseDescription} />

            <title>צמיגים לאופנועים | ספורטישופ | מהיבואן לצרכן</title>
          </Helmet>

          <div className={classes.spacer}></div>

          {this.props.children}
          <CatalogFooter />
          <CatalogFooter2 />
        </div>
      </RTL>
    );
  }
}

App.propTypes = {
  children: PropTypes.element,
  jsses: PropTypes.object,
  actions: PropTypes.object,
  catalog: PropTypes.object,
};

export default withStyles(styles)(App);
