import initialState from './initialState';
import handlers from './actions/catalogReducerHandlers';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function catalogReducer(state = initialState.catalog, action) {
  try {
    return handlers.has(action.type)
      ? handlers.get(action.type)(state, action)
      : state;
  } catch (err) {
    console.log(err);
  }
}
