import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import brandLogoUrls from "./../common/logoUrls";

const hebrew = {
  title: "המותגים שלנו",
};

const styles = (theme) => ({
  brandsRoot: {
    marginTop: 5,
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    width: "100%",
    display: "flex",
    flexFlow: "row wrap",
    alignContent: "flex-start",
    justifyContent: "center",
    flexGrow: 0,
  },
  brand: {
    height: 25,
    width: 100,
    margin: 8,
    display: "inline-flex",
    alignSelf: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  listItem: {
    paddingBottom: 0,
    paddingTop: 5,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    margin: 8,
  },
  "@media (min-width: 1024px)": {
    title: {
      textAlign: "center",
    },
    brand: {
      width: 120,
      height: 36,
    },
  },
});

const brandKeys = Object.keys(brandLogoUrls);

class OurBrands extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.brandsRoot}>
        <Typography className={classes.title}>{hebrew.title}</Typography>
        <div className={classes.root}>
          {brandKeys.map((key, index) => {
            return (
              <div
                key={index}
                className={classes.brand}
                style={{ backgroundImage: `url(${brandLogoUrls[key]})` }}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

OurBrands.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OurBrands);
