export function getCartTotal({ cart, checkout, cartProducts }) {
    let installTotal = 0;

    const cartSubTotal = cart.items.reduce((total, item) => {
        const productIndex = cartProducts.findIndex((product => product.ean === item.ean));
        if (productIndex !== -1) {
            const product = cartProducts[productIndex];
            return total + product.price * item.quantity;
        }
        return 0;
    }, 0);

    if (checkout.sip === 'install' && checkout.selectedShop) {
        const shop = checkout.shops.find(o => o.id === checkout.selectedShop);
        const price = shop.price;
        installTotal = cart.items.reduce((total, item) => {
            return total + ((item.isSet) ? price * 2 : price);
        }, 0);
    }

    return {
        cartSubTotal,
        installTotal,
    };
}