import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    padding: 16,
    marginTop: 33,
    marginLeft: "auto",
    marginRight: "auto",
  },
  headline: {
    fontSize: "14pt",
    fontWeight: 525,
  },
  para: {
    margin: "15px 8px",
  },
  "@media (min-width: 1024px)": {
    root: {
      marginTop: 80,
      width: 1000,
    },
    para: {
      width: "70%",
    },
  },
});

const hebrewLabels = {
  title: "הצטרפות לאתר כנקודת הרכבת צמיגים",
  paras: [
    "אנו שמחים שהחלטתם להתעניין בהצטרפות כנקודת התקנה לספורטישופ!",
    "שיתוף פעולה היא דרך מצוינת להזרים עבודה נוספת למוסך שלכם ברגעים רגועים יותר או כתקופות שיש פחות עבודה. לעבודה מולנו יתרונות במישורים רבים חלק מהיתרונות של הצטרפות כנקודת הרכבה:",
  ],
  list: [
    "השירות ניתן ללא עלות בשלב זה ומספק לכם שירות פרסום איכותי אשר מזרים עבודה לעסק",
    "לקוחות בקרוב יתאפשרו ביכולת לדרג את העסק שלכם ושירות טוב יתוגמל בביקורת מעולה מצד הלקוח משהו אשר מעלה את הערך שלכם בפני לקוחות אשר מבצעים מחקר לגבי הטיפול הבא לאופנוע שלהם",
    "אתם קובעים את התעריף הרכבה (כולל מע״מ) להרכבת צמיג אופנוע/קטנוע שיופיע באתר",
    "לקוחות אשר מגיעים להחלפת צמיגים דרכנו אומנם לא רכשו את הצמיגים דרך המוסך שלכם אך בהצטרפות אלינו אתם נהנים מחלק מהעסקה ומרוויחים את תשומת לב הלקוח אל המוסך שלכם דבר חיובי וחשוב לא פחות",
    "לקוחות בודקים היום בכל מקום איך אפשר לחסוך בעלויות אחזקת האופנוע ,ולא בטוח שיעדיפו לקנות דרך המוסך, הצטרפות אלינו מספקת אפשרות להמשיך להישאר רלוונטי בתחום התקנת הצמיגים ולא להפסיד לקוחות מהעיר שלכם שאולי קונים דרכנו.",
    "לקוחות אשר כבר הגיעו להתקנה דרכנו לעסק שלכם יכולים להתעניין לגבי טיפולים נוספים ועבודות נוספות אשר נדרשים באופנוע באותה הזדמנות בה הם מגיעים אליכם - משהו שמאפשר לכם יכולת להרוויח מעבר לסכום התקנת הצמיגים וגם לצאת אלופים מול הלקוח.",
    "כנקודת התקנה שלנו תהיו זכאים לרכוש מאיתנו צמיגים למוסך שלכם במחירים סיטונאים מיוחדים",
  ],
  paras2: [
    "אלו רק חלק מן היתרונות בהצטרפות כנקודת הרכבה? מתאים לכם? מעולה!",
    "אתם מוזמנים ליצור קשר עמנו בטלפון מספר 054-9200911 ולשלוח הודעת וואטסאפ שכוללת את הפרטים הבאים:",
  ],
  list2: [
    "שם איש קשר מרכזי במוסך",
    "צילום תעודה / כתב הסמכה הפעיל בעסק",
    "שם המוסך כמו שתרצו שיופיע בכרטיס שלכם אצלנו",
    "כתובת מלאה של המוסך",
    "סוג האיזון אותו אתם מספקים (סטטי או דיגיטלי)",
    "תמונה אחת מייצגת של המקום לכרטיס",
    "מספר טלפון נייד אשר יפורסם כחלק מהכרטיס שלכם",
    "כתובת אימייל שאתם מנתרים באופן יומי",
  ],
  paras3: [
    "במידה ויש עוד פרטים שתרצו לדעת על הצטרפות לאתר שלנו - מוזמנים לפנות לאיתמר אשר נמצא באותו מספר נייד עם שאלות נוספות.",
  ],
};

class ShopSignupPage extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Paper className={classes.root} elevation={4}>
          <Typography component="h1" variant="h1" className={classes.headline}>
            {hebrewLabels.title}
          </Typography>

          {hebrewLabels.paras.map((pa) => {
            return (
              <Typography className={classes.para} component="p">
                {pa}
              </Typography>
            );
          })}

          <ul>
            {hebrewLabels.list.map((li) => {
              return (
                <li>
                  <Typography>{li}</Typography>
                </li>
              );
            })}
          </ul>

          {hebrewLabels.paras2.map((pa) => {
            return (
              <Typography className={classes.para} component="p">
                {pa}
              </Typography>
            );
          })}

          <ul>
            {hebrewLabels.list2.map((li) => {
              return (
                <li>
                  <Typography>{li}</Typography>
                </li>
              );
            })}
          </ul>

          {hebrewLabels.paras3.map((pa) => {
            return (
              <Typography className={classes.para} component="p">
                {pa}
              </Typography>
            );
          })}
        </Paper>
      </div>
    );
  }
}

ShopSignupPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles)(ShopSignupPage);
