import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { vsprintf } from 'sprintf-js';

const hebrewLables = {
    totalToPayText: 'סה״כ לתשלום:',
};

const styles = theme => ({
    total: {
        textAlign: 'center',
        fontFamily: 'Lato',
        paddingTop: 2,
        direction: 'ltr',
        fontWeight: 700,
        fontSize: '14pt',
        clear: 'both'
    },
    totalToPayCell: {
        fontSize: '14pt',
        paddingRight: 20
    },
    content: {
        padding: 5,
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        maxWidth: 400,
        marginTop: '10px',
        marginLeft: 'auto',
        marginRight: 'auto',
        position: 'relative'
    },
    button: {
        margin: theme.spacing(),
    },
    desktopContent: {
        display: 'none'
    },
    '@media (min-width: 1024px)': {
        content: {
            display: 'none'
        },
        totalToPayCell: {
            display: 'inline'
        },
        desktopContent: {
            position: 'absolute',
            display: 'initial',
            top: 14,
            left: 13
        }
    }
});

class CartTotal extends React.Component {
    render() {
        const { classes, cart, cartProducts } = this.props;

        const cartTotal = cart.items.reduce((total, item) => {
            const productIndex = cartProducts.findIndex((product => product.ean === item.ean));
            if (productIndex !== -1) {
                const product = cartProducts[productIndex];
                return total + product.price * item.quantity;
            }
            return 0;
        }, 0);

        return (
            <React.Fragment>
                <Paper className={classes.content}>
                    <Typography variant="h1" className={classes.totalToPayCell}>
                        {hebrewLables.totalToPayText}
                    </Typography>
                    <span className={classes.total}>{vsprintf('%.2f', [cartTotal])} ₪</span>
                </Paper>
                <div className={classes.desktopContent}>
                    <Typography variant="h1" className={classes.totalToPayCell}>
                        {hebrewLables.totalToPayText}
                    </Typography>
                    <span className={classes.total}>{vsprintf('%.2f', [cartTotal])} ₪</span>
                </div>
            </React.Fragment>
        );
    }
}

CartTotal.propTypes = {
    classes: PropTypes.object.isRequired,
    cartProducts: PropTypes.array.isRequired,
    cart: PropTypes.object.isRequired
};

export default withStyles(styles)(CartTotal);
